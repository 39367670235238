import { ZipcodeAddress } from "c4u-web-components";
import { useRequest, viacepHttp } from "./../config";

interface IZipcodeAddress extends ZipcodeAddress {}

export const useZipcodeAddress = () => {
  const { get } = useRequest(viacepHttp, "ws", {});

  const getAddressByZipcode = async (
    zipcode: string
  ): Promise<IZipcodeAddress> => {
    try {
      const zipcodeNumbers = zipcode?.replace(/[^\d]/g, "");
      const response = await get(`${zipcodeNumbers}/json/`);
      return response.data;
    } catch (error) {
      console.log(error);
    }

    return {} as any;
  };

  return {
    getAddressByZipcode,
  };
};
