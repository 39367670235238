import { useAuth0 } from "@auth0/auth0-react";
import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect } from "react";

export const MainTemplateAuth: React.FC = (props) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
        clientId: process.env.REACT_APP_AUTH0_CLIENTID ?? "",
        audience: process.env.REACT_APP_AUTH0_AUDIENCE ?? "",
        scope: process.env.REACT_APP_AUTH0_SCOPE ?? "",
        redirectUri: `${window.location.href}`,
      });
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  return (
    <>
      {isLoading && <SpinnerCenteredAtom />}
      {isAuthenticated && props.children}
    </>
  );
};
