import { CoxIcon } from "c4u-web-components";
import React from "react";
import {
  Title,
  WrapperCommingSoon,
  BackgroudCommingSoon,
  IconStyledCS,
} from "./comming-soon.atom.styled";

interface IProps {
  title: string;
}

export const CommingSoonAtom: React.FC<IProps> = (props) => {
  return (
    <WrapperCommingSoon>
      <BackgroudCommingSoon>
        <Title>{props.title}</Title>
      </BackgroudCommingSoon>
      <IconStyledCS>
        <CoxIcon name={"shadow-corner"} />
      </IconStyledCS>
    </WrapperCommingSoon>
  );
};
