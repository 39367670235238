import { useRequest, insuranceHttp } from "./../config";
import { CreatePasswordlessRequest, ILoginModel } from "c4u-web-components";

export const useInsurancePasswordless = () => {
  const { post } = useRequest(insuranceHttp, "Passwordless");

  const AuthorizationCreate = async (
    request: CreatePasswordlessRequest
  ): Promise<string> => {
    const { data } = await post(`create`, request);
    return data?.authId;
  };

  const AuthorizationValidateCode = async (
    email: string,
    code: string
  ): Promise<ILoginModel> => {
    const { data } = await post(`login`, { email, code });
    return data;
  };

  return {
    AuthorizationCreate,
    AuthorizationValidateCode,
  };
};
