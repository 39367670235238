import styled from "styled-components";

export const QuotesOrganismWrapper = styled.div<{ reduceMarginTop?: boolean }>`
  /* display: flex;
  align-items: center; */
  /* justify-content: center; */
  margin: ${(props) => (props.reduceMarginTop ? "10px" : "60px")} 0 30px 0;

  overflow-y: hidden;
  overflow-x: auto;

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #cdcdcd #f0f0f0;

  /* Other browsers */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cdcdcd;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 5px;
  }
`;

export const QuotesMappingDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 26px;
`;

export const IturanTrackerOfferDiv = styled.div`
  width: 255px;
  margin: 0 0 6px;

  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  color: ${(props) => props.theme.colors.Dark};
`;

export const IturanTrackerOfferWrapper = styled.div`
  display: flex;
  justify-content: end;
  min-width: 1100px;
  width: 1100px;
  max-width: 1100px;
  padding: 0 2px;
`;
