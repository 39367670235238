import { CoxIcon, SpinnerCenteredAtom, theme } from "c4u-web-components";
import React, { useMemo, useState } from "react";
import { Details, WrapperQuoteDetailsLinkItem } from "./quote-item.atom.style";

interface IProps {
  title: string;
  disabled: boolean;
  onclick: (setLoadingDetails: (v: boolean) => void) => void;
}

export const QuoteItemDetailsAtom: React.FC<IProps> = (props) => {
  const fillColor = useMemo(
    () => (!props.disabled ? theme.colors.BlueHigh : theme.colors.MediumGray),
    [props.disabled]
  );

  const [loadingDetails, setLoadingDetails] = useState(false);

  return (
    <WrapperQuoteDetailsLinkItem
      onClick={
        props.disabled || loadingDetails
          ? undefined
          : () => {
              if (!loadingDetails) props.onclick(setLoadingDetails);
            }
      }
      isLoading={loadingDetails}
    >
      {loadingDetails ? (
        <SpinnerCenteredAtom classNames="spinner-detail" />
      ) : (
        <CoxIcon
          name="document-v2"
          width={13}
          height={13}
          fillColor={fillColor}
        />
      )}

      <Details color={fillColor}>{props.title}</Details>
    </WrapperQuoteDetailsLinkItem>
  );
};
