import { Modal } from "react-bootstrap";
import styled from "styled-components";

const ModalBody = styled.div`
  background-color: ${(props) => props.theme.colors.Background};
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 60px 60px 80px 60px;
  border-radius: 5px;
`;

const Icon = styled.div`
  margin: 0 0 15px 0;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.DarkBlue};
  text-align: center;
  line-height: 28px;
  margin: 0;
`;

const WrapperButtonsConfirm = styled.div`
  display: flex;
  margin: 5px 0;
  button:first-child {
    margin-right: 20px;
  }
`;

const Content = styled.div`
  margin: 0 0 30px 0;
`;

const ModalHeader = styled(Modal.Header)`
  background: ${(props) => props.theme.colors.Background};
  height: 60px;
  justify-content: flex-end;
  align-items: center;

  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
  color: #1f3e74;

  span {
    font-size: 30px;
    font-weight: normal;
  }
  button {
    padding: 0;
    margin: 0;
    opacity: 1;
  }
`;

const ContentModalMsg = styled.div`
  color: ${(props) => props.theme.colors.Dark};
  text-align: center;
  max-width: 500px;
`;

export {
  ModalBody,
  Title,
  Icon,
  Content,
  ModalHeader,
  ContentModalMsg,
  WrapperButtonsConfirm,
};
