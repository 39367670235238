import {
  Combo,
  VehicleCategoriesEnum,
  SortHelper,
  SourcePartnerEnum,
  VehicleSearchOptions,
  VehicleSearchOptionsProps,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useInsuranceContext, useKbb } from "../../../../hooks";
import { VehicleModel, Years } from "../../../../models";

interface IProps {
  vehicleKbb?: {
    id: number;
    idBrand: number;
    year: number;
    idModel: number;
    molicarID: string;
  };
  vehicleCategory: VehicleCategoriesEnum;
  setVehicleCategory: (vehicleCategory: VehicleCategoriesEnum) => void;
  sourcePartner: SourcePartnerEnum;
  setSourcePartner: (sourcePartner: SourcePartnerEnum) => void;
}

export const VehicleOptionsMolecule: React.FC<IProps> = (parameters) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);
  useEffect(() => {
    setProps(parameters);
  }, [parameters]);

  const {
    setVehicleContext,
    vehicleCategoryContext,
    setVehicleCategoryContext,
    sourcePartnerContext,
    setSourcePartnerContext,
  } = useInsuranceContext();

  const {
    GetAllVehicleBrand,
    GetAllVehicleModelByBrand,
    GetAllVehicleVersion,
    GetAllYears,
    GetVehicle,
  } = useKbb();

  const [editMode, setEditMode] = useState<boolean>();

  const [vehicleBrandCombo, setVehicleBrandCombo] = useState<Combo[]>();
  const [vehicleModelCombo, setVehicleModelCombo] = useState<Combo[]>();
  const [vehicleVersionCombo, setVehicleVersionCombo] = useState<Combo[]>();
  const [yearsCombo, setYearsCombo] = useState<Combo[]>();

  const [vehicleBrandSelected, setVehicleBrandSelected] = useState<Combo>();
  const [vehicleYearSelected, setVehicleYearSelected] = useState<Combo>();
  const [vehicleModelSelected, setVehicleModelSelected] = useState<Combo>();
  const [vehicleVersionSelected, setVehicleVersionSelected] = useState<Combo>();

  const [vehicleYears, setVehicleYears] = useState<Years[]>();
  const [vehicleModels, setVehicleModels] = useState<VehicleModel[]>();

  const isCarCategory = useMemo(
    () => vehicleCategoryContext === VehicleCategoriesEnum.Car,

    [vehicleCategoryContext]
  );

  const isSourceKbb = useMemo(
    () => sourcePartnerContext === SourcePartnerEnum.Kbb,

    [sourcePartnerContext]
  );

  const getVehiclesBrandAsync = useCallback(async (): Promise<void> => {
    const items = await GetAllVehicleBrand(
      vehicleCategoryContext,
      sourcePartnerContext
    );
    const itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.name,
          value: m.id,
        })
    );
    setVehicleBrandCombo(itemsCombo);
  }, [GetAllVehicleBrand, vehicleCategoryContext, sourcePartnerContext]);

  const getVehicleVersionAsync = useCallback(
    async (modelId: number, year: number): Promise<void> => {
      if (modelId && year) {
        const items = await GetAllVehicleVersion(
          modelId,
          year,
          sourcePartnerContext
        );
        const itemsCombo = items?.map(
          (m) =>
            new Combo({
              title: m.name,
              value: isSourceKbb ? m.kbbid : m.molicarID,
            })
        );

        setVehicleVersionCombo(itemsCombo);
      }
    },
    [GetAllVehicleVersion, sourcePartnerContext, isSourceKbb]
  );

  const getVehiclesModelAsync = useCallback(
    async (brandId: number, year: number | null = null): Promise<void> => {
      if (brandId) {
        const items = await GetAllVehicleModelByBrand(
          brandId,
          year,
          vehicleCategoryContext,
          sourcePartnerContext
        );
        setVehicleModels(items);
        const itemsCombo = items?.map(
          (m) =>
            new Combo({
              title: m.name,
              value: m.id,
            })
        );
        setVehicleModelCombo(itemsCombo);

        if (vehicleModelSelected && year) {
          const modelExists = itemsCombo.find(
            (f) => f.value === vehicleModelSelected?.value
          );
          if (modelExists) {
            getVehicleVersionAsync(Number(modelExists.value), year);
          } else {
            setVehicleModelSelected(undefined);
          }
        }
      }
    },
    [
      GetAllVehicleModelByBrand,
      getVehicleVersionAsync,
      sourcePartnerContext,
      vehicleCategoryContext,
      vehicleModelSelected,
    ]
  );

  const getVehicleAsync = useCallback(
    async (
      id: number,
      molicarID: string | null,
      year: number
    ): Promise<void> => {
      const vehicle = await GetVehicle(id, molicarID, year);

      setVehicleContext(vehicle);
    },
    [GetVehicle, setVehicleContext]
  );

  const GetAllYearsAsync = useCallback(async (): Promise<void> => {
    const items = await GetAllYears(sourcePartnerContext);
    setVehicleYears(items);
    const itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.year,
          value: m.year,
        })
    );
    setYearsCombo(itemsCombo);
  }, [GetAllYears, sourcePartnerContext]);

  const handleChangeBrand = useCallback(
    (comboValue: Combo) => {
      setVehicleBrandSelected(comboValue);
      setVehicleModelSelected(undefined);
      setVehicleVersionSelected(undefined);
      setVehicleContext(undefined);
    },
    [setVehicleContext]
  );

  const handleChangeYear = useCallback(
    (comboValue: Combo) => {
      setVehicleYearSelected(comboValue);
      setVehicleVersionSelected(undefined);
      setVehicleContext(undefined);
    },
    [setVehicleContext]
  );

  const handleChangeModel = useCallback(
    (comboValue: Combo) => {
      setVehicleModelSelected(comboValue);
      setVehicleVersionSelected(undefined);
      setVehicleContext(undefined);
    },
    [setVehicleContext]
  );

  const handleChangeVerion = useCallback(
    (comboValue: Combo) => {
      setVehicleVersionSelected(comboValue);
      setVehicleContext(undefined);
    },
    [setVehicleContext]
  );

  useEffect(() => {
    getVehiclesBrandAsync();
    GetAllYearsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcePartnerContext]);

  useEffect(() => {
    if (editMode === undefined && props.vehicleKbb) setEditMode(true);
  }, [props.vehicleKbb]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleBrandSelected && vehicleYearSelected)
      getVehiclesModelAsync(
        Number(vehicleBrandSelected.value),
        Number(vehicleYearSelected.value)
      );
  }, [vehicleBrandSelected, vehicleYearSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleModelSelected && vehicleYearSelected)
      getVehicleVersionAsync(
        Number(vehicleModelSelected.value),
        Number(vehicleYearSelected.value)
      );
  }, [vehicleModelSelected, vehicleYearSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleModels && vehicleModelSelected) {
      const modelSelected = vehicleModels.find(
        (f) => f.id === vehicleModelSelected.value
      );

      const itemsComboYears = modelSelected?.years.sort(SortHelper.desc).map(
        (y) =>
          new Combo({
            title: y,
            value: y,
          })
      );
      if (itemsComboYears) setYearsCombo(itemsComboYears);
    } else {
      const itemsCombo = vehicleYears?.map(
        (m) =>
          new Combo({
            title: m.year,
            value: m.year,
          })
      );
      if (itemsCombo) setYearsCombo(itemsCombo);
    }
  }, [vehicleModelSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleVersionSelected) {
      const vehicleYearValue = Number(vehicleYearSelected?.value);

      if (props.vehicleCategory === VehicleCategoriesEnum.Car) {
        getVehicleAsync(
          Number(vehicleVersionSelected.value),
          null,
          vehicleYearValue
        );
      } else {
        getVehicleAsync(
          0,
          vehicleVersionSelected.value.toString(),
          vehicleYearValue
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleVersionSelected, vehicleYearSelected?.value]);

  //EDITMODE
  useEffect(() => {
    if (editMode && vehicleBrandCombo && yearsCombo) {
      const brand = vehicleBrandCombo?.find(
        (f) => f.value === props.vehicleKbb?.idBrand
      );
      setVehicleBrandSelected(brand);

      const year = yearsCombo?.find((f) => f.value === props.vehicleKbb?.year);
      setVehicleYearSelected(year);
    }
  }, [editMode, vehicleBrandCombo, yearsCombo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleCategoryContext) {
      setVehicleCategoryContext(vehicleCategoryContext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vehicleCategory]);

  useEffect(() => {
    if (editMode && vehicleModelCombo) {
      const model = vehicleModelCombo?.find(
        (f) => f.value === props.vehicleKbb?.idModel
      );

      setVehicleModelSelected(model);
    }
  }, [editMode, vehicleModelCombo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editMode && vehicleVersionCombo) {
      let version: Combo | undefined;
      if (props.vehicleCategory === VehicleCategoriesEnum.Car) {
        version = vehicleVersionCombo?.find(
          (f) => f.value === props.vehicleKbb?.id
        );
      } else {
        version = vehicleVersionCombo?.find(
          (f) => f.value === props.vehicleKbb?.molicarID
        );
      }

      setVehicleVersionSelected(version);
      setEditMode(false);
    }
  }, [editMode, vehicleVersionCombo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setVehicleVersionSelected(undefined);
    setVehicleBrandSelected(undefined);
    setVehicleModelSelected(undefined);
    setVehicleYearSelected(undefined);
    setVehicleContext(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vehicleCategory]);

  useEffect(() => {
    if (isCarCategory) {
      setSourcePartnerContext(SourcePartnerEnum.Kbb);
    } else {
      setSourcePartnerContext(SourcePartnerEnum.Molicar);
    }
  });

  const propsVehicleSearchOptions = useMemo((): VehicleSearchOptionsProps => {
    return {
      Dropdowns: {
        Brand: {
          placeholder: t("Select") + "...",
          Options: vehicleBrandCombo,
          onChange: handleChangeBrand,
          Value: vehicleBrandSelected,
        },
        Model: {
          placeholder: t("Select") + "...",
          Options: vehicleModelCombo,
          onChange: handleChangeModel,
          Value: vehicleModelSelected,
        },
        Version: {
          placeholder: t("Select") + "...",
          Options: vehicleVersionCombo,
          onChange: handleChangeVerion,
          Value: vehicleVersionSelected,
        },
        Year: {
          placeholder: t("Select") + "...",
          Options: yearsCombo,
          onChange: handleChangeYear,
          Value: vehicleYearSelected,
        },
      },
      Texts: {
        Brand: t("Brand"),
        ClearFilter: t("Clean Filters"),
        Loading: t("Loading"),
        Model: t("Model"),
        NoMatchesFound: t("No Matches Found"),
        Others: t("Others"),
        TitleYears: t("Select the year of the model"),
        Version: t("Version"),
      },
    };
  }, [
    vehicleBrandCombo,
    yearsCombo,
    vehicleModelCombo,
    vehicleVersionCombo,
    vehicleBrandSelected,
    vehicleYearSelected,
    vehicleModelSelected,
    vehicleVersionSelected,
    t,
    handleChangeBrand,
    handleChangeYear,
    handleChangeModel,
    handleChangeVerion,
  ]);

  return (
    <>
      <Row>
        <Col>
          <VehicleSearchOptions {...propsVehicleSearchOptions} />
        </Col>
      </Row>
    </>
  );
};
