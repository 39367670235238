import { Col } from "react-bootstrap";
import styled from "styled-components";

export const WrapperFinalDataMolecule = styled.div`
  margin: 28px 0;

  .form-row {
    margin: 10px 0 0;
  }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 20px;
  }
  .form-label {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.Dark};
    line-height: 22px;
  }
  .form-check-input {
    height: unset;
    min-width: unset;
  }
  .form-check-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${(props) => props.theme.colors.Dark};
  }
  .highlight-empty .rbt-input-main,
  .highlight-empty .form-check,
  .highlight-empty input,
  .highlight-empty select {
    border: 1px solid green;
    border-radius: 4px;
  }
`;

export const WrapperAutocomplete = styled(Col)`
  margin: 0;
  > .col {
    padding: 0;
  }
  .dropdown-menu {
    border: 1px solid ${(props) => props.theme.colors.DarkGray};
  }
  input {
    height: 40px;
    background-color: ${(props) => props.theme.colors.GhostWhite};
    border: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
    border-radius: 4px;
    box-sizing: border-box;
    color: #495057;
  }
`;
