import { CoxIcon } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  ContactDetailsWrapper,
  ContactDetailsDiv,
} from "./contact-details.atom.style";

export const ContactDetailsAtom: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContactDetailsWrapper>
      <ContactDetailsDiv>
        {t("Insurance queries")}:
        <CoxIcon name="whatsapp" /> {t("Insurance Whatsapp")}&nbsp;&nbsp;/
        <CoxIcon name="phone" /> {t("Insurance Phone")}
      </ContactDetailsDiv>
      <ContactDetailsDiv>
        {t("Ituran queries")}: <CoxIcon name="phone" />
        {t("Ituran Phone")}
      </ContactDetailsDiv>
    </ContactDetailsWrapper>
  );
};
