import { Combo } from "c4u-web-components";
import { FormikHelpers, useFormik } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { IFormPaymentPortoCCMolecule } from "../../../../../models";
import { FormPaymentPortoBaseMolecule } from "./../form-payment-porto-base/form-payment-porto-base.molecule";
import { FormPaymentPortoCCMoleculeValidation } from "./form-payment-porto-cc.molecule.validation";

interface IProps {
  dataDropdownPlots: Combo[];
  onSubmit: (
    values: IFormPaymentPortoCCMolecule,
    formikHelpers: FormikHelpers<IFormPaymentPortoCCMolecule>
  ) => Promise<void>;
}

export const FormPaymentPortoCCMolecule: React.FC<IProps> = (props) => {
  const formik = useFormik<IFormPaymentPortoCCMolecule>({
    initialValues: { cardholder: "1" } as IFormPaymentPortoCCMolecule,
    onSubmit: props.onSubmit,
    validationSchema: FormPaymentPortoCCMoleculeValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <>
      <Form
        onSubmit={(e: any) => formik.handleSubmit(e)}
        className={"form-default"}
      >
        <FormPaymentPortoBaseMolecule
          formik={formik}
          dataDropdownPlots={props.dataDropdownPlots}
        />
      </Form>
    </>
  );
};
