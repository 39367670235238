import { addDays, addMonths, parse } from "date-fns";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { MsgValidation } from "../../../../../constants";
import { IFormPaymentPortoOwnMolecule } from "../../../../../models";

export const FormPaymentPortoOwnMoleculeValidation = () => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);

  const validations = Yup.object<IFormPaymentPortoOwnMolecule>({
    plots: Yup.string().required(msgRequired),
    cardholder: Yup.string().required(msgRequired),
    creditcardCvv: Yup.string()
      .required(msgRequired)
      .test("invalid", t("Invalid"), (value: any): boolean => {
        return value && /^\d{3,4}$/.test(value.trim());
      }),
    creditcardFlag: Yup.string().required(msgRequired),
    creditcardMonth: Yup.string()
      .required(msgRequired)
      .test("invalid", t("Invalid"), function (value: any) {
        if (/^\d{2}$/.test(value)) {
          const month = Number(value);
          if (this.parent.creditcardYear) {
            let dateComp = parse(
              `01/${month}/${this.parent.creditcardYear}`,
              "dd/MM/yyyy",
              new Date()
            );
            dateComp = addMonths(dateComp, 1);
            dateComp = addDays(dateComp, -1);
            return month > 0 && month <= 12 && dateComp > new Date();
          } else return month > 0 && month <= 12;
        }

        return false;
      }),
    creditcardName: Yup.string().required(msgRequired),
    creditcardNumber: Yup.string()
      .required(msgRequired)
      .test(
        "invalid",
        t("Invalid creditcard number"),
        (value: any): boolean => {
          return /^\d{4}\.\d{4}\.\d{4}\.\d{4}$/.test(value);
        }
      ),
    creditcardYear: Yup.string()
      .required(msgRequired)
      .test("invalid", t("Invalid"), (value: any): boolean => {
        if (/^\d{4}$/.test(value)) {
          const year = Number(value);
          return year >= new Date().getFullYear();
        }

        return false;
      }),
  });

  return validations;
};
