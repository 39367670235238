import React, { useRef, useState } from "react";
import {
  CoverageItem,
  CoverageItemNotAvailable,
  CoverageItemText,
  CoverageTitle,
  CreditCardInfo,
  DeductibleValue,
  HR,
  InstallmentsInfo,
  ModalBodyDescription,
  ModalBodySummary,
  ModalFooter,
  ModalHeader,
  ModalHeaderContent,
  PaymentSummary,
  PlanDescription,
  PlanHeader,
  PlanHeaderItem,
  PlanTitle,
  PrintButtonDiv,
  PrintableContentDiv,
  StyledModal,
  SummaryContent,
  SummaryLabel,
  TotalContent,
  TotalLabel,
  TotalSummary,
} from "./insurance-receipt-modal.molecule.style";
import { useTranslation } from "react-i18next";
import { ButtonPrimary, CoxIcon, NumberHelper } from "c4u-web-components";
import {
  CreditCardFlagEnum,
  InsuranceCompanyEnum,
  IInsuranceQuoteData,
  PaymentMethodEnum,
  PrintTypeEnum,
} from "../../../models";
import {
  LogoAzulSeguros,
  LogoLibertySeguros,
  LogoMastercard,
  LogoPortoSeguro,
  LogoVisa,
} from "../../assets";
import { Image } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

interface IProps {
  show: boolean;
  onHide: () => void;
  shareOnClick: () => void;
  data?: IInsuranceQuoteData;
  textButton?: string;
  content?: string;
  hideButtons?: boolean;
  onPrintProposalDetails: (
    customerId: number | undefined,
    printType: PrintTypeEnum | undefined,
    setLoadingDetails: (v: boolean) => void,
    printFallback: (() => void) | undefined
  ) => void;
}

export const ViewInsuranceReceiptModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getInsuranceCompanyLogo = (InsuranceCompany: InsuranceCompanyEnum) => {
    switch (InsuranceCompany) {
      case InsuranceCompanyEnum.Azul:
        return (
          <Image
            width={85}
            height={47}
            src={LogoAzulSeguros}
            alt="Logo Azul Seguros"
          />
        );

      case InsuranceCompanyEnum.Liberty:
        return (
          <Image
            width={90}
            height={46}
            src={LogoLibertySeguros}
            alt="Logo Liberty Seguros"
          />
        );

      case InsuranceCompanyEnum.PortoSeguro:
      default:
        return (
          <Image
            width={105.21}
            height={30}
            src={LogoPortoSeguro}
            alt="Logo Porto Seguro"
          />
        );
    }
  };

  const getCreditCardFlagLogo = (creditCardFlag: CreditCardFlagEnum) => {
    switch (creditCardFlag) {
      case CreditCardFlagEnum.Mastercard:
        return (
          <Image
            width={25}
            height={20}
            src={LogoMastercard}
            alt="Logo Mastercard"
          />
        );

      case CreditCardFlagEnum.Visa:
      default:
        return <Image width={47} height={15} src={LogoVisa} alt="Logo Visa" />;
    }
  };

  const [LoadingDetails, setLoadingDetails] = useState(false);

  return (
    <StyledModal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
      contentClassName="my-modal"
    >
      <PrintableContentDiv ref={componentRef}>
        <ModalHeader closeButton>
          <ModalHeaderContent>
            <p>{t("QuoteReceiptTitle")}</p>
            <p className="vehicle-name">{props.data?.vehicle.name}</p>
            <p className="vehicle-plate">{props.data?.vehicle.plate}</p>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalBodyDescription>
          <PlanHeader>
            {props.data && getInsuranceCompanyLogo(props.data?.company)}
            <PlanHeaderItem marginLeft="20px">
              <PlanTitle>{props.data?.quoteReceipt?.insurancePlan}</PlanTitle>
              <PlanDescription>
                {`${t("PlanDuration")} ${
                  props.data?.quoteReceipt?.monthsValidity
                } meses`}
              </PlanDescription>
            </PlanHeaderItem>
            <PlanHeaderItem marginLeft="44px">
              <PlanTitle>Franquia</PlanTitle>
              <PlanDescription>
                {t("DeductibleDescription")}
                <DeductibleValue>
                  {`R$ ${NumberHelper.toFormatString(
                    props.data?.quoteReceipt?.deductible,
                    2
                  )}`}
                </DeductibleValue>
              </PlanDescription>
            </PlanHeaderItem>
          </PlanHeader>
          <HR />
          <CoverageTitle>Coberturas</CoverageTitle>
          {props.data?.quoteReceipt?.coverages.map((m, i) => (
            <CoverageItem key={`coverage-item-${i}`}>
              <CoverageItemText>{m.title}</CoverageItemText>
              <CoverageItemText>
                {!m.has && (
                  <CoverageItemNotAvailable>
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.08366 1.73852L8.26116 0.916016L5.00033 4.17685L1.73949 0.916016L0.916992 1.73852L4.17783 4.99935L0.916992 8.26018L1.73949 9.08268L5.00033 5.82185L8.26116 9.08268L9.08366 8.26018L5.82283 4.99935L9.08366 1.73852Z"
                        fill="#FF0000"
                      />
                    </svg>
                  </CoverageItemNotAvailable>
                )}
                {m.value
                  ? `R$ ${NumberHelper.toFormatString(m.value, 2)}`
                  : m.description}
              </CoverageItemText>
            </CoverageItem>
          ))}
        </ModalBodyDescription>
        <ModalBodySummary>
          <TotalSummary>
            <TotalLabel>Total</TotalLabel>
            <TotalContent>
              {`R$ ${NumberHelper.toFormatString(
                props.data?.quoteReceipt?.quotePaymentMethod.totalValue,
                2
              )}`}
            </TotalContent>
          </TotalSummary>
          <PaymentSummary>
            <CreditCardInfo>
              <SummaryLabel>
                {props.data?.quoteReceipt &&
                  t(
                    PaymentMethodEnum[
                      props.data?.quoteReceipt.quotePaymentMethod.paymentMethod
                    ]
                  )}
              </SummaryLabel>
              {props.data?.company !== InsuranceCompanyEnum.Liberty && (
                <>
                  {props.data?.quoteReceipt &&
                    getCreditCardFlagLogo(
                      props.data?.quoteReceipt?.quotePaymentMethod
                        .creditCardFlag
                    )}
                  <SummaryContent>
                    {t("Creditcard ending {number}", {
                      number:
                        props.data?.quoteReceipt?.quotePaymentMethod
                          .creditCardEnding,
                    })}
                  </SummaryContent>
                </>
              )}
            </CreditCardInfo>
            <InstallmentsInfo>
              <SummaryLabel>Forma de Pagamento</SummaryLabel>
              <SummaryContent>
                {`${
                  props.data?.quoteReceipt?.quotePaymentMethod.plots
                }x R$ ${NumberHelper.toFormatString(
                  props.data?.quoteReceipt?.quotePaymentMethod.plotPrice,
                  2
                )}`}
              </SummaryContent>
            </InstallmentsInfo>
          </PaymentSummary>
        </ModalBodySummary>
      </PrintableContentDiv>
      <ModalFooter>
        <PrintButtonDiv
          onClick={() =>
            props.onPrintProposalDetails(
              props.data?.customerId,
              props.data?.printType,
              setLoadingDetails,
              handlePrint
            )
          }
          isLoading={LoadingDetails}
        >
          <CoxIcon name="print" width={20} height={18} />
          {props.textButton ? props.textButton : t("Imprimir")}
        </PrintButtonDiv>
        <div style={{ display: "none" }}>
          <ButtonPrimary onClick={props.shareOnClick} sizex="md">
            {t("Share")}
          </ButtonPrimary>
        </div>
      </ModalFooter>
    </StyledModal>
  );
};
