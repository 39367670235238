import React from "react";
import { useTranslation } from "react-i18next";
import { C4uStepperMolecule } from "c4u-web-components";
import {
  InsuranceStepsEnum,
  IPasswordlessParams,
  OriginEnum,
} from "../../../../models";
import { useInsuranceContext } from "../../../../hooks";
import { useParams } from "react-router-dom";
import { Definition } from "../../../../constants";

interface IProps {
  currentStep: InsuranceStepsEnum;
}

export const HeaderStepsMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { typeJourney } = useParams<IPasswordlessParams>();
  const { origin } = useInsuranceContext();

  const nameStepOne =
    origin === OriginEnum.Dt ? t("FinalData") : t("CustomerData");

  const steps =
    typeJourney === Definition.Passwordless
      ? [t("Authorize"), t("VehicleData"), t("CustomerData"), t("Quotes"), t("Payment")]
      : [nameStepOne, t("Quotes"), t("Payment")];

  return <C4uStepperMolecule steps={steps} currentStep={props.currentStep} />;
};
