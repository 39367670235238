import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Definition } from "../../../constants";
import { useInsurance, useInsuranceContext } from "../../../hooks";
import { IPasswordlessParams } from "../../../models";

export const BaseTemplate: React.FC = (props) => {
  const { id, typeJourney } = useParams<IPasswordlessParams>();
  const { getCustomerById } = useInsurance();
  const { isAuthenticated } = useAuth0();

  const {
    customerContext,
    idCustomerContext,
    setCustomerContext,
    setIdCustomerContext,
    setOrigin,
  } = useInsuranceContext();

  const isPasswordless = useMemo(
    () => typeJourney === Definition.Passwordless,
    [typeJourney]
  );

  useEffect(() => {
    if (id) setIdCustomerContext(id);
  }, [id, setIdCustomerContext]);

  useEffect(() => {
    if (
      (isAuthenticated || isPasswordless) &&
      idCustomerContext &&
      idCustomerContext !== customerContext?.customer?.id?.toString()
    ) {
      getCustomerById(Number(idCustomerContext)).then((t) => {
        setOrigin(t.customer.origin);
        setCustomerContext(t);
      });
    }
  }, [idCustomerContext, isAuthenticated, getCustomerById, isPasswordless]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{props.children}</>;
};
