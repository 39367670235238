import { TransferToUserModalAtom } from "c4u-web-components";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../constants";
import {
  useC4u,
  useInsurance,
  useInsuranceContext,
  useSessionContext,
} from "../../../hooks";
import { IPasswordlessParams } from "../../../models";
import { ContactDetailsAtom } from "../../atoms";
import { FeedbackModal } from "../../molecules";
import { HeaderWithStepsOrganism, PaymentOrganism } from "../../organisms";

export const PaymentPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, typeJourney } = useParams<IPasswordlessParams>();

  const { getListMyUsers } = useC4u();
  const { putTransferInsurance } = useInsurance();

  const { coxUserContext } = useSessionContext();
  const { selectedPlanContext } = useInsuranceContext();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [showChooseUserModal, setShowChooseUserModal] = useState(false);

  const isPasswordless = useMemo(
    () => typeJourney === Definition.Passwordless,
    [typeJourney]
  );

  useEffect(() => {
    if (id && !selectedPlanContext) history.push(paths.quotes(id, typeJourney));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlanContext, id, typeJourney]);

  useEffect(() => {
    if (coxUserContext?.isCorporate) setShowChooseUserModal(true);
  }, [coxUserContext?.isCorporate]);

  useEffect(() => {
    if (showErrorModal || showSuccessModal) setShowChooseUserModal(false);
  }, [showErrorModal, showSuccessModal]);

  return (
    <>
      <HeaderWithStepsOrganism
        currentStep={isPasswordless ? 5 : 3}
        returnButtonActionOverride={() =>
          history.replace(paths.quotes(id, typeJourney))
        }
        description={<ContactDetailsAtom />}
      />

      <div className={"default-align"}>
        <PaymentOrganism />
      </div>

      <TransferToUserModalAtom
        entryId={Number(id)}
        show={showChooseUserModal}
        onSubmitCallback={putTransferInsurance}
        getUsersPagedListCallback={getListMyUsers}
        onSucessCallBack={() => setShowSuccessModal(true)}
        setShowErrorModal={setShowErrorModal}
        setErrorMsg={setErrorMsg}
        translate={t}
      />
      <FeedbackModal
        type={"error"}
        show={showErrorModal}
        onHide={() => window.location.reload()}
        title={t("A problem found")}
        content={errorMsg ?? t("GenericErrorMsg")}
      />
      <FeedbackModal
        type={"success"}
        show={showSuccessModal}
        onHide={() => history.push(paths.myQuotes(typeJourney))}
        title={t("Everything all right")}
        content={t("Insurance proposal forwarded successfully")}
      />
    </>
  );
};
