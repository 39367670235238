import { useContext } from "react";
import { InsuranceContext } from "../../contexts";
import {
  IGetCustomerResponse,
  InsuranceCompanyEnum,
  IQuoteContext,
  IQuoteModelPlan,
  IVehicle,
  IVehicleData,
  OriginEnum,
} from "../../models";
import { VehicleCategoriesEnum, SourcePartnerEnum } from "c4u-web-components";

export interface InsuranceProviderState {
  idCustomerContext: string | undefined;
  setIdCustomerContext: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  customerContext: IGetCustomerResponse | undefined;
  setCustomerContext: React.Dispatch<
    React.SetStateAction<IGetCustomerResponse | undefined>
  >;
  quotesContext: IQuoteContext | undefined;
  setQuotesContext: React.Dispatch<
    React.SetStateAction<IQuoteContext | undefined>
  >;
  selectedCompanyContext: InsuranceCompanyEnum | undefined;
  setSelectedCompanyContext: React.Dispatch<
    React.SetStateAction<InsuranceCompanyEnum | undefined>
  >;
  selectedPlanContext: string | undefined;
  setSelectedPlanContext: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  getSelectedPlanContext: () => IQuoteModelPlan | undefined;
  vehicleContext: IVehicle;
  setVehicleContext: React.Dispatch<React.SetStateAction<IVehicle | undefined>>;
  vehicleDataContext: IVehicleData;
  setVehicleDataContext: React.Dispatch<
    React.SetStateAction<IVehicleData | undefined>
  >;
  paymentLinkContext: string;
  setPaymentLinkContext: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  origin: OriginEnum;
  setOrigin: React.Dispatch<React.SetStateAction<OriginEnum | undefined>>;
  clearInsuranceContexts: () => void;
  vehicleCategoryContext: VehicleCategoriesEnum;
  setVehicleCategoryContext: React.Dispatch<VehicleCategoriesEnum | undefined>;
  sourcePartnerContext: SourcePartnerEnum;
  setSourcePartnerContext: React.Dispatch<SourcePartnerEnum | undefined>;
}

export const useInsuranceContext = () => {
  const context = useContext<InsuranceProviderState>(InsuranceContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
