import { YupHelper } from "c4u-web-components";
import { addMonths, parse } from "date-fns";
import { addDays } from "date-fns/esm";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { MsgValidation } from "../../../../../constants";
import { IFormPaymentPortoOthersMolecule } from "../../../../../models";

export const FormPaymentPortoOthersMoleculeValidation = () => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);
  const msgInvalid = t(MsgValidation.Invalid);

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDatePtBrValid",
    YupHelper.isDatePtBrValid
  );

  const validations = Yup.object<IFormPaymentPortoOthersMolecule>({
    plots: Yup.string().required(msgRequired),
    birthdate: Yup.string().when("document", {
      is: (v) => YupHelper.cpfValid(v),
      then: Yup.string()
        .nullable()
        .required(msgRequired)
        .isDatePtBrValid(t(MsgValidation.Date)),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    cardholder: Yup.string().required(msgRequired),
    city: Yup.string().required(msgRequired),
    complement: Yup.string().notRequired(),
    district: Yup.string().required(msgRequired),
    creditcardCvv: Yup.string()
      .required(msgRequired)
      .test("invalid", t("Invalid"), (value: any): boolean => {
        return value && /^\d{3,4}$/.test(value.trim());
      }),
    creditcardFlag: Yup.string().required(msgRequired),
    creditcardMonth: Yup.string()
      .required(msgRequired)
      .test("invalid", t("Invalid"), function (value: any) {
        if (/^\d{2}$/.test(value)) {
          const month = Number(value);
          if (this.parent.creditcardYear) {
            let dateComp = parse(
              `01/${month}/${this.parent.creditcardYear}`,
              "dd/MM/yyyy",
              new Date()
            );
            dateComp = addMonths(dateComp, 1);
            dateComp = addDays(dateComp, -1);
            return month > 0 && month <= 12 && dateComp > new Date();
          } else return month > 0 && month <= 12;
        }

        return false;
      }),
    creditcardName: Yup.string().required(msgRequired),
    creditcardNumber: Yup.string()
      .required(msgRequired)
      .test(
        "invalid",
        t("Invalid creditcard number"),
        (value: any): boolean => {
          return /^\d{4}\.\d{4}\.\d{4}\.\d{4}$/.test(value);
        }
      ),
    creditcardYear: Yup.string()
      .required(msgRequired)
      .test("invalid", t("Invalid"), (value: any): boolean => {
        if (/^\d{4}$/.test(value)) {
          const year = Number(value);
          return year >= new Date().getFullYear();
        }

        return false;
      }),
    document: Yup.string().required(msgRequired).isCpfCnpjValid(msgInvalid),
    email: Yup.string().email(msgInvalid).required(msgRequired),
    number: Yup.string().required(msgRequired),
    phone: Yup.string().required(msgRequired),
    state: Yup.string().required(msgRequired),
    street: Yup.string().required(msgRequired),
    addressType: Yup.string().required(msgRequired),
    maritalStatus: Yup.string().when("document", {
      is: (v) => YupHelper.cpfValid(v),
      then: Yup.string().nullable().required(msgRequired),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    gender: Yup.string().when("document", {
      is: (v) => YupHelper.cpfValid(v),
      then: Yup.string().nullable().required(msgRequired),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    zipcode: Yup.string()
      .required(msgRequired)
      .test("invalid", msgInvalid, (value: any): boolean => {
        return /^\d{5}-\d{3}$/.test(value);
      }),
  });

  return validations;
};
