import { IDealershipComboData } from "c4u-web-components";
import React, { useEffect } from "react";
import { useInsurance } from "../../../hooks";
import { IGetQuotesResponse } from "../../../models";
import {
  HeaderSearchFilterMolecule,
  HeaderTitleMolecule,
} from "../../molecules";
import { HeaderSearchOrganismWrapper } from "./header-with-search.organism.style";

interface IProps {
  title: string;
  setQuotesHistoryData: (data: IGetQuotesResponse | undefined) => void;
  disableReturnButton?: boolean;
  disableFormSubmit?: boolean;
  navigationButtonText?: string;
  navigationButtonAction?: () => void;
  pageNumber?: number;
  isPasswordless: boolean;
  selectedDealership?: IDealershipComboData;
}

export const HeaderWithSearchOrganism: React.FC<IProps> = (props) => {
  const { getQuotesHistory, getUpdateList } = useInsurance();

  useEffect(() => {
    // Triggers updates to DB - has no return
    getUpdateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HeaderSearchOrganismWrapper>
      <HeaderTitleMolecule
        title={props.title}
        navigationButtonText={props.navigationButtonText}
        navigationButtonAction={props.navigationButtonAction}
        disableReturnButton={props.disableReturnButton}
      />
      <HeaderSearchFilterMolecule
        getQuotesHistory={getQuotesHistory}
        setQuotesHistoryData={props.setQuotesHistoryData}
        disableFormSubmit={props.disableFormSubmit}
        pageNumber={props.pageNumber}
        isPasswordless={props.isPasswordless}
        selectedDealership={props.selectedDealership}
      />
    </HeaderSearchOrganismWrapper>
  );
};
