import axios, { CancelToken } from "axios";
import {
  ButtonPrimary,
  HttpHelper,
  SortHelper,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import {
  Definition,
  componentUnmountedMsgKey,
  paths,
} from "../../../constants";
import { useInsurance, useSessionContext } from "../../../hooks";
import { useInsuranceContext } from "../../../hooks/contexts/use-insurance-context.hook";
import {
  IPlanChoiceRequest,
  IQuoteModel,
  InsuranceCompanyEnum,
  ParamsModel,
  PlanChoiceNextActionEnum,
  PrintTypeEnum,
} from "../../../models";
import { QuoteMolecule } from "../../molecules";
import {
  IturanTrackerOfferDiv,
  IturanTrackerOfferWrapper,
  QuotesMappingDiv,
  QuotesOrganismWrapper,
} from "./quotes.organism.style";

interface IProps {
  title?: string;
}

interface IHashQuote {
  hash: string;
  quote?: IQuoteModel;
}

interface IHashesByCompany {
  hashes: IHashQuote[];
  company: InsuranceCompanyEnum;
}

export const QuotesOrganism: React.FC<IProps> = (props) => {
  const { showGenericErrorModal, showGenericSuccessModal, handleEventGTM } =
    useSessionContext();
  const {
    setQuotesContext,
    quotesContext,
    setSelectedCompanyContext,
    setSelectedPlanContext,
    selectedPlanContext,
    setPaymentLinkContext,
  } = useInsuranceContext();

  const { t } = useTranslation();

  const { id: idParam, typeJourney } = useParams<ParamsModel>();
  const { savePlan, startQuote, quoteResult, getQuotePdf } = useInsurance();

  const [quotes, setQuotes] = useState<IQuoteModel[]>();
  const [quotesError, setQuotesError] = useState<string>();
  const [quoteByHash, setQuoteByHash] = useState<IHashQuote>();
  const [hashesByCompany, setHashesByCompany] = useState<IHashesByCompany[]>();

  const loadDefaultPlanSelect = useCallback(
    (quotesParam: IQuoteModel[], planId?: string) => {
      for (let i = 0; i < quotesParam?.length; i++) {
        for (let x = 0; x < quotesParam[i].plans?.length; x++) {
          if (
            (!planId && quotesParam[i].plans[x].selected) ||
            planId === quotesParam[i].plans[x].planId
          ) {
            setChoose(
              quotesParam[i].plans[x].planId,
              quotesParam[i].plans[x].printType,
              quotesParam[i].plans[x].price,
              quotesParam[i].company,
              quotesParam[i].planRequestHash
            );
            setSelectedCompanyContext(quotesParam[i].company);
            setSelectedPlanContext(quotesParam[i].plans[x].planId);
          }
        }
      }
    },
    [setSelectedPlanContext, setSelectedCompanyContext]
  );
  const viewItemsListGtmCallback = useCallback(() => {
    const quotationsUnstructured = quotes?.map((itemQuote) => itemQuote.plans);
    if (!!quotes && quotes.length) {
      handleEventGTM({
        event: "view_item_list",
        ecommerce: {
          items: {
            item_name: quotes.map((quote) =>
              quote.companyName === t("ErrorApi")
                ? t("notAvailable")
                : quote.companyName
            ),
            item_id: quotationsUnstructured?.map((item) =>
              item.map((item) => item.planId)
            ),
            price: quotationsUnstructured?.map((item) =>
              item.map((item) => item.annualPrice)
            ),
            currency: "BRL",
            affiliation: quotes.map((quote) =>
              quote.companyName === t("ErrorApi")
                ? t("notAvailable")
                : quote.companyName
            ),
            item_category: "Seguros Auto",
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotes, t]);

  const loadAllQuotes = useCallback(
    (quotesItemsParam: IQuoteModel[]) => {
      const quotesItems = quotesItemsParam.sort((a, b) =>
        SortHelper.ascProp(a, b, "company")
      );
      setQuotes(quotesItems);
      setQuotesContext({ quotes: quotesItems, customerId: Number(idParam) });
      loadDefaultPlanSelect(quotesItems);
    },
    [setQuotesContext, idParam, loadDefaultPlanSelect]
  );

  const loadQuotes = useCallback(
    async (id: number, cancelToken: CancelToken) => {
      if (!quotesContext || quotesContext.customerId !== id) {
        setQuotesContext(undefined);
        try {
          const hashes = await startQuote(id, cancelToken);
          let hashesByCompany = [] as IHashesByCompany[];
          hashes
            .sort((a, b) => SortHelper.ascProp(a, b, "companyId"))
            .forEach((hash) => {
              const item = hashesByCompany.find(
                (f) => f.company === hash.companyId
              );
              if (!item)
                hashesByCompany.push({
                  company: hash.companyId,
                  hashes: [{ hash: hash.hash }],
                } as IHashesByCompany);
              else item.hashes.push({ hash: hash.hash });
            });
          setQuotes(
            hashesByCompany.map((m) => ({
              company: m.company,
              plans: [],
              companyName: "",
              active: true,
              planRequestHash: "",
            }))
          );
          setHashesByCompany(hashesByCompany);
          hashes.forEach(async (f) => {
            try {
              const quotesService = await quoteResult(id, f.hash, cancelToken);
              setQuoteByHash({ hash: f.hash, quote: quotesService });
            } catch (error) {
              if ((error as any)?.stack?.message === componentUnmountedMsgKey)
                return;
              setQuotesError(f.hash);
            }
          });
        } catch (error) {
          if ((error as any)?.stack?.message === componentUnmountedMsgKey)
            return;
          setQuotes([]);
        }
      } else {
        setQuotes(quotesContext.quotes);
        loadDefaultPlanSelect(quotesContext.quotes, selectedPlanContext);
      }
    },
    [
      quotesContext,
      setQuotesContext,
      startQuote,
      quoteResult,
      loadDefaultPlanSelect,
      selectedPlanContext,
    ]
  );

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (idParam) loadQuotes(Number(idParam), source.token);

    return () => {
      source.cancel(componentUnmountedMsgKey);
    };
    // eslint-disable-next-line
  }, [idParam]);

  useEffect(() => {
    if (quotesError) {
      const hashByCompany = hashesByCompany?.find((f) =>
        f.hashes.find((f) => f.hash === quotesError)
      );
      const newQuote = quotes?.find(
        (f) => f.company === hashByCompany?.company
      );
      if (newQuote && quotes)
        loadAllQuotes([
          ...quotes.filter((f) => f.company !== hashByCompany?.company),
          { ...newQuote, companyName: t("ErrorApi") },
        ]);
      setQuotesError(undefined);
    }
    // eslint-disable-next-line
  }, [quotesError]);

  useEffect(() => {
    if (quoteByHash && hashesByCompany) {
      const hashByCompany = hashesByCompany.find((f) =>
        f.hashes.find((f) => f.hash === quoteByHash.hash)
      );
      const hashes = hashByCompany?.hashes.find(
        (f) => f.hash === quoteByHash.hash
      );
      if (hashes) hashes.quote = quoteByHash.quote;
      if (quotes && hashes && hashes.quote) {
        const oldQuote = quotes.find(
          (f) => f.company === hashes.quote?.company
        );

        if (oldQuote?.plans.length) hashes.quote.plans.push(oldQuote.plans[0]);
        const quotesItems = [
          ...quotes.filter((f) => f.company !== hashes.quote?.company),
          hashes.quote,
          // { ...hashes.quote, onlyTracker: true },
        ];
        loadAllQuotes(quotesItems);
      }
    }
    // eslint-disable-next-line
  }, [quoteByHash]);

  const history = useHistory();

  const [plan, setPlan] = useState<string>();
  const [hash, setHash] = useState<string>();
  const [company, setCompany] = useState<InsuranceCompanyEnum>();
  const [printType, setPrintType] = useState<PrintTypeEnum>();
  const [price, setPrice] = useState<number>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = useCallback(async () => {
    if (!company || !plan || !hash || !price || printType === undefined) return;

    try {
      const id = Number(idParam);
      const request: IPlanChoiceRequest = {
        customerId: id,
        insuranceCompany: company,
        planId: plan,
        planRequestHash: hash,
        printType,
        value: price,
      };
      const data = await savePlan(request);
      setSelectedCompanyContext(request.insuranceCompany);
      setSelectedPlanContext(request.planId);

      switch (data.nextAction) {
        case PlanChoiceNextActionEnum.PaymentLiberty:
          setPaymentLinkContext(data.link);
          break;
        case PlanChoiceNextActionEnum.Tracker:
          if (typeJourney === Definition.Passwordless) {
            const storage = {
              passwordless_token: sessionStorage.getItem("passwordless_token"),
              passwordless_expire: sessionStorage.getItem(
                "passwordless_expire"
              ),
              passwordless_email_cellphone: sessionStorage.getItem(
                "passwordless_email_cellphone"
              ),
            };
            const queryParams = HttpHelper.BuildQueryString(storage);
            data.link += `/${typeJourney}${queryParams}`;
          }

          window.location.href = data.link;
          return;
        case PlanChoiceNextActionEnum.PaymentStandard:
        default:
          setPaymentLinkContext(undefined);
          break;
      }
      history.push(paths.payment(id, typeJourney));
    } catch (error) {
      showGenericErrorModal();
    }
  }, [
    savePlan,
    company,
    hash,
    printType,
    price,
    history,
    idParam,
    plan,
    showGenericErrorModal,
    setSelectedCompanyContext,
    setSelectedPlanContext,
    setPaymentLinkContext,
    typeJourney,
  ]);

  useEffect(() => {
    if (isSubmitting) submit();
    // eslint-disable-next-line
  }, [isSubmitting]);

  const setChoose = (
    planId: string,
    printType: PrintTypeEnum,
    price: number,
    company: InsuranceCompanyEnum,
    hash: string
  ) => {
    setPlan(planId);
    setPrintType(printType);
    setPrice(price);
    setCompany(company);
    setHash(hash);
  };

  const onViewQuoteDetails = useCallback(
    (
      companyId: InsuranceCompanyEnum,
      planId: string,
      printType: PrintTypeEnum,
      setLoadingDetails: (v: boolean) => void
    ) => {
      if (printType === PrintTypeEnum.None) return;

      setLoadingDetails(true);
      if (printType === PrintTypeEnum.Link) {
        getQuotePdf(companyId, planId)
          .then((link) => {
            window.open(link, "_blank");
          })
          .catch(() => {
            showGenericErrorModal(
              t("QuoteDetailsLinkErrorMsg"),
              null,
              t("QuoteDetailsLinkErrorTitle")
            );
          })
          .finally(() => setLoadingDetails(false));
      } else if (printType === PrintTypeEnum.Email) {
        getQuotePdf(companyId, planId)
          .then(() => {
            showGenericSuccessModal(
              t("QuoteDetailsEmailSuccessMsg"),
              null,
              t("QuoteDetailsEmailSuccessTitle")
            );
          })
          .catch(() => {
            showGenericErrorModal(
              t("QuoteDetailsEmailErrorMsg"),
              null,
              t("QuoteDetailsEmailErrorTitle")
            );
          })
          .finally(() => setLoadingDetails(false));
      }
    },
    [getQuotePdf, t, showGenericSuccessModal, showGenericErrorModal]
  );

  const [showTrackerMessage, setShowTrackerMessage] = useState(false);
  const [finishedLoadingInsurance, setFinishedLoadingInsurance] =
    useState(false);

  useEffect(() => {
    const insuranceQuotes =
      quotesContext?.quotes &&
      quotesContext.quotes.filter(
        (f) => f.company !== InsuranceCompanyEnum.Ituran
      );
    const trackerQuotes =
      quotesContext?.quotes &&
      quotesContext.quotes.find(
        (f) => f.company === InsuranceCompanyEnum.Ituran
      );

    if (insuranceQuotes && insuranceQuotes.every((e) => !!e.companyName)) {
      setFinishedLoadingInsurance(true);
    }
    if (
      insuranceQuotes?.every((e) => !!e.companyName && e.plans.length === 0) &&
      trackerQuotes?.companyName &&
      trackerQuotes.onlyTracker &&
      trackerQuotes.plans.length > 0
    ) {
      setShowTrackerMessage(true);
    }
  }, [quotesContext]);

  const handleGtmSelectInsurance = useCallback(
    (planId: string) => {
      const selectedPlanId = quotesContext?.quotes?.find(
        (f) => !!f.plans.find((f) => f.planId === planId)
      );
      if (!selectedPlanId) return;

      const selectedQuotesModule = { ...selectedPlanId };

      const selectedIndex = selectedQuotesModule?.plans.findIndex(
        (plan) => plan.planId === planId
      );
      selectedQuotesModule.plans = selectedQuotesModule?.plans.filter(
        (f) => f.planId === planId
      );

      handleEventGTM({
        event: "select_list",
        ecommerce: {
          items: {
            item_name:
              selectedQuotesModule.companyName === t("ErrorApi")
                ? t("notAvailable")
                : selectedQuotesModule.companyName,
            item_id: selectedQuotesModule.plans.map((quote) => quote.planId),
            price: selectedQuotesModule.plans.map((quote) => quote.annualPrice),
            currency: "BRL",
            affiliation:
              selectedQuotesModule.companyName === t("ErrorApi")
                ? t("notAvailable")
                : selectedQuotesModule.companyName,
            item_category: "Seguros Auto",
            item_list_name: "Cotações",
            index: selectedIndex,
            quantity: 1,
          },
        },
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quotesContext?.quotes, t]
  );

  useEffect(() => {
    if (finishedLoadingInsurance) {
      viewItemsListGtmCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedLoadingInsurance]);

  useEffect(() => {
    if (!plan) return;
    handleGtmSelectInsurance(plan);
  }, [handleGtmSelectInsurance, plan]);

  return (
    <>
      <QuotesOrganismWrapper reduceMarginTop={showTrackerMessage}>
        {showTrackerMessage && (
          <IturanTrackerOfferWrapper>
            <IturanTrackerOfferDiv>
              {t("IturanTrackerOffer")}
            </IturanTrackerOfferDiv>
          </IturanTrackerOfferWrapper>
        )}

        {!quotes ? (
          <SpinnerCenteredAtom />
        ) : quotes?.length === 0 ? (
          <div
            className={"h-100 d-flex align-items-center justify-content-center"}
          >
            {t("NoQuotesFound")}
          </div>
        ) : (
          <QuotesMappingDiv>
            {quotes?.map((q, i) => (
              <div key={`quotes${i}`} className={"mb-4"}>
                <QuoteMolecule
                  onChoose={(
                    planId: string,
                    printType: PrintTypeEnum,
                    price: number
                  ) =>
                    setChoose(
                      planId,
                      printType,
                      price,
                      q.company,
                      q.planRequestHash
                    )
                  }
                  choosenPlan={plan}
                  company={q.company}
                  active={q.active}
                  plans={q.plans}
                  loading={q.companyName === ""}
                  showTrackerMessage={showTrackerMessage}
                  finishedLoadingInsurance={finishedLoadingInsurance}
                  onlyTracker={!!q.onlyTracker}
                  onViewDetails={(
                    planId: string,
                    printType: PrintTypeEnum,
                    setLoadingDetails: (v: boolean) => void
                  ) => {
                    onViewQuoteDetails(
                      q.company,
                      planId,
                      printType,
                      setLoadingDetails
                    );
                  }}
                />
              </div>
            ))}
          </QuotesMappingDiv>
        )}
      </QuotesOrganismWrapper>

      <Row>
        <Col className={"text-right py-4"}>
          <ButtonPrimary
            sizex={"md"}
            disabled={!plan}
            loading={isSubmitting}
            onClick={() => setIsSubmitting(true)}
          >
            {t("Continue")}
          </ButtonPrimary>
        </Col>
      </Row>
    </>
  );
};
