import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../constants";
import { useInsuranceContext } from "../../../hooks";
import { IPasswordlessParams } from "../../../models";
import { ContactDetailsAtom } from "../../atoms";
import { HeaderWithStepsOrganism, QuotesOrganism } from "../../organisms";

export const QuotesPage: React.FC = () => {
  const { id: customerId, typeJourney } = useParams<IPasswordlessParams>();
  const history = useHistory();

  const { customerContext } = useInsuranceContext();

  const isPasswordless = useMemo(
    () => typeJourney === Definition.Passwordless,
    [typeJourney]
  );

  return (
    <>
      <HeaderWithStepsOrganism
        currentStep={isPasswordless ? 4 : 2}
        returnButtonActionOverride={() =>
          history.replace(
            paths.finalData(
              customerContext?.customer?.publicPersonId,
              customerContext?.customer?.encodeLenderCode,
              typeJourney
            )
          )
        }
        disableReturnButton={
          customerContext === undefined ||
          customerContext.customer?.id?.toString() !== customerId
        }
        description={<ContactDetailsAtom />}
      />
      <QuotesOrganism />
    </>
  );
};
