export {
  InsuranceCompanyEnum,
  GenderEnum,
  MaritalStatusEnum,
  NationalityEnum,
  AddressTypeEnum,
  PoliticallyExposedPersonEnum,
  RelationshipTypeEnum,
  DocumentTypeEnum,
  ProposalStepEnum,
} from "c4u-web-components";

export enum CnhTypeEnum {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  AB = "AB",
  AC = "AC",
  AD = "AD",
  AE = "AE",
}

export enum QuoteStepStatusEnum {
  Successful = 1,
  Failed,
}

export enum GenderStringEnum {
  Male = "M",
  Female = "F",
}

export enum OriginEnum {
  Cpv,
  Kbb,
  Dt,
}

export enum PrintTypeEnum {
  None,
  Link,
  Email,
}

export enum PlanChoiceNextActionEnum {
  PaymentStandard,
  PaymentLiberty,
  Tracker
}
