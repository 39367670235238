import { TypesHelper } from "c4u-web-components";
import { IQuoteModelCoverage } from "../..";
import {
  IInvoiceAddress,
  IPaymentRequest,
  IPaymentRequestCreditCard,
  IProposalDetail,
} from "./payment.model";

export class InvoiceAddress {
  zipCode?: string | null;
  street?: string | null;
  number?: string | null;
  complement?: string | null;
  neighborhood?: string | null;
  city?: string | null;
  state?: string | null;
  addressType?: number | null;
  constructor({
    zipCode,
    street,
    number,
    complement,
    neighborhood,
    city,
    state,
    addressType,
  }: IInvoiceAddress) {
    this.zipCode = TypesHelper.toStringOnlyNumbersNull(zipCode);
    this.street = TypesHelper.toStringNull(street);
    this.number = TypesHelper.toStringNull(number);
    this.complement = TypesHelper.toStringNull(complement);
    this.neighborhood = TypesHelper.toStringNull(neighborhood);
    this.city = TypesHelper.toStringNull(city);
    this.state = TypesHelper.toStringNull(state);
    this.addressType = TypesHelper.toNumberNull(addressType);
  }
}

export class PaymentRequestCreditCard {
  flagCard: number | null;
  numberCard?: string | null;
  name?: string | null;
  month?: string | null;
  year?: string | null;
  cvv?: number | null;
  cpfCnpj?: string | null;
  birthDate?: Date | null;
  phone?: string | null;
  invoiceAddress?: InvoiceAddress | null;
  maritalStatus?: number | null;
  gender?: number | null;
  ramal?: string | null;
  observation?: string | null;
  constructor({
    flagCard,
    numberCard,
    name,
    month,
    year,
    cvv,
    cpfCnpj,
    birthDate,
    maritalStatus,
    gender,
    ramal,
    observation,
    invoiceAddress,
  }: IPaymentRequestCreditCard) {
    this.flagCard = TypesHelper.toNumberNull(flagCard);
    this.numberCard = TypesHelper.toStringOnlyNumbersNull(numberCard);
    this.name = TypesHelper.toStringNull(name);
    this.month = TypesHelper.toStringOnlyNumbersNull(month);
    this.year = TypesHelper.toStringOnlyNumbersNull(year);
    this.cvv = TypesHelper.toNumberNull(cvv);
    this.cpfCnpj = TypesHelper.toStringOnlyNumbersNull(cpfCnpj);
    this.birthDate = TypesHelper.toDateNull(birthDate);
    this.maritalStatus = TypesHelper.toNumberNull(maritalStatus);
    this.gender = TypesHelper.toNumberNull(gender);
    this.ramal = TypesHelper.toStringNull(ramal);
    this.observation = TypesHelper.toStringNull(observation);
    this.invoiceAddress =
      invoiceAddress === undefined ? null : new InvoiceAddress(invoiceAddress);
  }
}

export class ProposalCoverage {
  title?: string | null;
  description?: string | null;
  value: number | null;
  constructor({ title, description, value }: IQuoteModelCoverage) {
    this.title = TypesHelper.toStringNull(title);
    this.description = TypesHelper.toStringNull(description);
    this.value = TypesHelper.toNumberNull(value);
  }
}

export class PaymentRequestProposalDetail {
  description?: string | null;
  monthsValidity: number | null;
  deductible: number | null;
  coverages?: ProposalCoverage[] | null;
  firstPlotPrice: number | null;
  othersPlotsPrice: number | null;
  interestPrice: number | null;
  constructor({
    description,
    monthsValidity,
    deductible,
    coverages,
    firstPlotPrice,
    othersPlotsPrice,
    interestPrice,
  }: IProposalDetail) {
    this.description = TypesHelper.toStringNull(description);
    this.monthsValidity = TypesHelper.toNumberNull(monthsValidity);
    this.deductible = TypesHelper.toNumberNull(deductible);
    this.coverages = coverages?.map((m) => new ProposalCoverage(m));
    this.firstPlotPrice = TypesHelper.toNumberNull(firstPlotPrice);
    this.othersPlotsPrice = TypesHelper.toNumberNull(othersPlotsPrice);
    this.interestPrice = TypesHelper.toNumberNull(interestPrice);
  }
}

export class PaymentRequest {
  customerId: number | null;
  paymentFormCode: number | null;
  numberInstallments: number | null;
  email?: string | null;
  phone?: string | null;
  creditCard: PaymentRequestCreditCard | null;
  proposalDetail: PaymentRequestProposalDetail | null;
  constructor({
    customerId,
    paymentFormCode,
    numberInstallments,
    email,
    phone,
    creditCard,
    proposalDetail,
  }: IPaymentRequest) {
    this.customerId = TypesHelper.toNumberNull(customerId);
    this.paymentFormCode = TypesHelper.toNumberNull(paymentFormCode);
    this.numberInstallments = TypesHelper.toNumberNull(numberInstallments);
    this.email = TypesHelper.toStringNull(email);
    this.phone = TypesHelper.toStringOnlyNumbersNull(phone);
    this.creditCard = !!creditCard
      ? new PaymentRequestCreditCard(creditCard)
      : null;
    this.proposalDetail = new PaymentRequestProposalDetail(proposalDetail);
  }
}
