import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { HeaderWithStepsOrganism, VehicleChooseOrganism } from "..";
import { Definition, paths } from "../../../constants";
import { IPasswordlessParams } from "../../../models";
import { ContactDetailsAtom } from "../../atoms";
import { HeaderTitleMolecule } from "../../molecules";
import { NewQuoteWrapper } from "./new-quote.organism.style";

interface IProps {
  title: string;
}

export const NewQuoteOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { typeJourney } = useParams<IPasswordlessParams>();
  const history = useHistory();

  return (
    <NewQuoteWrapper>
      {typeJourney === Definition.Passwordless ? (
        <HeaderWithStepsOrganism
          currentStep={2}
          disableReturnButton
          returnButtonActionOverride={() =>
            history.push(paths.myQuotes(typeJourney))
          }
          description={<ContactDetailsAtom />}
        />
      ) : (
        <>
          <HeaderTitleMolecule
            title={props.title}
            navigationButtonText={t("My quotes")}
            navigationButtonAction={() => history.push(paths.myQuotes())}
            description={<ContactDetailsAtom />}
            disableReturnButton
            alignLeft
          />
          <hr />
        </>
      )}
      <VehicleChooseOrganism />
    </NewQuoteWrapper>
  );
};
