import styled from "styled-components";

interface IStyleProps {
  verticalMargin?: string;
}

const WrapperFormSectionMolecule = styled.div<IStyleProps>`
  margin: ${(props) => props.verticalMargin ?? "28px"} 0;
`;

const SectionTitleDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: ${(props) => props.theme.colors.BlueNavy};
`;

export { SectionTitleDiv, WrapperFormSectionMolecule };
