import styled from "styled-components";

export const WrapperCommingSoon = styled.div`
  width: 115.5px;
  height: 41px;
`;
export const BackgroudCommingSoon = styled.div`
  height: 33px;
  background: #f3bc2a;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
`;
export const IconStyledCS = styled.div`
  margin: -8px 0 0 0;
  height: 8px;
  svg {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`;
