import React, { useCallback, useEffect, useState } from "react";
import { useInsuranceContext, useSessionContext } from "../../../hooks";
import { IQuoteModelPlan } from "../../../models";
import { PaymentLibertyOrganism, PaymentPortoOrganism } from "../../organisms";
import { useTranslation } from "react-i18next";

export const PaymentOrganism: React.FC = () => {
  const { handleEventGTM } = useSessionContext();
  const { getSelectedPlanContext, paymentLinkContext, origin, quotesContext } =
    useInsuranceContext();
  const { t } = useTranslation();

  const [selectedPlanData, setSelectedPlanData] = useState<IQuoteModelPlan>(
    {} as IQuoteModelPlan
  );

  const loadPlanAndGtmEvent = useCallback(
    (planId: string) => {
      const selectedPlanId = quotesContext?.quotes?.find(
        (f) => !!f.plans.find((f) => f.planId === planId)
      );
      if (!selectedPlanId) return;

      const selectedQuotesModule = { ...selectedPlanId };

      const selectedIndex = selectedQuotesModule?.plans.findIndex(
        (plan) => plan.planId === planId
      );
      selectedQuotesModule.plans = selectedQuotesModule?.plans.filter(
        (f) => f.planId === planId
      );

      handleEventGTM({
        event: "add_payment_info",
        ecommerce: {
          value: `${selectedQuotesModule.plans.map(
            (quote) => quote.annualPrice
          )}`,
          currency: "BRL",
          payment_type: "Cartão de Crédito",
          items: {
            item_name:
              selectedQuotesModule.companyName === t("ErrorApi")
                ? t("notAvailable")
                : selectedQuotesModule.companyName,
            item_id: selectedQuotesModule.plans.map((quote) => quote.planId),
            price: selectedQuotesModule.plans.map((quote) => quote.annualPrice),
            currency: "BRL",
            affiliation:
              selectedQuotesModule.companyName === t("ErrorApi")
                ? t("notAvailable")
                : selectedQuotesModule.companyName,
            item_category: "Seguros Auto",
            item_list_name: "Pagamento",
            index: selectedIndex,
            quantity: 1,
          },
        },
      });

      handleEventGTM({
        event: "begin_checkout",
        ecommerce: {
          value: `${selectedQuotesModule.plans.map(
            (quote) => quote.annualPrice
          )}`,
          currency: "BRL",
          items: {
            item_name:
              selectedQuotesModule.companyName === t("ErrorApi")
                ? t("notAvailable")
                : selectedQuotesModule.companyName,
            item_id: selectedQuotesModule.plans.map((quote) => quote.planId),
            price: selectedQuotesModule.plans.map((quote) => quote.annualPrice),
            currency: "BRL",
            affiliation:
              selectedQuotesModule.companyName === t("ErrorApi")
                ? t("notAvailable")
                : selectedQuotesModule.companyName,
            item_category: "Seguros Auto",
            item_list_name: "Pagamento",
            index: selectedIndex,
            quantity: 1,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quotesContext?.quotes]
  );

  useEffect(() => {
    const planData = getSelectedPlanContext();
    const plan = {
      annualPrice: planData?.annualPrice,
      backupCar: planData?.backupCar,
      coverages: planData?.coverages,
      monthsValidity: planData?.monthsValidity,
      paymentFormName: planData?.paymentForm.map(
        (paymentFormName) => paymentFormName.paymentFormName
      ),
      percentage: planData?.percentage,
      plots: planData?.plots,
      price: planData?.price,
      tooltipMessage: planData?.tooltipMessage,
      winch: planData?.winch,
      id: planData?.planId,
    };
    if (planData) setSelectedPlanData(planData);
    if (plan.id) loadPlanAndGtmEvent(plan.id);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelectedPlanContext, loadPlanAndGtmEvent]);

  return !!paymentLinkContext ? (
    <PaymentLibertyOrganism
      selectedPlanData={selectedPlanData}
      paymentLink={paymentLinkContext}
      origin={origin}
    />
  ) : (
    <PaymentPortoOrganism selectedPlanData={selectedPlanData} origin={origin} />
  );
};
