import { PasswordlessPageEnum } from "c4u-web-components";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { IPasswordlessParams } from "../../../models";
import {
  HeaderWithStepsOrganism,
  PasswordlessRegisterOrganism,
} from "../../organisms";

export const PasswordlessRegisterPage: React.FC = () => {
  const { typeJourney } = useParams<IPasswordlessParams>();
  const [page, setPage] = useState<PasswordlessPageEnum>();

  return (
    <>
      <HeaderWithStepsOrganism
        currentStep={1}
        disableReturnButton={page !== PasswordlessPageEnum.CodeValidation}
        returnButtonActionOverride={() => window.location.reload()}
        hideNavigationButton
      />
      <div className="my-5 mx-md-5 mx-sm-3 mx-xs-1">
        <PasswordlessRegisterOrganism
          route={paths.newQuote(typeJourney)}
          setPage={setPage}
        />
      </div>
    </>
  );
};
