import styled from "styled-components";

interface IPropsStatusColor {
  color: string;
}

interface IBodyIconProps {
  isLoading?: boolean;
  disabled?: boolean;
}

const InsuranceCardWrapper = styled.div``;

const InsuranceCardDiv = styled.div<IPropsStatusColor>`
  background: white;
  margin: 50px 0;
  padding: 21px 30px 21px 45px;
  box-shadow: 0px 8px 10px 0px #00000014;
  border-radius: 10px;
  border-left: 11px solid ${(props) => props.color};
`;

const CardBodyIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

const BodyIconDiv = styled.div<IBodyIconProps>`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.MediumGray};
  cursor: ${(props) =>
    props.isLoading
      ? "wait"
      : props.onClick !== undefined
      ? "pointer"
      : "default"};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

export { CardBodyIcons, BodyIconDiv, InsuranceCardDiv, InsuranceCardWrapper };
