import styled from "styled-components";

const AppContainerWithMenu = styled.div`
  padding-left: 82px;
  padding-top: 80px;
  height: 100%;
  width: 100%;
  @media (max-width: 576px) {
    padding-left: 10px;
  }
`;

export { AppContainerWithMenu };
