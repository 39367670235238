import {
  PasswordlessPageEnum,
  PasswordlessRegisterMolecule,
} from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useInsurancePasswordless, useSessionContext } from "../../../hooks";

interface IProps {
  route: string;
  setPage?: (n: PasswordlessPageEnum) => void;
}

export const PasswordlessRegisterOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const {
    handleFormikError,
    showGenericWarningModal,
    showGenericErrorModal,
    setUsernamePawordlessContext,
    usernamePawordlessContext,
  } = useSessionContext();

  const { AuthorizationCreate, AuthorizationValidateCode } =
    useInsurancePasswordless();

  const { push } = useHistory();

  return (
    <PasswordlessRegisterMolecule
      onValidateCode={() => push(props.route)}
      translate={t}
      handleFormikError={handleFormikError}
      showGenericWarningModal={showGenericWarningModal}
      showGenericErrorModal={showGenericErrorModal}
      usernamePawordlessContext={usernamePawordlessContext}
      setUsernamePawordlessContext={setUsernamePawordlessContext}
      AuthorizationCreate={AuthorizationCreate}
      AuthorizationValidateCode={AuthorizationValidateCode}
      setPage={props.setPage}
    />
  );
};
