import React from "react";
import {
  FormikControlAtom as FormikControlAtomComponents,
  FormikControlAtomProps,
} from "c4u-web-components";
import { useTranslation } from "react-i18next";

interface IProps extends Omit<FormikControlAtomProps, "translate"> {}

export const FormikControlTranslatedAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { children, ...FormikProps } = props;

  return (
    <FormikControlAtomComponents translate={t} {...FormikProps}>
      {children}
    </FormikControlAtomComponents>
  );
};
