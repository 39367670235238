import styled from "styled-components";

const MyQuotesWrapper = styled.div`
  padding-bottom: 40px;
`;

const NoQuotesFoundDiv = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.MediumGray};
`;

export { MyQuotesWrapper, NoQuotesFoundDiv };
