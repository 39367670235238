import { DateHelper, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { MsgValidation } from "../../../constants";
import {
  AddressTypeEnum,
  CnhTypeEnum,
  DocumentTypeEnum,
  GenderEnum,
  GenderStringEnum,
  ICustomerViewModel,
  MaritalStatusEnum,
  PoliticallyExposedPersonEnum,
  RelationshipTypeEnum,
} from "../../../models";

export const FinalDataMoleculeValidation = () => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);
  const msgInvalid = t(MsgValidation.Invalid);
  const msgStateTooLong = t("InvalidStateTooLong");
  const msgMaxYearModel = (e: any) => t("MaxYearModel", { max: e.max });
  const msgMinYearModel = (e: any) => t("MinYearModel", { min: e.min });

  const checkPepIsCloseRelative = (value: PoliticallyExposedPersonEnum) => {
    return value === PoliticallyExposedPersonEnum.Relative;
  };

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfValid",
    YupHelper.isCpfValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDatePtBrValid",
    YupHelper.isDatePtBrValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isEmailValid",
    YupHelper.isEmailValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isPhoneValid",
    YupHelper.isPhoneValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "identityBasicValidation",
    YupHelper.identityBasicValidation
  );

  const validations = Yup.object<ICustomerViewModel>({
    id: Yup.number().required(),

    // plugin questions
    overnightAtDifferentZipCode: Yup.string().nullable().required(msgRequired),
    overnightZipCode: Yup.string().when("overnightAtDifferentZipCode", {
      is: (v) => v === "1" || v === true,
      then: Yup.string().nullable().required(msgRequired),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    businessPurposes: Yup.string().nullable().required(msgRequired),
    driverAgeBetween18And25: Yup.string().nullable().required(msgRequired),
    driverGenderBetween18And25: Yup.mixed<string | string[]>().when(
      "driverAgeBetween18And25",
      {
        is: (v) => v === "1" || v === true,
        then: Yup.array()
          .of(Yup.string<GenderStringEnum>())
          .min(1, msgRequired)
          .required(msgRequired),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),

    bonusClass: Yup.object({
      bonusClassCode: Yup.number().min(0).max(10).required(msgRequired),
      startDate: Yup.string().when("bonusClassCode", {
        is: (v) => v !== 0,
        then: Yup.string()
          .required(msgRequired)
          .isDatePtBrValid(t(MsgValidation.Date)),
        otherwise: Yup.string().notRequired(),
      }),
      endDate: Yup.string().when("bonusClassCode", {
        is: (v) => v !== 0,
        then: Yup.string()
          .required(msgRequired)
          .isDatePtBrValid(t(MsgValidation.Date))
          .test("invalid", t("InvalidEndDate"), function (value) {
            const startDate = this.parent.startDate;
            if (!value || !startDate) return true;
            return DateHelper.greaterThanDatePtBr(value, startDate);
          }),
        otherwise: Yup.string().notRequired(),
      }),
      insurerSUSEPCode: Yup.number().when("bonusClassCode", {
        is: (v) => v !== 0,
        then: Yup.number().nullable().required(msgRequired),
        otherwise: Yup.number().nullable().notRequired(),
      }),
      policyNumber: Yup.string().when("bonusClassCode", {
        is: (v) => v !== 0,
        then: Yup.string().nullable().required(msgRequired),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    }).required(),

    // customer data
    name: Yup.string().nullable().required(msgRequired),
    hasSocialName: Yup.string().nullable().required(msgRequired),
    socialName: Yup.string().when("hasSocialName", {
      is: (v) => v === "1" || v === true,
      then: Yup.string().nullable().required(msgRequired),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    email: Yup.string()
      .nullable()
      .required(msgRequired)
      .isEmailValid(t(MsgValidation.Email)),
    cpf: Yup.string().nullable().required(msgRequired).isCpfValid(msgInvalid),
    professionalActivity: Yup.string().nullable().required(msgRequired),
    customerGender: Yup.number<GenderEnum>().nullable().required(msgRequired),
    maritalStatus: Yup.number<MaritalStatusEnum>()
      .nullable()
      .required(msgRequired),
    nationality: Yup.number().nullable().required(msgRequired),
    phoneNumber: Yup.string()
      .nullable()
      .required(msgRequired)
      .isPhoneValid(t(MsgValidation.Phone)),
    // correspondence address
    mailingAddressZipCode: Yup.string().nullable().required(msgRequired),
    mailingAddressType: Yup.number<AddressTypeEnum>()
      .nullable()
      .required(msgRequired),
    mailingAddressStreet: Yup.string().nullable().required(msgRequired),
    mailingAddressNumber: Yup.string().nullable().required(msgRequired),
    mailingAddressComplement: Yup.string().nullable().notRequired(),
    mailingAddressNeighborhood: Yup.string().nullable().required(msgRequired),
    mailingAddressCity: Yup.string().nullable().required(msgRequired),
    mailingAddressState: Yup.string()
      .nullable()
      .required(msgRequired)
      .max(2, msgStateTooLong),

    // income range
    customerIncome: Yup.number().nullable().required(msgRequired),

    // pessoa politicamente exposta
    politicallyExposedPerson: Yup.number<PoliticallyExposedPersonEnum>()
      .nullable()
      .required(msgRequired),
    politicallyExposedRelativeName: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.string().nullable().required(msgRequired),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),
    politicallyExposedRelativeCpf: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.string()
          .nullable()
          .required(msgRequired)
          .isCpfValid(msgInvalid),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),
    politicallyExposedRelativeRelationshipType:
      Yup.number<RelationshipTypeEnum>().when("politicallyExposedPerson", {
        is: checkPepIsCloseRelative,
        then: Yup.number<RelationshipTypeEnum>()
          .nullable()
          .required(msgRequired),
        otherwise: Yup.number<RelationshipTypeEnum>().nullable().notRequired(),
      }),
    politicallyExposedRelativeEmail: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.string()
          .nullable()
          .required(msgRequired)
          .isEmailValid(t(MsgValidation.Email)),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),
    politicallyExposedRelativePhoneNumber: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.string()
          .nullable()
          .required(msgRequired)
          .isPhoneValid(t(MsgValidation.Phone)),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),
    politicallyExposedRelativeAddressZipCode: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.string().nullable().required(msgRequired),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),
    politicallyExposedRelativeAddressType: Yup.number<AddressTypeEnum>().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.number<AddressTypeEnum>().nullable().required(msgRequired),
        otherwise: Yup.number<AddressTypeEnum>().nullable().notRequired(),
      }
    ),
    politicallyExposedRelativeAddressStreet: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.string().nullable().required(msgRequired),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),
    politicallyExposedRelativeAddressNumber: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.string().nullable().required(msgRequired),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),
    politicallyExposedRelativeAddressNeighborhood: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.string().nullable().required(msgRequired),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),
    politicallyExposedRelativeAddressCity: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.string().nullable().required(msgRequired),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),
    politicallyExposedRelativeAddressState: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: checkPepIsCloseRelative,
        then: Yup.string()
          .nullable()
          .required(msgRequired)
          .max(2, msgStateTooLong),
        otherwise: Yup.string().nullable().notRequired(),
      }
    ),

    // document data
    identityType: Yup.number<DocumentTypeEnum>()
      .nullable()
      .required(msgRequired),
    identityNumber: Yup.string()
      .nullable()
      .required(msgRequired)
      .identityBasicValidation(t(MsgValidation.Rg)),
    identityIssuingAgency: Yup.string().nullable().required(msgRequired),
    identityIssuingDate: Yup.string()
      .nullable()
      .required(msgRequired)
      .isDatePtBrValid(t(MsgValidation.Date)),
    birthDate: Yup.string()
      .nullable()
      .required(msgRequired)
      .isDatePtBrValid(t(MsgValidation.Date)),
    cnhNumber: Yup.string().nullable().required(msgRequired),
    cnhCategory: Yup.string<CnhTypeEnum>()
      .oneOf(Object.values(CnhTypeEnum), msgRequired)
      .nullable()
      .required(msgRequired),
    cnhIssuingDate: Yup.string()
      .nullable()
      .required(msgRequired)
      .isDatePtBrValid(t(MsgValidation.Date)),

    // vehicle data
    plate: Yup.string().nullable().required(msgRequired),
    plateUf: Yup.string().nullable().required(msgRequired),
    color: Yup.string().nullable().required(msgRequired),
    chassi: Yup.string()
      .nullable()
      .required(msgRequired)
      .test(
        "len",
        t("ChassiLengthValidation", { length: 17 }),
        (v) => v?.length === 17
      ),
    renavam: Yup.string().nullable().required(msgRequired),
    modelYear: Yup.number()
      .required(msgRequired)
      .min(1900, msgMinYearModel)
      .max(new Date().getFullYear() + 1, msgMaxYearModel),
    manufactureYear: Yup.number().required(msgRequired),

    // invoice data
    invoiceNumber: Yup.string().when("isZeroKm", {
      is: true,
      then: Yup.string().nullable().required(msgRequired),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    invoiceValue: Yup.number().when("isZeroKm", {
      is: true,
      then: Yup.number().nullable().required(msgRequired),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    invoiceEmissionDate: Yup.string().when("isZeroKm", {
      is: true,
      then: Yup.string()
        .nullable()
        .required(msgRequired)
        .isDatePtBrValid(t(MsgValidation.Date)),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    invoiceDealershipCode: Yup.string().when("isZeroKm", {
      is: true,
      then: Yup.string().nullable().required(msgRequired),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    invoiceDealershipVehicleLeaveDate: Yup.string().when("isZeroKm", {
      is: true,
      then: Yup.string()
        .nullable()
        .required(msgRequired)
        .isDatePtBrValid(t(MsgValidation.Date)),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  });

  return validations;
};
