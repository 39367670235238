import axios, { CancelToken } from "axios";
import { Combo, FilterAtom, IDealershipComboData } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { componentUnmountedMsgKey } from "../../../../constants";
import { useSessionContext } from "../../../../hooks";
import {
  IGetQuotesParams,
  IGetQuotesResponse,
  InsuranceCompanyEnum,
  ProposalStepEnum,
} from "../../../../models";
import { FormikControlTranslatedAtom } from "../../../atoms";
import {
  HeaderSearchFilterWrapper,
  StyledForm,
} from "./header-search-filter.molecule.style";

interface IFormData {
  search?: string;
  partners?: string[];
  steps?: string[];
  page: number;
  pageSize: number;
  passwordless: boolean;
}

interface IProps {
  getQuotesHistory: (
    params: IGetQuotesParams,
    cancelToken?: CancelToken | undefined
  ) => Promise<IGetQuotesResponse>;
  setQuotesHistoryData: (data: IGetQuotesResponse | undefined) => void;
  disableFormSubmit?: boolean;
  pageNumber?: number;
  isPasswordless: boolean;
  selectedDealership?: IDealershipComboData;
}

export const HeaderSearchFilterMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { handleFormikException, coxUserContext } = useSessionContext();

  const source = useMemo(
    () => axios.CancelToken.source(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.pageNumber]
  );

  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false);

  const validations = Yup.object<IFormData>({
    search: Yup.string().optional(),
    partners: Yup.array().of(Yup.string()),
    steps: Yup.array().of(Yup.string()),
    page: Yup.number().required(),
    pageSize: Yup.number().required(),
    passwordless: Yup.boolean().required(),
  });

  const formik = useFormik<IFormData>({
    initialValues: {
      search: "",
      partners: [],
      steps: [],
      page: 1,
      pageSize: 10,
      passwordless: props.isPasswordless,
    } as IFormData,
    onSubmit: async (values, { setErrors }) => {
      try {
        const params: IGetQuotesParams = {
          ...values,
          partners: values.partners?.map((m) => parseInt(m)),
          steps: values.steps?.map((m) => parseInt(m)),
        };
        props.setQuotesHistoryData(undefined);
        const data = await props.getQuotesHistory(params, source.token);
        props.setQuotesHistoryData(data);
      } catch (e: any) {
        if (e?.stack?.message === componentUnmountedMsgKey) return;
        handleFormikException(e, setErrors);
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const insuranceCompanyOptions = useMemo(() => {
    return [
      new Combo({ title: t("Porto"), value: InsuranceCompanyEnum.PortoSeguro }),
      new Combo({ title: t("Azul"), value: InsuranceCompanyEnum.Azul }),
      new Combo({ title: t("Liberty"), value: InsuranceCompanyEnum.Liberty }),
      new Combo({ title: t("Ituran"), value: InsuranceCompanyEnum.Ituran }),
    ];
  }, [t]);

  const stepOptions = useMemo(() => {
    return [
      new Combo({ title: t("Quotation"), value: ProposalStepEnum.Quotation }),
      new Combo({ title: t("Waiting"), value: ProposalStepEnum.Waiting }),
      new Combo({ title: t("Approved"), value: ProposalStepEnum.Approved }),
      new Combo({ title: t("Refused"), value: ProposalStepEnum.Refused }),
    ];
  }, [t]);

  useEffect(() => {
    formik.setFieldValue("passwordless", props.isPasswordless);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isPasswordless]);

  useEffect(() => {
    if (coxUserContext && !coxUserContext.isCorporate) {
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coxUserContext]);

  useEffect(() => {
    if (props.pageNumber) {
      formik.setFieldValue("page", props.pageNumber);
      formik.submitForm();
    }

    return () => {
      source.cancel(componentUnmountedMsgKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pageNumber]);

  useEffect(() => {
    if (
      coxUserContext &&
      coxUserContext.isCorporate &&
      props.selectedDealership?.id
    ) {
      formik.setFieldValue("page", 1);
      formik.setFieldValue("partners", []);
      formik.setFieldValue("steps", []);
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedDealership]);

  useEffect(
    () => {
      if (formik.values.page !== 1) formik.setFieldValue("page", 1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.partners, formik.values.steps, formik.values.search]
  );

  return (
    <HeaderSearchFilterWrapper>
      <Row>
        <Col xs={8}>
          <StyledForm onSubmit={(e: any) => formik.handleSubmit(e)}>
            <Form.Row>
              <FormikControlTranslatedAtom
                type={"search-submit"}
                formik={formik}
                md={12}
                property={"search"}
                disabled={props.disableFormSubmit}
              />
            </Form.Row>
          </StyledForm>
        </Col>
        <Col xs={"auto"}>
          <FilterAtom
            filterText={t("Filter")}
            filterIsOpen={filterIsOpen}
            setFilterIsOpen={setFilterIsOpen}
          />
        </Col>
      </Row>

      {filterIsOpen && (
        <Row className="my-3">
          <Col xs="auto">
            <StyledForm onInput={(e: any) => formik.handleSubmit(e)}>
              <Form.Row>
                <FormikControlTranslatedAtom
                  type={"checkbox"}
                  data={insuranceCompanyOptions}
                  formik={formik}
                  md={12}
                  property={"partners"}
                  disabled={props.disableFormSubmit}
                />
              </Form.Row>
            </StyledForm>
          </Col>
          <Col xs="auto">
            <StyledForm onInput={(e: any) => formik.handleSubmit(e)}>
              <Form.Row>
                <FormikControlTranslatedAtom
                  type={"checkbox"}
                  data={stepOptions}
                  formik={formik}
                  md={12}
                  property={"steps"}
                  disabled={props.disableFormSubmit}
                />
              </Form.Row>
            </StyledForm>
          </Col>
        </Row>
      )}
    </HeaderSearchFilterWrapper>
  );
};
