import { NumberHelper, theme } from "c4u-web-components";
import React from "react";
import {
  Franchise,
  SubTitle,
  Title,
  WrapperQuoteItem,
} from "./quote-item.atom.style";

interface IProps {
  title: string;
  requiredLabel: string;
  percentage: number;
  value: number;
  currency: string;
  noFranchise: string;
}

export const QuoteItemFranchiseAtom: React.FC<IProps> = (props) => {
  return (
    <WrapperQuoteItem>
      {props.noFranchise ? (
        <>
          &nbsp;
          <Title color={theme.colors.BlueNavy}>{props.noFranchise}</Title>
        </>
      ) : (
        <>
          <Title>{props.title}</Title>
          <Franchise>
            {props.currency} {NumberHelper.toFormatString(props.value, 2)}
          </Franchise>
          <SubTitle>
            {props.requiredLabel} {props.percentage}%
          </SubTitle>
        </>
      )}
    </WrapperQuoteItem>
  );
};
