import styled from "styled-components";

interface IQuoteDetailsLinkItemProp {
  isLoading: boolean;
}

export const WrapperQuoteItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  min-height: 50px;
  gap: 1px;
`;

export const WrapperQuoteDifferentials = styled(WrapperQuoteItem)`
  justify-content: start;
`;

export const WrapperQuoteDetailsLinkItem = styled.div<IQuoteDetailsLinkItemProp>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  cursor: ${(p) =>
    p.onClick === undefined ? "not-allowed" : p.isLoading ? "wait" : "pointer"};

  .spinner-border {
    width: 15px;
    height: 15px;
    color: #1f3e74;
  }
`;

export const Title = styled.div`
  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 10px;
  line-height: 9px;
  color: ${(p) => p.color || p.theme.colors.MediumGray};
  text-transform: uppercase;
  

  cursor: ${(props) => (props.onClick ? "pointer" : "auto")};
`;

export const SubTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamily.OpenSans};
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.MediumGray};
  text-align: center;
`;

export const Splot = styled.div`
  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: ${(p) => p.theme.colors.BlueNavy};
`;

export const Price = styled(Splot)`
  font-size: 14px;
  line-height: 22px;
`;

export const Franchise = styled.div`
  font-family: ${(p) => p.theme.fontFamily.OpenSans};
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
`;

export const Details = styled.div`
  font-family: ${(p) => p.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;

  text-align: center;

  color: ${(p) => p.color};
`;

export const ViewMoreSpan = styled.span`
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  color: #e95814;
`;

export const ItemsUl = styled.ul`
  height: 64px;
  overflow: hidden;

  list-style: disc;
  text-align: left;

  margin: 0;
  padding-left: 14px;

  &.show-full-diff {
    height: 100%;
  }
`;

export const ItemsLi = styled.li`
  font-family: ${(p) => p.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.MediumGray};
  text-transform: capitalize;
`;

export const WrapperPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  margin-left: 11px;

  path {
    fill: rgb(255, 212, 120);
  }
`