import {
  Combo,
  MaskHelper,
  YupHelper,
  ZipcodeAddress,
} from "c4u-web-components";
import { FormikHelpers, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormPaymentPortoBaseMolecule } from "../form-payment-porto-base/form-payment-porto-base.molecule";
import { FormPaymentPortoOthersMoleculeValidation } from "./form-payment-porto-others.molecule.validation";
import { FormikControlTranslatedAtom } from "../../../../atoms";
import {
  AddressTypeEnum,
  GenderEnum,
  IFormPaymentPortoOthersMolecule,
  MaritalStatusEnum,
} from "../../../../../models";

interface IProps {
  onChangeOwnCC: (own: boolean) => void;
  dataDropdownPlots: Combo[];
  dataRadioCardholder: Combo[];
  getAddress: (zipcode: string) => Promise<ZipcodeAddress>;
  onSubmit: (
    values: IFormPaymentPortoOthersMolecule,
    formikHelpers: FormikHelpers<IFormPaymentPortoOthersMolecule>
  ) => Promise<void>;
}

export const FormPaymentPortoOthersMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = useFormik<IFormPaymentPortoOthersMolecule>({
    initialValues: { cardholder: "0" } as IFormPaymentPortoOthersMolecule,
    onSubmit: props.onSubmit,
    validationSchema: FormPaymentPortoOthersMoleculeValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  const [addressZipcode, setAddressZipcode] = useState<ZipcodeAddress>();

  const getAddressByZipcode = async (cep: string) => {
    const address = await props.getAddress(cep);
    if (address) {
      formik.setValues({
        ...formik.values,
        city: address.localidade,
        state: address.uf,
        street: address.logradouro,
        district: address.bairro,
      });

      setAddressZipcode(address);
    }
  };

  useEffect(() => {
    if (formik.values.cardholder === "1") props.onChangeOwnCC(true);
    // eslint-disable-next-line
  }, [formik.values.cardholder]);

  useEffect(() => {
    if (
      formik.values.zipcode &&
      formik.values.zipcode.replace(/[^\d]/g, "").length === 8
    ) {
      getAddressByZipcode(formik.values.zipcode);
    }
  }, [formik.values.zipcode]); //eslint-disable-line react-hooks/exhaustive-deps

  const maritalStatusOptions = useMemo(() => {
    return [
      new Combo({ title: t("Single"), value: MaritalStatusEnum.Single }),
      new Combo({
        title: t("CivilUnion"),
        value: MaritalStatusEnum.CivilUnion,
      }),
      new Combo({ title: t("Married"), value: MaritalStatusEnum.Married }),
      new Combo({ title: t("Divorced"), value: MaritalStatusEnum.Divorced }),
      new Combo({ title: t("Widowed"), value: MaritalStatusEnum.Widowed }),
    ];
  }, [t]);

  const genderStringOptions = useMemo(() => {
    return [
      new Combo({ title: t("Male"), value: GenderEnum.Male }),
      new Combo({ title: t("Female"), value: GenderEnum.Female }),
    ];
  }, [t]);

  const addressTypeOptions = useMemo(() => {
    return [
      new Combo({ title: t("Grove"), value: AddressTypeEnum.Grove }),
      new Combo({ title: t("Avenue"), value: AddressTypeEnum.Avenue }),
      new Combo({ title: t("Square"), value: AddressTypeEnum.Square }),
      new Combo({ title: t("Street"), value: AddressTypeEnum.Street }),
    ];
  }, [t]);

  return (
    <>
      <Form
        onSubmit={(e: any) => formik.handleSubmit(e)}
        className={"form-default"}
      >
        <Form.Row>
          <FormikControlTranslatedAtom
            type={"radio"}
            formik={formik}
            property={"cardholder"}
            label={t("Are you the cardholder?")}
            data={props.dataRadioCardholder}
          />
        </Form.Row>

        <FormPaymentPortoBaseMolecule
          formik={formik}
          dataDropdownPlots={props.dataDropdownPlots}
        >
          <Form.Row>
            <FormikControlTranslatedAtom
              lg={4}
              formik={formik}
              property={"creditcardNumber"}
              label={t("Creditcard number")}
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
            />
            <FormikControlTranslatedAtom
              lg={4}
              formik={formik}
              property={"creditcardName"}
              label={t("Creditcard name")}
            />
          </Form.Row>
          <Form.Row>
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"creditcardMonth"}
              label={t("Month")}
              mask={[/\d/, /\d/]}
            />
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"creditcardYear"}
              label={t("Year")}
              mask={[/\d/, /\d/, /\d/, /\d/]}
            />
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"creditcardCvv"}
              label={t("Cvv")}
              mask={[/\d/, /\d/, /\d/, /\d?/]}
              placeholderChar={"\u2000"}
            />
          </Form.Row>

          <Form.Row>
            <FormikControlTranslatedAtom
              lg={4}
              formik={formik}
              property={"document"}
              label={t("CpfOrCnpj")}
              mask={MaskHelper.CpfCnpj}
            />
            {formik.values.document?.length === 14 &&
              YupHelper.cpfValid(formik.values.document) && (
                <>
                  <FormikControlTranslatedAtom
                    lg={2}
                    formik={formik}
                    property={"birthdate"}
                    label={t("Birthdate")}
                    mask={MaskHelper.Date}
                  />
                  <FormikControlTranslatedAtom
                    type={"select"}
                    data={maritalStatusOptions}
                    lg={2}
                    formik={formik}
                    property={"maritalStatus"}
                    label={t("MaritalStatus")}
                  />
                  <FormikControlTranslatedAtom
                    type={"select"}
                    data={genderStringOptions}
                    formik={formik}
                    lg={2}
                    property={"gender"}
                    label={t("Gender")}
                  />
                </>
              )}
          </Form.Row>

          <hr />

          <Row>
            <Col className={"mb-2"}>
              <span className={"form-label"}>{t("Creditcard address")}</span>
            </Col>
          </Row>

          <Form.Row>
            <FormikControlTranslatedAtom
              lg={4}
              formik={formik}
              property={"zipcode"}
              label={t("ZipCode")}
              mask={MaskHelper.Cep}
            />
          </Form.Row>
          <Form.Row>
            <FormikControlTranslatedAtom
              type={"select"}
              data={addressTypeOptions}
              formik={formik}
              md={2}
              property={"addressType"}
              label={t("AddressType")}
            />
            <FormikControlTranslatedAtom
              lg={4}
              formik={formik}
              property={"street"}
              label={t("StreetName")}
              disabled={!!addressZipcode?.logradouro}
            />
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"number"}
              label={t("Number")}
            />
            <FormikControlTranslatedAtom
              lg={3}
              formik={formik}
              property={"complement"}
              label={t("Complement")}
            />
          </Form.Row>
          <Form.Row>
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"district"}
              label={t("District")}
              disabled={!!addressZipcode?.bairro}
            />
            <FormikControlTranslatedAtom
              lg={4}
              formik={formik}
              property={"city"}
              label={t("City")}
              disabled={!!addressZipcode?.localidade}
            />
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"state"}
              label={t("State")}
              disabled={!!addressZipcode?.uf}
              mask={[/[a-zA-Z]/, /[a-zA-Z]/]}
            />
          </Form.Row>

          <hr />

          <Row>
            <Col className={"mb-2"}>
              <span className={"form-label"}>{t("Confirm your data")}</span>
            </Col>
          </Row>

          <Form.Row>
            <FormikControlTranslatedAtom
              lg={4}
              formik={formik}
              property={"email"}
              label={t("Email")}
            />
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"phone"}
              label={t("Phone")}
              mask={MaskHelper.Phone}
            />
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"ramal"}
              label={t("Ramal")}
              mask={[/\d/, /\d?/, /\d?/, /\d?/, /\d?/]}
              placeholderChar={"\u2000"}
            />
          </Form.Row>

          <Form.Row>
            <FormikControlTranslatedAtom
              as={"textarea"}
              lg={8}
              formik={formik}
              property={"observation"}
              label={t("Observation")}
            />
          </Form.Row>
        </FormPaymentPortoBaseMolecule>
      </Form>
    </>
  );
};
