export enum CreditCardFlagEnum {
  Visa = 1,
  Mastercard = 2,
}

export enum PaymentMethodEnum {
  CreditCard,
  BankSlip,
}

export enum PaymentFormCodeEnum {
  CreditCard = 62,
  CreditCardPortoSeguro = 97,
}
