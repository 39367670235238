import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  WrapperQuote,
  CompanyLogo,
  RowQuotes,
  ColQuotes,
  RowButtons,
  ColButtons,
  NoCotation,
  Soon,
  ScrollWrapper,
} from "./quote.molecule.style";
import { PortoLogo, AzulLogo, LibertyLogo, LogoIturan } from "./../../assets";
import { Img, SpinnerCenteredAtom } from "c4u-web-components";
import {
  InsuranceCompanyEnum,
  IQuoteModelPlan,
  PrintTypeEnum,
} from "../../../models";
import {
  CommingSoonAtom,
  QuoteItemCoverageAtom,
  QuoteItemDetailsAtom,
  QuoteItemFranchiseAtom,
  QuoteItemKeysAtom,
  QuoteItemPriceAtom,
} from "../../atoms";

interface IProps {
  company: InsuranceCompanyEnum;
  active: boolean;
  plans?: IQuoteModelPlan[];
  onChoose?: (planId: string, printType: PrintTypeEnum, price: number) => void;
  choosenPlan?: string;
  loading: boolean;
  finishedLoadingInsurance: boolean;
  showTrackerMessage: boolean;
  onlyTracker: boolean;
  onViewDetails: (
    planId: string,
    printType: PrintTypeEnum,
    setLoadingDetails: (v: boolean) => void
  ) => void;
}

export const QuoteMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const getLogo = (company: InsuranceCompanyEnum) => {
    switch (company) {
      case InsuranceCompanyEnum.PortoSeguro:
        return PortoLogo;
      case InsuranceCompanyEnum.Azul:
        return AzulLogo;
      case InsuranceCompanyEnum.Liberty:
        return LibertyLogo;
      case InsuranceCompanyEnum.Ituran:
        return LogoIturan;
    }
  };

  const handleChoose = useCallback(
    (planId: string, printType: PrintTypeEnum, price: number) => {
      if (props.onChoose) props.onChoose(planId, printType, price);
    },
    [props]
  );

  const plans = props.plans?.slice(0, 2);

  const quotePlanA = plans?.slice(0, 1)[0];
  const quotePlanB = plans?.slice(1, 2)[0];

  const [showFullDiffPlanA, setShowFullDiffPlanA] = useState(false);
  const [showFullDiffPlanB, setShowFullDiffPlanB] = useState(false);

  const getQuotesDetails = useCallback(
    (plans: IQuoteModelPlan[]) => (
      <>
        <RowQuotes className={"RowQuotes"}>
          {plans.map((m, i) => (
            <ColQuotes key={`ColQuotes${i}`} className={"ColQuotes"}>
              <QuoteItemPriceAtom
                splot={m.plots}
                title={t("Price")}
                tooltipMessage={m.tooltipMessage}
                value={m.annualPrice}
                currency={t("$")}
                monthlyPayment={
                  props.company === InsuranceCompanyEnum.Ituran
                    ? t("MonthlyPayment")
                    : ""
                }
              />
            </ColQuotes>
          ))}
        </RowQuotes>

        <RowQuotes className={"RowQuotes"}>
          {plans.map((m, i) => (
            <ColQuotes key={`ColQuotes2${i}`} className={"ColQuotes"}>
              <QuoteItemFranchiseAtom
                currency={t("$")}
                percentage={m.percentage}
                requiredLabel={t("Required")}
                title={t("Franchise")}
                value={m.franchise}
                noFranchise={
                  !m.franchise && props.company === InsuranceCompanyEnum.Ituran
                    ? t("NoFranchise")
                    : ""
                }
              />
            </ColQuotes>
          ))}
        </RowQuotes>

        <RowQuotes className={"RowQuotes"}>
          {plans.map((m, i) => (
            <ColQuotes key={`ColQuotes3${i}`} className={"ColQuotes"}>
              <QuoteItemCoverageAtom
                title={m.backupCar.title}
                has={m.backupCar.status}
                description={m.backupCar.information}
              />
            </ColQuotes>
          ))}
        </RowQuotes>
        <RowQuotes className={"RowQuotes"}>
          {plans.map((m, i) => (
            <ColQuotes key={`ColQuotes4${i}`} className={"ColQuotes"}>
              <QuoteItemCoverageAtom
                title={t("Winch")}
                has={m.winch.status}
                description={m.winch.information}
              />
            </ColQuotes>
          ))}
        </RowQuotes>

        <RowQuotes className={"RowQuotes Differentials"}>
          {quotePlanA && (
            <ColQuotes className={"ColQuotes"}>
              <QuoteItemKeysAtom
                title={t("Differentials")}
                items={quotePlanA.coverages.map((c) => c.title)}
                onToggleViewMore={() => setShowFullDiffPlanA(true)}
              />
            </ColQuotes>
          )}
          {quotePlanB && (
            <ColQuotes className={"ColQuotes"}>
              <QuoteItemKeysAtom
                title={t("Differentials")}
                items={quotePlanB.coverages.map((c) => c.title)}
                onToggleViewMore={() => setShowFullDiffPlanB(true)}
              />
            </ColQuotes>
          )}
        </RowQuotes>

        {(showFullDiffPlanA || showFullDiffPlanB) && (
          <RowQuotes
            className={
              "RowQuotes Differentials overlay-full-diff click-through"
            }
          >
            <ColQuotes
              className={`ColQuotes ${
                showFullDiffPlanA ? "white-background clickable" : ""
              }`}
            >
              {quotePlanA && showFullDiffPlanA && (
                <ScrollWrapper>
                  <QuoteItemKeysAtom
                    title={t("Differentials")}
                    items={quotePlanA.coverages.map((c) => c.title)}
                    onToggleViewMore={() => setShowFullDiffPlanA(false)}
                    showFullDiff
                  />
                </ScrollWrapper>
              )}
            </ColQuotes>
            {plans.length > 1 && (
              <ColQuotes
                className={`ColQuotes ${
                  showFullDiffPlanB ? "white-background clickable " : ""
                }`}
              >
                {quotePlanB && showFullDiffPlanB && (
                  <ScrollWrapper>
                    <QuoteItemKeysAtom
                      title={t("Differentials")}
                      items={quotePlanB.coverages.map((c) => c.title)}
                      onToggleViewMore={() => setShowFullDiffPlanB(false)}
                      showFullDiff
                    />
                  </ScrollWrapper>
                )}
              </ColQuotes>
            )}
          </RowQuotes>
        )}

        <RowQuotes className="half-height">
          {plans.map((m, i) => (
            <ColQuotes key={`ColQuotes6${i}`} className={"ColQuotes"}>
              <QuoteItemDetailsAtom
                title={t("ViewDetails")}
                disabled={m.printType === PrintTypeEnum.None}
                onclick={(setLoadingDetails) =>
                  props.onViewDetails(m.planId, m.printType, setLoadingDetails)
                }
              />
            </ColQuotes>
          ))}
        </RowQuotes>

        <RowButtons>
          {plans.map((m, i) => (
            <ColButtons
              key={`ColButtons${i}`}
              active={m.planId === props.choosenPlan}
              onClick={() => handleChoose(m.planId, m.printType, m.price)}
              className="ColButtons"
            >
              {m.planId === props.choosenPlan
                ? t("Selected")
                : t("Choose this")}
            </ColButtons>
          ))}
        </RowButtons>
      </>
    ),
    [
      handleChoose,
      props,
      quotePlanA,
      quotePlanB,
      showFullDiffPlanA,
      showFullDiffPlanB,
      t,
    ]
  );

  const getQuoteMolecule = useCallback(() => {
    if (!props.active) {
      return (
        <Soon>
          <CommingSoonAtom title={t("Comming soon")} />
        </Soon>
      );
    }
    if (!plans) return undefined;
    if (props.company === InsuranceCompanyEnum.Ituran && props.onlyTracker) {
      if (!props.finishedLoadingInsurance) {
        return (
          <NoCotation>
            <SpinnerCenteredAtom />
          </NoCotation>
        );
      }
      if (props.showTrackerMessage === false) {
        return (
          <NoCotation>
            {t("No cotation from this insurance company")}
          </NoCotation>
        );
      }
    }
    if (plans.length === 0) {
      if (props.loading) {
        return (
          <NoCotation>
            <SpinnerCenteredAtom />
          </NoCotation>
        );
      }
      return (
        <NoCotation>{t("No cotation from this insurance company")}</NoCotation>
      );
    }
    if (plans.length > 0) {
      return getQuotesDetails(plans);
    }
    return undefined;
  }, [
    getQuotesDetails,
    props.active,
    props.company,
    props.finishedLoadingInsurance,
    props.loading,
    props.onlyTracker,
    props.showTrackerMessage,
    plans,
    t,
  ]);

  return (
    <WrapperQuote active={props.active}>
      <CompanyLogo>
        <Img src={getLogo(props.company)} />
      </CompanyLogo>

      {getQuoteMolecule()}
    </WrapperQuote>
  );
};
