import React from "react";
import { useTranslation } from "react-i18next";
import {
  Title,
  WrapperQuoteDifferentials,
  ViewMoreSpan,
  ItemsUl,
  ItemsLi,
} from "./quote-item.atom.style";

interface IProps {
  title: string;
  items: string[];
  showFullDiff?: boolean;
  onToggleViewMore?: () => void;
}

export const QuoteItemKeysAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <WrapperQuoteDifferentials>
      <Title
        className="mb-2"
        onClick={props.onToggleViewMore}
        title={props.showFullDiff ? t("ViewLess") : t("ViewMore")}
      >
        {props.title}{" "}
        <ViewMoreSpan>
          {props.showFullDiff ? <span>&ndash;</span> : "+"}
        </ViewMoreSpan>
      </Title>
      <ItemsUl className={props.showFullDiff ? "show-full-diff" : ""}>
        {props.items.map((m, i) => (
          <ItemsLi key={`diff-item-${i}`}>{m}</ItemsLi>
        ))}
      </ItemsUl>
    </WrapperQuoteDifferentials>
  );
};
