import {
  ButtonPrimary,
  ButtonSecondary,
  CoxIcon,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Content,
  ContentModalMsg,
  Icon,
  ModalBody,
  ModalHeader,
  Title,
  WrapperButtonsConfirm,
} from "./feedback-modal.molecule.style";

interface IProps {
  type:
    | "success"
    | "error"
    | "warning"
    | "confirm"
    | "confirm-yesno"
    | "loading";
  show: boolean;
  onHide: () => void;
  onClickButton?: () => void;
  title: string;
  titleHeader?: string;
  textButton?: string;
  content?: string | React.ReactNode;
  personalButton?: React.ReactNode;
  size?: "sm" | "md" | "lg" | "xl";
}

export const FeedbackModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.onHide}
        size={props.size === "md" ? undefined : props.size ?? "lg"}
      >
        <ModalHeader closeButton>
          {props.titleHeader ??
            t(props.type.slice(0, 1).toUpperCase() + props.type.slice(1))}
        </ModalHeader>
        <ModalBody>
          <Icon>
            {props.type === "success" && (
              <CoxIcon name="checked" width={32} height={32} />
            )}
            {props.type === "error" && (
              <CoxIcon name="danger-color" width={32} height={32} />
            )}
            {(props.type === "warning" || props.type === "confirm") && (
              <CoxIcon name="warning-color" width={32} height={32} />
            )}
            {props.type === "loading" && <SpinnerCenteredAtom />}
          </Icon>
          <Title>{props.title}</Title>
          <Content>
            {props.children ? (
              props.children
            ) : (
              <ContentModalMsg>{props.content}</ContentModalMsg>
            )}
          </Content>
          {props.type === "success" && !props.personalButton && (
            <ButtonPrimary
              onClick={props.onClickButton ? props.onClickButton : props.onHide}
              sizex={"md"}
            >
              {props.textButton ? props.textButton : t("OK")}
            </ButtonPrimary>
          )}
          {props.type === "error" && !props.personalButton && (
            <ButtonSecondary
              onClick={props.onClickButton ? props.onClickButton : props.onHide}
              sizex={"md"}
            >
              {props.textButton ? props.textButton : t("OK")}
            </ButtonSecondary>
          )}
          {props.type === "warning" && !props.personalButton && (
            <ButtonSecondary
              onClick={props.onClickButton ? props.onClickButton : props.onHide}
              sizex={"md"}
            >
              {props.textButton ? props.textButton : t("OK")}
            </ButtonSecondary>
          )}
          {props.type === "confirm" && !props.personalButton && (
            <WrapperButtonsConfirm>
              <ButtonSecondary
                sizey={"thin"}
                onClick={props.onHide}
                sizex={"md"}
              >
                {t("Cancel")}
              </ButtonSecondary>
              <ButtonPrimary
                sizey={"thin"}
                onClick={
                  props.onClickButton ? props.onClickButton : props.onHide
                }
                sizex={"md"}
              >
                {props.textButton ? props.textButton : t("OK")}
              </ButtonPrimary>
            </WrapperButtonsConfirm>
          )}
          {props.type === "confirm-yesno" && !props.personalButton && (
            <WrapperButtonsConfirm>
              <ButtonSecondary
                sizey={"thin"}
                onClick={
                  props.onClickButton ? props.onClickButton : props.onHide
                }
                sizex={"md"}
              >
                {props.textButton ? props.textButton : t("Yes")}
              </ButtonSecondary>
              <ButtonSecondary
                sizey={"thin"}
                onClick={props.onHide}
                sizex={"md"}
              >
                {t("No")}
              </ButtonSecondary>
            </WrapperButtonsConfirm>
          )}
          {props.personalButton}
        </ModalBody>
      </Modal>
    </>
  );
};
