import { CoxIcon, NumberHelper, Tooltip } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Price,
  Splot,
  Title,
  WrapperPrice,
  WrapperQuoteItem,
} from "./quote-item.atom.style";

interface IProps {
  title: string;
  splot: number;
  value: number;
  currency: string;
  monthlyPayment: string;
  tooltipMessage: string;
}

export const QuoteItemPriceAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  return (
    <WrapperQuoteItem>
      <WrapperPrice>
        <Title>{props.title}</Title>
        <Tooltip
          content={props.tooltipMessage}
          width="110px"
          placement="bottom"
        >
          <CoxIcon name={"info"} width={14} height={14} />
        </Tooltip>
      </WrapperPrice>
      <Splot>{t("MonthlyPayment")}</Splot>
      <Price>
        {props.currency} {NumberHelper.toFormatString(props.value, 2)}
      </Price>
    </WrapperQuoteItem>
  );
};
