import { useEffect } from "react";

export const useEvent = (
  event: string,
  handler: (event: any) => void,
  passive = false
) => {
  useEffect(() => {
    // initiate the event handler
    window.addEventListener(event, handler, passive);

    // clean up the event when the component is re-rendered
    return () => {
      window.removeEventListener(event, handler);
    };
  });
};
