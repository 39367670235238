import styled from "styled-components";

interface IProps {
  active?: boolean;
}
export const CreditCardButton = styled.div<IProps>`
  background: ${(p) =>
    !p.active ? p.theme.colors.MediumGray : p.theme.colors.BlueHigh};
  height: 55px;
  min-width: 176px;
  border-radius: 4px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  line-height: 55px;
  color: ${(p) => p.theme.colors.white};
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0 5px;
  cursor: pointer;
`;

export const WrapperPorto = styled.div`
  .header-payment-porto {
    padding-bottom: 40px;
  }
  @media (min-width: 767px) {
    .form-default .form-row > [class*="col-"] {
      padding-right: 20px;
    }
  }
`;
