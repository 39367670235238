import {
  ButtonSecondary,
  OptionsSelectionVehicleMolecule,
  PasswordlessSendByEnum,
} from "c4u-web-components";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Definition, paths } from "../../../constants";
import {
  useInsurance,
  useInsuranceContext,
  useSessionContext,
} from "../../../hooks";
import { IPasswordlessParams } from "../../../models";
import {
  VehicleInformationMolecule,
  VehicleOptionsMolecule,
} from "../../molecules";
import { DivButtonAdvance } from "./vehicle-choose.organism.style";

export const VehicleChooseOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { typeJourney } = useParams<IPasswordlessParams>();

  const [submiting, setSubmiting] = useState(false);

  const { showGenericErrorModal, usernamePawordlessContext } =
    useSessionContext();
  const {
    vehicleContext,
    vehicleCategoryContext,
    setVehicleCategoryContext,
    sourcePartnerContext,
    setSourcePartnerContext,
  } = useInsuranceContext();
  const informationCarMolicarCondition = vehicleContext?.id === 0;

  const { saveVehicle } = useInsurance();
  const { push } = useHistory();

  const vehicleValid = useMemo((): boolean => {
    return !!vehicleContext;
  }, [vehicleContext]);

  const buttonValid = useMemo((): boolean => {
    if (!vehicleContext) return false;
    return true;
  }, [vehicleContext]);

  const handleSave = async () => {
    setSubmiting(true);
  };

  const save = async () => {
    try {
      const response = await saveVehicle(
        vehicleContext.externalReference,
        vehicleContext.year,
        vehicleCategoryContext,
        typeJourney !== Definition.Passwordless
          ? null
          : usernamePawordlessContext.sendBy === PasswordlessSendByEnum.Sms
          ? usernamePawordlessContext.cellphone
          : usernamePawordlessContext.email
      );
      push(
        paths.finalData(
          response.publicPersonId,
          response.encodeLenderCode,
          typeJourney
        )
      );
    } catch (e) {
      console.log(e);
      showGenericErrorModal();
    } finally {
      setSubmiting(false);
    }
  };

  useEffect(() => {
    if (submiting) {
      save();
    }
  }, [submiting]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Row>
        <Col lg={6}>
          <OptionsSelectionVehicleMolecule
            vehicleCategory={vehicleCategoryContext}
            setVehicleCategory={setVehicleCategoryContext}
            translate={t}
          />
          <VehicleOptionsMolecule
            vehicleCategory={vehicleCategoryContext}
            setVehicleCategory={setVehicleCategoryContext}
            vehicleKbb={
              vehicleContext
                ? {
                    idBrand: vehicleContext.brandID,
                    id: vehicleContext.id,
                    idModel: vehicleContext.modelID,
                    year: vehicleContext.year,
                    molicarID: vehicleContext.molicarID,
                  }
                : undefined
            }
            sourcePartner={sourcePartnerContext}
            setSourcePartner={setSourcePartnerContext}
          />
        </Col>
        <Col lg={6}>
          <VehicleInformationMolecule
            reduced={false}
            vehicleCategory={vehicleCategoryContext}
            informationCarMolicar={informationCarMolicarCondition}
          />
        </Col>
      </Row>
      {vehicleValid && (
        <>
          <Row>
            <Col md={{ span: 3, offset: 9 }}>
              <DivButtonAdvance>
                <ButtonSecondary
                  onClick={handleSave}
                  disabled={!buttonValid}
                  loading={submiting}
                  data-cyid="button-continue"
                >
                  {t("Continue")}
                </ButtonSecondary>
              </DivButtonAdvance>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
