import { useRequest, kbbHttp } from "../config";
import {
  LocationState,
  VehicleModel,
  VehicleVersion,
  Years,
  VehicleBrand,
  Vehicle,
  VehicleBrandModels,
  BaseResponseKbb,
  PriceAdvisorRequest,
  VehiclePrices,
} from "../../../models";
import {
  HttpHelper,
  VehicleCategoriesEnum,
  VehicleEquipment,
  SourcePartnerEnum,
} from "c4u-web-components";
import { useTranslation } from "react-i18next";

export const useKbb = () => {
  const { get, post } = useRequest(kbbHttp, "Kbb");

  const { t } = useTranslation();

  const GetAllLocationState = async (): Promise<LocationState[]> => {
    const { data } = await get("GetAllLocationState");
    return data;
  };

  const GetAllVehicleBrand = async (
    vehicleCategory?: VehicleCategoriesEnum,
    sourcePartner?: SourcePartnerEnum
  ): Promise<VehicleBrand[]> => {
    const { data } = await get(
      `GetAllVehicleBrand${HttpHelper.BuildQueryString({
        vehicleCategory,
        sourcePartner,
      })}`
    );
    return data;
  };

  const GetAllVehicleModelByBrand = async (
    brandId: number,
    year: number | null = null,
    vehicleCategory?: VehicleCategoriesEnum,
    sourcePartner?: SourcePartnerEnum
  ): Promise<VehicleModel[]> => {
    const { data } = await get(
      `GetAllVehicleModelByBrand/${brandId}` +
        `${HttpHelper.BuildQueryString({ vehicleCategory, sourcePartner })}` +
        (year ? `&year=${year}` : "")
    );
    return data;
  };

  const GetAllVehicleModels = async (
    vehicleCategory?: VehicleCategoriesEnum,
    sourcePartner?: SourcePartnerEnum
  ): Promise<VehicleBrandModels[]> => {
    const { data } = await get(
      `GetAllVehicleModels${HttpHelper.BuildQueryString({
        vehicleCategory,
        sourcePartner,
      })}`
    );
    return data;
  };

  const GetAllVehicleVersion = async (
    modelId: number,
    year: number,
    sourcePartner: SourcePartnerEnum
  ): Promise<VehicleVersion[]> => {
    const { data } = await get(
      `GetAllVehicleVersion/${year}/${modelId}${HttpHelper.BuildQueryString({
        sourcePartner,
      })}`
    );
    return data;
  };

  const GetAllYears = async (
    sourcePartner: SourcePartnerEnum
  ): Promise<Years[]> => {
    const { data } = await get(
      `GetAllYears${HttpHelper.BuildQueryString({ sourcePartner })}`
    );
    return data;
  };

  const GetVehicle = async (
    id: number,
    molicarID: string | null,
    year: number
  ): Promise<Vehicle> => {
    const { data } = await get(
      `GetVehicle/${id}${HttpHelper.BuildQueryString({
        molicarID,
        year,
      })}`
    );
    if (data && !data?.name) {
      data.name =
        `${data?.brandName} ${data?.modelName} ${data?.versionName} ` +
        `${data?.hp} ${t("Hp")} - ${data?.year}`;
    }
    return data;
  };

  const GetAllVehicleGrade = async (): Promise<BaseResponseKbb[]> => {
    try {
      const { data } = await get(`GetAllVehicleGrade`);
      return data;
    } catch (error) {
      return [];
    }
  };

  const GetPriceAdvisor = async (
    request: PriceAdvisorRequest
  ): Promise<string> => {
    try {
      const { data } = await post(`GetPriceAdvisor`, request);
      return data.content;
    } catch (error) {
      return "";
    }
  };

  const GetAllEquipments = async (
    id: number | null = null,
    year: number | null = null
  ): Promise<VehicleEquipment[]> => {
    const { data } = await get(`GetAllEquipments${id ? `/${id}/${year}` : ""}`);
    return data;
  };

  const GetVehiclePrices = async (
    request: PriceAdvisorRequest
  ): Promise<VehiclePrices> => {
    try {
      const { data } = await post(`GetVehiclePrices`, request);
      return data;
    } catch (error) {
      return {} as VehiclePrices;
    }
  };

  return {
    GetAllLocationState,
    GetAllVehicleModelByBrand,
    GetAllVehicleVersion,
    GetAllYears,
    GetAllVehicleBrand,
    GetVehicle,
    GetAllVehicleModels,
    GetAllVehicleGrade,
    GetPriceAdvisor,
    GetAllEquipments,
    GetVehiclePrices,
  };
};
