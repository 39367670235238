import {
  ICoxUser,
  ICreatePasswordlessRequest,
  IDealershipComboData,
} from "c4u-web-components";
import React, { createContext, useState } from "react";

import { SessionProviderState } from "../hooks/contexts/use-session-context.hook";
import { ConflictRequestError } from "../models";

const SessionContext = createContext({} as any);

declare const window: Window &
  typeof globalThis & {
    registration: any[];
  };

const SessionProvider = (props: any) => {
  const [genericErrorModal, setShowGenericErrorModal] = useState(false);
  const [genericWarningModal, setShowGenericWarningModal] = useState(false);
  const [genericSuccessModal, setShowGenericSuccessModal] = useState(false);
  const [genericModalMsg, setGenericModalMsg] = useState<string | null>(null);
  const [titleErrorModal, setTitleErrorModal] = useState<string | null>(null);
  const [titleWarningModal, setTitleWarningModal] = useState<string | null>(
    null
  );
  const [titleSuccessModal, setTitleSuccessModal] = useState<string | null>(
    null
  );
  const [genericEventClose, setGenericEventClose] = useState<
    (() => void) | null
  >(null);
  const [usernamePawordlessContext, setUsernamePawordlessContext] =
    useState<ICreatePasswordlessRequest>({} as ICreatePasswordlessRequest);
  const [coxUserContext, setCoxUserContext] = useState<ICoxUser>();
  const [selectedDealershipContext, setSelectedDealershipContext] =
    useState<IDealershipComboData>();

  const showGenericErrorModal = (
    text: string | null = null,
    onClose: (() => void) | null = null,
    title: string | null = null
  ) => {
    setGenericModalMsg(text);
    setTitleErrorModal(title);
    setShowGenericErrorModal(true);
    if (onClose) setGenericEventClose(() => onClose);
  };
  const hideGenericErrorModal = () => {
    setGenericModalMsg(null);
    setShowGenericErrorModal(false);

    if (genericEventClose) {
      genericEventClose();
      setGenericEventClose(null);
    }
  };
  const showGenericWarningModal = (
    text: string | null = null,
    onClose: (() => void) | null = null,
    title: string | null = null
  ) => {
    setGenericModalMsg(text);
    setTitleWarningModal(title);
    setShowGenericWarningModal(true);
    if (onClose) setGenericEventClose(() => onClose);
  };
  const hideGenericWarningModal = () => {
    setGenericModalMsg(null);
    setShowGenericWarningModal(false);
    if (genericEventClose) {
      genericEventClose();
      setGenericEventClose(null);
    }
  };

  const showGenericSuccessModal = (
    text: string | null = null,
    onClose: (() => void) | null = null,
    title: string | null = null
  ) => {
    setGenericModalMsg(text);
    setTitleSuccessModal(title);
    setShowGenericSuccessModal(true);
    if (onClose) setGenericEventClose(() => onClose);
  };
  const hideGenericSuccessModal = () => {
    setGenericModalMsg(null);
    setShowGenericSuccessModal(false);
    if (genericEventClose) {
      genericEventClose();
      setGenericEventClose(null);
    }
  };

  const handleFormikError = (
    exception: any,
    setErrors?: any,
    msg?: string,
    title?: string
  ) => {
    if (exception?.errors) {
      showGenericWarningModal();
      setErrors(exception.errors);
    } else if (exception instanceof ConflictRequestError) {
      return exception?.message;
    } else {
      if (msg) setGenericModalMsg(msg);

      if (title) setTitleErrorModal(title);

      setShowGenericErrorModal(true);
    }
  };

  const handleFormikException = (
    exception: any,
    setErrors?: any,
    msg?: string,
    title?: string
  ) => {
    const message = handleFormikError(exception, setErrors, msg, title);

    if (message) showGenericWarningModal(message);
  };

  const handleEventGTM = (eventData: object) => {
    try {
      console.log(eventData);
      window.registration.push(eventData);
    } catch (e) {
      if (process.env.REACT_APP_GTMID)
        console.log("Error when trying to send data to GTM", eventData);
    }
  };

  return (
    <SessionContext.Provider
      value={
        {
          genericModalMsg,
          genericErrorModal,
          showGenericErrorModal,
          hideGenericErrorModal,
          genericWarningModal,
          showGenericWarningModal,
          hideGenericWarningModal,
          genericSuccessModal,
          showGenericSuccessModal,
          hideGenericSuccessModal,
          handleFormikError,
          handleFormikException,
          titleErrorModal,
          titleWarningModal,
          titleSuccessModal,
          usernamePawordlessContext,
          setUsernamePawordlessContext,
          coxUserContext,
          setCoxUserContext,
          selectedDealershipContext,
          setSelectedDealershipContext,
          handleEventGTM,
        } as SessionProviderState
      }
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
