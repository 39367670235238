import React, { Suspense } from "react";
import "./App.css";

import { ThemeProvider } from "styled-components";
import GlobalStyles from "./ui/styles";
import { theme } from "c4u-web-components";
import TagManager from "react-gtm-module";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Routes } from "./router";

import { Auth0Provider } from "@auth0/auth0-react";
import { InsuranceProvider, SessionProvider } from "./contexts";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div>Loading...</div>}>
        <SessionProvider>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
            clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ""}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ""}
            scope={`${process.env.REACT_APP_AUTH0_SCOPE}`}
            redirectUri={`${window.location.href}`}
          >
            <InsuranceProvider>
              <Routes />
            </InsuranceProvider>
          </Auth0Provider>
        </SessionProvider>
      </Suspense>
      <GlobalStyles />
    </ThemeProvider>
  );
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTMID ?? "dummy",
  dataLayerName: "registration",
  dataLayer: {
    page: "init",
  },
};
if (process.env.REACT_APP_GTMID) TagManager.initialize(tagManagerArgs);

export default App;
