import { ITrackerInformationResponse } from "../../../models";
import { useRequest, productsHttp } from "./../config";

export const useProducts = () => {
  const { get } = useRequest(productsHttp, "Tracker");

  const getTrackerInformation = async (
    id: number
  ): Promise<ITrackerInformationResponse> => {
    const response = await get(`getTrackerInformation/${id}`);
    return response.data;
  };

  return {
    getTrackerInformation,
  };
};
