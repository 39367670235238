import {
  IPagedResponseBaseModel,
  PaginationControlMolecule,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInsurance, useSessionContext } from "../../../hooks";
import { IInsuranceQuoteData, PrintTypeEnum } from "../../../models";
import {
  FeedbackModal,
  InsuranceCardMolecule,
  ViewInsuranceReceiptModal,
} from "../../molecules";
import { MyQuotesWrapper, NoQuotesFoundDiv } from "./my-quotes.organism.style";

interface IProps {
  quotesHistory?: IInsuranceQuoteData[];
  paginationData?: IPagedResponseBaseModel;
  setPageNumber: (v: number) => void;
}

export const MyQuotesOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { getDtLink, getProposalPdf } = useInsurance();

  const { showGenericErrorModal, showGenericSuccessModal } =
    useSessionContext();

  const [showReceiptQuoteId, setShowReceiptQuoteId] = useState<string>();
  const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);

  const redirectToDtAsync = useCallback(
    async (customerId: number) => {
      setShowLoadingModal(true);
      try {
        const url = await getDtLink(customerId);
        if (!url) throw new Error();
        window.location.href = url;
      } catch {
        setShowLoadingModal(false);
        showGenericErrorModal(t("GetDtLinkErrorMessage"));
      }
    },
    [getDtLink, showGenericErrorModal, t]
  );

  const onPrintProposalDetails = useCallback(
    (
      customerId: number | undefined,
      printType: PrintTypeEnum | undefined,
      setLoadingDetails: (v: boolean) => void,
      printFallback: (() => void) | undefined
    ) => {
      if (
        customerId === undefined ||
        printType === undefined ||
        printType === PrintTypeEnum.None
      ) {
        if (printFallback) printFallback();
        return;
      }

      setLoadingDetails(true);
      if (printType === PrintTypeEnum.Link) {
        getProposalPdf(customerId)
          .then((link) => {
            window.open(link, "_blank");
          })
          .catch(() => {
            showGenericErrorModal(
              t("QuoteDetailsLinkErrorMsg"),
              null,
              t("QuoteDetailsLinkErrorTitle")
            );
          })
          .finally(() => setLoadingDetails(false));
      } else if (printType === PrintTypeEnum.Email) {
        getProposalPdf(customerId)
          .then(() => {
            showGenericSuccessModal(
              t("QuoteDetailsEmailSuccessMsg"),
              null,
              t("QuoteDetailsEmailSuccessTitle")
            );
          })
          .catch(() => {
            showGenericErrorModal(
              t("QuoteDetailsEmailErrorMsg"),
              null,
              t("QuoteDetailsEmailErrorTitle")
            );
          })
          .finally(() => setLoadingDetails(false));
      }
    },
    [getProposalPdf, t, showGenericSuccessModal, showGenericErrorModal]
  );

  return (
    <>
      <MyQuotesWrapper>
        {props.quotesHistory === undefined ? (
          <SpinnerCenteredAtom classNames="mt-5" />
        ) : props.quotesHistory.length === 0 ? (
          <NoQuotesFoundDiv
            className={
              "h-100 mt-5 d-flex align-items-center justify-content-center"
            }
          >
            {t("NoQuotesFound")}
          </NoQuotesFoundDiv>
        ) : (
          <>
            {props.quotesHistory.map((m, i) => (
              <InsuranceCardMolecule
                data={m}
                key={`quote-${i}`}
                viewCreditOnClick={() => redirectToDtAsync(m.customerId)}
                viewInsuranceOnClick={() => setShowReceiptQuoteId(m.quoteId)}
              />
            ))}
            {props.paginationData && props.paginationData.totalPages > 1 && (
              <div className="default-align">
                <PaginationControlMolecule
                  translator={t}
                  paginationData={props.paginationData}
                  setPageNumber={props.setPageNumber}
                />
              </div>
            )}
          </>
        )}
      </MyQuotesWrapper>

      {props.quotesHistory && showReceiptQuoteId && (
        <ViewInsuranceReceiptModal
          show={!!showReceiptQuoteId}
          data={props.quotesHistory.find(
            (f) => f.quoteId === showReceiptQuoteId
          )}
          onHide={() => setShowReceiptQuoteId(undefined)}
          shareOnClick={() => console.log("sharing not implemented yet...")}
          onPrintProposalDetails={onPrintProposalDetails}
        />
      )}

      <FeedbackModal
        type="loading"
        title={t("Wait")}
        show={showLoadingModal}
        onHide={() => setShowLoadingModal(false)}
        content={t("WaitRedirectMessage")}
      />
    </>
  );
};
