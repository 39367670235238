import {
  ButtonPrimary,
  Combo,
  DateHelper,
  MaskHelper,
  ScrollHelper,
  ZipcodeAddress,
} from "c4u-web-components";
import { addDays, addYears, isValid, parse } from "date-fns";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormSectionMolecule } from "..";
import { IdentityIssuingAgenciesConstant } from "../../../constants";
import {
  useInsurance,
  useSessionContext,
  useZipcodeAddress,
} from "../../../hooks";
import {
  AddressTypeEnum,
  CnhTypeEnum,
  DocumentTypeEnum,
  GenderEnum,
  GenderStringEnum,
  ICustomerViewModel,
  MaritalStatusEnum,
  NationalityEnum,
  PoliticallyExposedPersonEnum,
  RelationshipTypeEnum,
} from "../../../models";
import { FormikControlFinalDataAtom } from "../../atoms";
import {
  WrapperAutocomplete,
  WrapperFinalDataMolecule,
} from "./final-data.molecule.style";
import { FinalDataMoleculeValidation } from "./final-data.molecule.validation";

interface IProps {
  title?: string;
  successModalText?: string;
  onCloseSuccessModal?: () => void;
  initialValues: ICustomerViewModel;
  onSubmit: (values: ICustomerViewModel) => Promise<void>;
}

export const FinalDataMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { getProfessionalActivity, getInsurancesList } = useInsurance();
  const { handleFormikException } = useSessionContext();
  const { getAddressByZipcode } = useZipcodeAddress();

  const formik = useFormik<ICustomerViewModel>({
    initialValues: props.initialValues ?? ({} as ICustomerViewModel),
    onSubmit: async (values, { setErrors }) => {
      try {
        await props.onSubmit(values);
      } catch (e) {
        handleFormikException(e, setErrors);
      }
    },
    validationSchema: FinalDataMoleculeValidation(),
    validateOnBlur: true,
    validateOnChange: false,
    isInitialValid: true,
  });

  useEffect(() => {
    getProfessionalActivity().then((options) => {
      setJobOptions(options);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const yesNoOptions = useMemo(() => {
    return [
      new Combo({ title: t("Yes"), value: "1" }),
      new Combo({ title: t("No"), value: "0" }),
    ];
  }, [t]);

  const genderOptions = useMemo(() => {
    return [
      new Combo({ title: t("Male"), value: GenderEnum.Male }),
      new Combo({ title: t("Female"), value: GenderEnum.Female }),
    ];
  }, [t]);

  const genderStringOptions = useMemo(() => {
    return [
      new Combo({ title: t("Male"), value: GenderStringEnum.Male }),
      new Combo({ title: t("Female"), value: GenderStringEnum.Female }),
    ];
  }, [t]);

  const ppeOptions = useMemo(() => {
    return [
      new Combo({
        title: t("NotExposed"),
        value: PoliticallyExposedPersonEnum.No,
      }),
      new Combo({
        title: t("Self"),
        value: PoliticallyExposedPersonEnum.Yes,
      }),
      new Combo({
        title: t("Relation"),
        value: PoliticallyExposedPersonEnum.Relative,
      }),
    ];
  }, [t]);

  const maritalStatus = useMemo(() => {
    return [
      new Combo({ title: t("Single"), value: MaritalStatusEnum.Single }),
      new Combo({
        title: t("CivilUnion"),
        value: MaritalStatusEnum.CivilUnion,
      }),
      new Combo({ title: t("Married"), value: MaritalStatusEnum.Married }),
      new Combo({ title: t("Divorced"), value: MaritalStatusEnum.Divorced }),
      new Combo({ title: t("Widowed"), value: MaritalStatusEnum.Widowed }),
    ];
  }, [t]);

  const nationalityOptions = useMemo(() => {
    return [
      new Combo({ title: t("Brazilian"), value: NationalityEnum.Brazilian }),
      new Combo({ title: t("Foreigner"), value: NationalityEnum.Foreign }),
    ];
  }, [t]);

  const documentOptions = useMemo(() => {
    return [
      new Combo({ title: t("Rg"), value: DocumentTypeEnum.Rg }),
      new Combo({ title: t("Ric"), value: DocumentTypeEnum.Ric }),
    ];
  }, [t]);

  const ufOptions = useMemo(() => {
    const states = [
      "AC",
      "AL",
      "AM",
      "AP",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MG",
      "MS",
      "MT",
      "PA",
      "PB",
      "PE",
      "PI",
      "PR",
      "RJ",
      "RN",
      "RO",
      "RR",
      "RS",
      "SC",
      "SE",
      "SP",
      "TO",
    ];
    return states.map((m) => new Combo({ title: m, value: m }));
  }, []);

  const addressTypeOptions = useMemo(() => {
    return [
      new Combo({ title: t("Grove"), value: AddressTypeEnum.Grove }),
      new Combo({ title: t("Avenue"), value: AddressTypeEnum.Avenue }),
      new Combo({ title: t("Square"), value: AddressTypeEnum.Square }),
      new Combo({ title: t("Street"), value: AddressTypeEnum.Street }),
    ];
  }, [t]);

  const relationshipOptions = useMemo(() => {
    return [
      new Combo({ title: t("Children"), value: RelationshipTypeEnum.Children }),
      new Combo({ title: t("Parent"), value: RelationshipTypeEnum.Parent }),
      new Combo({ title: t("Sibling"), value: RelationshipTypeEnum.Sibling }),
      new Combo({ title: t("Spouse"), value: RelationshipTypeEnum.Spouse }),
    ];
  }, [t]);

  const [jobOptions, setJobOptions] = useState<Combo[]>([
    new Combo({ title: t("Loading") + "...", value: "null" }),
  ]);

  const bonusClassOptions = useMemo(() => {
    return [
      new Combo({ title: t("NoBonusClass"), value: "0" }),
      ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
        (m) => new Combo({ title: m, value: m })
      ),
    ];
  }, [t]);

  const [insuranceCompanyOptions, setInsuranceCompanyOptions] =
    useState<Combo[]>();
  useEffect(() => {
    getInsurancesList()
      .then((data) =>
        setInsuranceCompanyOptions(
          data.map((m) => new Combo({ title: m.name, value: m.susep }))
        )
      )
      .catch((e) => {
        console.log("Error getting insurance companies list", e);
        setInsuranceCompanyOptions([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const manufactureYearOptions = useMemo(() => {
    const anoModelo = props.initialValues.modelYear;
    return [
      new Combo({ title: anoModelo - 1, value: anoModelo - 1 }),
      new Combo({ title: anoModelo, value: anoModelo }),
    ];
  }, [props.initialValues.modelYear]);

  const getAddressType = (logradouro: string) => {
    const addressType = logradouro.split(" ");
    switch (addressType[0]) {
      case "Bosque":
        return AddressTypeEnum.Grove;
      case "Avenida":
        return AddressTypeEnum.Avenue;
      case "Praça":
        return AddressTypeEnum.Square;
      case "Rua":
        return AddressTypeEnum.Street;
      default:
        return AddressTypeEnum.Street;
    }
  };

  const [zipCodeAddress, setZipCodeAddress] = useState<ZipcodeAddress>();
  const handleMailingAddress = async (cep: string) => {
    const address = await getAddressByZipcode(cep);
    setZipCodeAddress(address);
    if (address?.erro) {
      formik.setFieldValue("mailingAddressCity", "");
      formik.setFieldValue("mailingAddressState", "");
      formik.setFieldValue("mailingAddressStreet", "");
      formik.setFieldValue("mailingAddressNeighborhood", "");
    } else if (address?.uf) {
      formik.setFieldValue("mailingAddressCity", address.localidade);
      formik.setFieldValue("mailingAddressState", address.uf);
      formik.setFieldValue("mailingAddressStreet", address.logradouro);
      formik.setFieldValue("mailingAddressNeighborhood", address.bairro);
      formik.setFieldValue(
        "mailingAddressType",
        getAddressType(address.logradouro)
      );
      if (
        (zipCodeAddress !== undefined &&
          props.initialValues.mailingAddressNumber &&
          props.initialValues.mailingAddressNumber !== cep) ||
        (zipCodeAddress?.cep && zipCodeAddress?.cep !== cep)
      ) {
        formik.setFieldValue("mailingAddressNumber", "");
        formik.setFieldValue("mailingAddressComplement", "");
      }
    }
  };
  useEffect(() => {
    if (
      formik?.values.mailingAddressZipCode?.replace(/[^\d]/g, "").length === 8
    ) {
      handleMailingAddress(formik.values.mailingAddressZipCode);
    } else {
      setZipCodeAddress(undefined);
      formik.setFieldValue("mailingAddressCity", "", false);
      formik.setFieldValue("mailingAddressState", "", false);
      formik.setFieldValue("mailingAddressStreet", "", false);
      formik.setFieldValue("mailingAddressNeighborhood", "", false);
      formik.setFieldValue("mailingAddressNumber", "", false);
      formik.setFieldValue("mailingAddressComplement", "", false);
    }
  }, [formik.values.mailingAddressZipCode]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseRelativeAddress = async (cep: string) => {
    const address = await getAddressByZipcode(cep);
    if (address?.erro) {
      formik.setFieldValue("politicallyExposedRelativeAddressCity", "");
      formik.setFieldValue("politicallyExposedRelativeAddressState", "");
      formik.setFieldValue("politicallyExposedRelativeAddressStreet", "");
      formik.setFieldValue("politicallyExposedRelativeAddressNeighborhood", "");
    } else if (address?.uf) {
      formik.setFieldValue(
        "politicallyExposedRelativeAddressCity",
        address.localidade
      );
      formik.setFieldValue(
        "politicallyExposedRelativeAddressState",
        address.uf
      );
      formik.setFieldValue(
        "politicallyExposedRelativeAddressStreet",
        address.logradouro
      );
      formik.setFieldValue(
        "politicallyExposedRelativeAddressNeighborhood",
        address.bairro
      );
      formik.setFieldValue(
        "politicallyExposedRelativeAddressType",
        getAddressType(address.logradouro)
      );
    }
  };
  useEffect(() => {
    if (
      formik?.values.politicallyExposedRelativeAddressZipCode?.replace(
        /[^\d]/g,
        ""
      ).length === 8
    )
      handleCloseRelativeAddress(
        formik.values.politicallyExposedRelativeAddressZipCode
      );
  }, [formik.values.politicallyExposedRelativeAddressZipCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      formik?.values.nationality?.toString() ===
      NationalityEnum.Foreign.toString()
    ) {
      formik.setFieldValue("identityType", DocumentTypeEnum.Rne);
      formik.setFieldValue("country", "Estrangeiro");
    } else {
      formik.setFieldValue("identityType", DocumentTypeEnum.Rg);
      formik.setFieldValue("country", "Brasileiro");
    }
  }, [formik.values.nationality]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (formik.values.identityNumber) formik.validateField("identityNumber");
    if (formik.values.email) formik.validateField("email");
    if (formik.values.phoneNumber) formik.validateField("phoneNumber");
    // eslint-disable-next-line
  }, []);

  const stdFieldWidth = 3;

  const handleSubmit = useCallback(
    (e: any) => {
      formik.handleSubmit(e);
      setTimeout(() => {
        ScrollHelper.scrollToError(document, 96);
      }, 300);
    },
    // eslint-disable-next-line
    [formik]
  );

  useEffect(() => {
    if (jobOptions && props.initialValues.professionalActivity) {
      const initialValue = props.initialValues.professionalActivity
        ?.toLowerCase()
        .trim();

      const optionValue = jobOptions.find(
        (x) => x.value?.toString().toLowerCase().trim() === initialValue
      )?.value;

      formik.setFieldValue("professionalActivity", optionValue ?? "");
    }
  }, [jobOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const startDate = formik.values.bonusClass?.startDate;
    if (
      !formik.values.bonusClass?.endDate &&
      startDate &&
      /^\d{2}\/\d{2}\/\d{4}$/.test(startDate)
    ) {
      try {
        const date = parse(startDate, "dd/MM/yyyy", new Date());
        if (isValid(date)) {
          const endDate = addDays(addYears(date, 1), -1);
          formik.setFieldValue(
            "bonusClass.endDate",
            DateHelper.dateFormat(endDate)
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.bonusClass?.startDate]);

  useEffect(() => {
    if (
      formik.values.bonusClass?.bonusClassCode &&
      formik.values.bonusClass.bonusClassCode.toString() === "0"
    ) {
      formik.setFieldValue("bonusClass.startDate", "");
      formik.setFieldValue("bonusClass.endDate", "");
      formik.setFieldValue("bonusClass.insurerSUSEPCode", "");
      formik.setFieldValue("bonusClass.policyNumber", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.bonusClass?.bonusClassCode]);

  useEffect(() => {
    if (formik.values.overnightAtDifferentZipCode === "0")
      formik.setFieldValue("overnightZipCode", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.overnightAtDifferentZipCode]);

  useEffect(() => {
    if (formik.values.hasSocialName === "0")
      formik.setFieldValue("socialName", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.hasSocialName]);

  useEffect(() => {
    if (formik.values.driverAgeBetween18And25 === "0")
      formik.setFieldValue("driverGenderBetween18And25", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.driverAgeBetween18And25]);

  return (
    <WrapperFinalDataMolecule>
      <Form
        className={"form-default"}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <input type="hidden" value="disable-autocomplete-chrome" />

        {/* Dados do segurado */}
        <FormSectionMolecule title={"Dados do Segurado"}>
          <Form.Row>
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth * 2}
              property={"name"}
              label={t("Name")}
              isDt
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              mask={MaskHelper.Cpf}
              property={"cpf"}
              label={t("Cpf")}
              isDt
            />
          </Form.Row>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"radio"}
              data={yesNoOptions}
              formik={formik}
              md={6}
              property={"hasSocialName"}
              label={t("HasSocialName")}
              disabled={false}
              isDt
            />
            {formik.values.hasSocialName === "1" && (
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                property={"socialName"}
                label={t("SociaName")}
                disabled={false}
                isDt
              />
            )}
          </Form.Row>
          <Form.Row>
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth * 2}
              property={"email"}
              label={t("Email")}
              isDt
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              mask={MaskHelper.Date}
              property={"birthDate"}
              label={t("Birthdate")}
              isDt
            />
          </Form.Row>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"radio"}
              data={genderOptions}
              formik={formik}
              md={stdFieldWidth}
              property={"customerGender"}
              label={t("Gender")}
              isDt
            />
            <WrapperAutocomplete md={6}>
              <FormikControlFinalDataAtom
                type={"dropdown-search"}
                placeholder={t("Search")}
                data={jobOptions}
                formik={formik}
                property={"professionalActivity"}
                label={t("ProfessionalActivity")}
                isDt
              />
            </WrapperAutocomplete>
          </Form.Row>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"select"}
              data={maritalStatus}
              formik={formik}
              md={stdFieldWidth}
              property={"maritalStatus"}
              label={t("MaritalStatus")}
              isDt
            />
            <FormikControlFinalDataAtom
              type={"select"}
              data={nationalityOptions}
              formik={formik}
              md={stdFieldWidth}
              property={"nationality"}
              label={t("Nationality")}
              isDt
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              mask={MaskHelper.Phone}
              property={"phoneNumber"}
              label={t("PhoneNumber")}
              isDt
            />
          </Form.Row>
        </FormSectionMolecule>

        {/* Endereço para correspondência */}
        <FormSectionMolecule title={"Endereço para Correspondência"}>
          <Form.Row>
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              mask={MaskHelper.Cep}
              property={"mailingAddressZipCode"}
              label={t("ZipCode")}
              isDt
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={6}
              property={"mailingAddressStreet"}
              disabled={!!zipCodeAddress?.logradouro}
              label={t("StreetName")}
              isDt
            />
          </Form.Row>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"select"}
              data={addressTypeOptions}
              formik={formik}
              md={stdFieldWidth}
              property={"mailingAddressType"}
              label={t("AddressType")}
              disabled={
                !!zipCodeAddress?.logradouro &&
                !!formik.values?.mailingAddressType
              }
              isDt
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              property={"mailingAddressNumber"}
              label={t("Number")}
              maxLength={5}
              isDt
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              property={"mailingAddressComplement"}
              label={t("Complement")}
              isDt
            />
          </Form.Row>
          <Form.Row>
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              property={"mailingAddressNeighborhood"}
              label={t("Neighborhood")}
              disabled={!!zipCodeAddress?.bairro}
              isDt
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              property={"mailingAddressCity"}
              label={t("City")}
              disabled={!!zipCodeAddress?.localidade}
              isDt
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              property={"mailingAddressState"}
              disabled={!!zipCodeAddress?.uf}
              label={t("State")}
              isDt
            />
          </Form.Row>
        </FormSectionMolecule>

        {/* Documentos */}
        {formik.values.nationality?.toString() ===
        NationalityEnum.Foreign.toString() ? (
          <FormSectionMolecule title={"Documento de Identidade - RNE"}>
            <Form.Row>
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                property={"identityNumber"}
                label={t("RneNumber")}
                isDt
              />
              <FormikControlFinalDataAtom
                formik={formik}
                property={"identityIssuingAgency"}
                label={t("IssuingBody")}
                isDt
              />
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                mask={MaskHelper.Date}
                property={"identityIssuingDate"}
                label={t("IssuingDate")}
                isDt
              />
            </Form.Row>
          </FormSectionMolecule>
        ) : (
          <FormSectionMolecule title={"Documento de Identidade - RG/RIC"}>
            <Form.Row>
              <FormikControlFinalDataAtom
                type={"select"}
                hideSelectOption
                data={documentOptions}
                formik={formik}
                md={stdFieldWidth}
                property={"identityType"}
                label={t("IdentityType")}
                isDt
              />
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                property={"identityNumber"}
                label={t("Number")}
                isDt
              />
            </Form.Row>
            <Form.Row>
              <WrapperAutocomplete md={stdFieldWidth}>
                <FormikControlFinalDataAtom
                  type={"dropdown-search"}
                  placeholder={t("Search")}
                  data={IdentityIssuingAgenciesConstant.allAgenciesCombo}
                  formik={formik}
                  property={"identityIssuingAgency"}
                  label={t("IssuingBody")}
                  isDt
                />
              </WrapperAutocomplete>
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                mask={MaskHelper.Date}
                property={"identityIssuingDate"}
                label={t("IssuingDate")}
                isDt
              />
            </Form.Row>
          </FormSectionMolecule>
        )}

        <FormSectionMolecule title={"CNH"}>
          <Form.Row>
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              property={"cnhNumber"}
              label={t("CnhNumber")}
              maxLength={11}
              func={(value) => value && value.replace(/\D/g, "")}
            />
            <FormikControlFinalDataAtom
              type={"select"}
              data={Object.values(CnhTypeEnum).map((m) => ({
                title: m,
                value: m,
              }))}
              formik={formik}
              md={stdFieldWidth}
              property={"cnhCategory"}
              label={t("CnhCategory")}
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              mask={MaskHelper.Date}
              property={"cnhIssuingDate"}
              label={t("IssuingDate")}
            />
          </Form.Row>
        </FormSectionMolecule>

        {/* Faixa de Renda */}
        <FormSectionMolecule>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"money"}
              formik={formik}
              md={stdFieldWidth}
              property={"customerIncome"}
              label={t("customerIncome")}
              isDt
            />
          </Form.Row>
        </FormSectionMolecule>

        {/* PPE */}
        <FormSectionMolecule>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"radio"}
              data={ppeOptions}
              formik={formik}
              md={9}
              property={"politicallyExposedPerson"}
              label={t("Ppe")}
            />
          </Form.Row>
        </FormSectionMolecule>

        {formik.values.politicallyExposedPerson?.toString() ===
          PoliticallyExposedPersonEnum.Relative.toString() && (
          <>
            <FormSectionMolecule title={"Dados da PPE"}>
              <Form.Row>
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  property={"politicallyExposedRelativeName"}
                  label={t("Name")}
                />
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  mask={MaskHelper.Cpf}
                  property={"politicallyExposedRelativeCpf"}
                  label={t("Cpf")}
                />
                <FormikControlFinalDataAtom
                  type={"select"}
                  data={relationshipOptions}
                  formik={formik}
                  md={stdFieldWidth}
                  property={"politicallyExposedRelativeRelationshipType"}
                  label={t("Relation")}
                />
              </Form.Row>
              <Form.Row>
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  property={"politicallyExposedRelativeEmail"}
                  label={t("Email")}
                />
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  mask={MaskHelper.Phone}
                  property={"politicallyExposedRelativePhoneNumber"}
                  label={t("PhoneNumber")}
                />
              </Form.Row>
            </FormSectionMolecule>

            <FormSectionMolecule title={"Endereço da PPE"}>
              <Form.Row>
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  mask={MaskHelper.Cep}
                  property={"politicallyExposedRelativeAddressZipCode"}
                  label={t("ZipCode")}
                />
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={6}
                  property={"politicallyExposedRelativeAddressStreet"}
                  label={t("StreetName")}
                />
              </Form.Row>
              <Form.Row>
                <FormikControlFinalDataAtom
                  type={"select"}
                  data={addressTypeOptions}
                  formik={formik}
                  md={stdFieldWidth}
                  property={"politicallyExposedRelativeAddressType"}
                  label={t("AddressType")}
                />
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  property={"politicallyExposedRelativeAddressNumber"}
                  label={t("Number")}
                />
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  property={"politicallyExposedRelativeAddressComplement"}
                  label={t("Complement")}
                />
              </Form.Row>
              <Form.Row>
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  property={"politicallyExposedRelativeAddressNeighborhood"}
                  label={t("Neighborhood")}
                />
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  property={"politicallyExposedRelativeAddressCity"}
                  label={t("City")}
                />
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  property={"politicallyExposedRelativeAddressState"}
                  label={t("State")}
                />
              </Form.Row>
            </FormSectionMolecule>
          </>
        )}

        {/* Questões do Plugin */}
        <FormSectionMolecule title={"Perguntas"}>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"radio"}
              data={yesNoOptions}
              formik={formik}
              md={5}
              property={"overnightAtDifferentZipCode"}
              label={t("OvernightAtDifferentZipCode")}
              disabled={false}
              isDt
            />
            {formik.values.overnightAtDifferentZipCode === "1" && (
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                mask={MaskHelper.Cep}
                property={"overnightZipCode"}
                label={t("OvernightZipCode")}
                disabled={false}
                isDt
              />
            )}
          </Form.Row>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"radio"}
              data={yesNoOptions}
              formik={formik}
              md={5}
              property={"businessPurposes"}
              label={t("BusinessPurposes")}
              disabled={false}
              isDt
            />
          </Form.Row>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"radio"}
              data={yesNoOptions}
              formik={formik}
              md={5}
              property={"driverAgeBetween18And25"}
              label={t("DriverAgeBetween18And25")}
              disabled={false}
              isDt
            />
            {formik.values.driverAgeBetween18And25 === "1" && (
              <FormikControlFinalDataAtom
                type={"checkbox"}
                data={genderStringOptions}
                formik={formik}
                md={stdFieldWidth}
                property={"driverGenderBetween18And25"}
                label={t("Gender")}
                disabled={false}
                isDt
              />
            )}
          </Form.Row>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"select"}
              data={bonusClassOptions}
              formik={formik}
              md={stdFieldWidth}
              property={"bonusClass.bonusClassCode"}
              label={t("HasBonusClass")}
              disabled={false}
              isDt
              hideSelectOption
            />
            {!!formik.values.bonusClass?.bonusClassCode &&
              formik.values.bonusClass.bonusClassCode.toString() !== "0" && (
                <>
                  <FormikControlFinalDataAtom
                    type={"select"}
                    data={insuranceCompanyOptions}
                    formik={formik}
                    md={stdFieldWidth}
                    property={"bonusClass.insurerSUSEPCode"}
                    label={t("InsurerSUSEPCode")}
                    disabled={false}
                    isDt
                  />
                  <FormikControlFinalDataAtom
                    formik={formik}
                    md={stdFieldWidth}
                    property={"bonusClass.policyNumber"}
                    label={t("PolicyNumber")}
                    disabled={false}
                    isDt
                  />
                </>
              )}
          </Form.Row>
          {!!formik.values.bonusClass?.bonusClassCode &&
            formik.values.bonusClass.bonusClassCode.toString() !== "0" && (
              <Form.Row>
                <Col md={stdFieldWidth} className="d-none d-md-block"></Col>
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  mask={MaskHelper.Date}
                  property={"bonusClass.startDate"}
                  label={t("PolicyStartDate")}
                  disabled={false}
                  isDt
                />
                <FormikControlFinalDataAtom
                  formik={formik}
                  md={stdFieldWidth}
                  mask={MaskHelper.Date}
                  property={"bonusClass.endDate"}
                  label={t("PolicyEndDate")}
                  disabled={false}
                  isDt
                />
              </Form.Row>
            )}
        </FormSectionMolecule>

        {/* Dados do veículo */}
        <FormSectionMolecule title={"Dados do Veículo"}>
          <Form.Row>
            <FormikControlFinalDataAtom
              type={"select"}
              data={ufOptions}
              formik={formik}
              md={stdFieldWidth}
              property={"plateUf"}
              label={t("PlateUf")}
              isDt
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              property={"color"}
              label={t("Color")}
              isDt
            />
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              mask={MaskHelper.Chassi}
              placeholderChar={"\u2000"}
              property={"chassi"}
              label={t("Chassi")}
              isDt
            />
          </Form.Row>
          {!formik.values.isZeroKm && (
            <Form.Row>
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                mask={MaskHelper.PlateBr}
                placeholderChar={"\u2000"}
                property={"plate"}
                label={t("Plate")}
                isDt
              />
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                mask={MaskHelper.Renavam}
                placeholderChar={"\u2000"}
                property={"renavam"}
                label={t("Renavam")}
                isDt
              />
            </Form.Row>
          )}
          <Form.Row>
            <FormikControlFinalDataAtom
              formik={formik}
              md={stdFieldWidth}
              property={"modelYear"}
              label={t("ModelYear")}
              isDt
              disabled
            />
            <FormikControlFinalDataAtom
              type={"select"}
              data={manufactureYearOptions}
              formik={formik}
              md={stdFieldWidth}
              property={"manufactureYear"}
              label={t("ManufactureYear")}
              isDt
            />
          </Form.Row>
        </FormSectionMolecule>

        {formik.values.isZeroKm && (
          <FormSectionMolecule title={"Nota Fiscal"}>
            <Form.Row>
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                property={"invoiceNumber"}
                label={t("Number")}
                isDt
              />
              <FormikControlFinalDataAtom
                type={"money"}
                formik={formik}
                md={stdFieldWidth}
                property={"invoiceValue"}
                label={t("Value")}
                isDt
              />
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                mask={MaskHelper.Date}
                property={"invoiceEmissionDate"}
                label={t("EmissionDate")}
                isDt
              />
            </Form.Row>
            <Form.Row>
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                property={"invoiceDealershipCode"}
                label={t("DealershipCode")}
                isDt
              />
              <FormikControlFinalDataAtom
                formik={formik}
                md={stdFieldWidth}
                mask={MaskHelper.Date}
                property={"invoiceDealershipVehicleLeaveDate"}
                label={t("DealershipVehicleLeaveDate")}
                isDt
              />
            </Form.Row>
          </FormSectionMolecule>
        )}

        {/* Submit button */}
        <Form.Row>
          <Form.Group as={Col} md className={"text-right pt-4"}>
            <ButtonPrimary
              type={"submit"}
              sizex={"md"}
              loading={formik.isSubmitting}
            >
              {t("Continue")}
            </ButtonPrimary>
          </Form.Group>
        </Form.Row>
      </Form>
    </WrapperFinalDataMolecule>
  );
};
