import React from "react";
import {
  FormikControlAtom as FormikControlAtomComponents,
  FormikControlAtomProps,
  ObjectHelper,
  theme,
} from "c4u-web-components";
import { useTranslation } from "react-i18next";
import { useInsuranceContext } from "../../../hooks";
import { OriginEnum } from "../../../models";

interface IProps extends Omit<FormikControlAtomProps, "translate"> {
  isDt?: boolean;
}

export const FormikControlFinalDataAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { origin } = useInsuranceContext();

  const { children, disabled, ...FormikProps } = props;

  const initialValue = ObjectHelper.getNestedProperty(
    props.formik.values,
    props.property
  );
  const hasInitialValue = !(
    [null, undefined].includes(initialValue) || initialValue?.length === 0
  );

  const error = ObjectHelper.getNestedProperty(
    props.formik.errors,
    props.property,
  );

  const disableField =
    disabled ??
    (props.isDt &&
      origin === OriginEnum.Dt &&
      hasInitialValue &&
      !error);
  const highlightField =
    origin === OriginEnum.Dt &&
    !hasInitialValue &&
    !["checkbox", "radio"].includes(props.type ?? "");

  return (
    <FormikControlAtomComponents
      translate={t}
      disabled={disableField}
      {...FormikProps}
      className={highlightField ? "highlight-empty" : ""}
      autoComplete={props.property}
      borderColor={theme.colors.DarkGrayOpacity}
      borderColorError={theme.colors.Validation.Invalid}
    >
      {children}
    </FormikControlAtomComponents>
  );
};
