import { TypesHelper } from "c4u-web-components";

export interface IBonusClassViewModel {
  startDate?: string;
  endDate?: string;
  insurerSUSEPCode?: number;
  bonusClassCode: number;
  policyNumber?: string;
}

export class BonusClassViewModel {
  startDate: Date | null;
  endDate: Date | null;
  insurerSUSEPCode: number | null;
  bonusClassCode: number | null;
  policyNumber: string | null;

  constructor({
    startDate,
    endDate,
    insurerSUSEPCode,
    bonusClassCode,
    policyNumber,
  }: IBonusClassViewModel) {
    this.startDate = TypesHelper.toDateNull(startDate);
    this.endDate = TypesHelper.toDateNull(endDate);
    this.insurerSUSEPCode = TypesHelper.toNumberNotZeroNull(insurerSUSEPCode);
    this.bonusClassCode = TypesHelper.toNumberNull(bonusClassCode);
    this.policyNumber = TypesHelper.toStringNull(policyNumber);
  }
}
