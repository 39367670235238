import {
  ICoxUser,
  ICreatePasswordlessRequest,
  IDealershipComboData,
} from "c4u-web-components";
import { FormikErrors } from "formik";
import { useContext } from "react";
import { SessionContext } from "../../contexts";

export interface SessionProviderState {
  genericModalMsg: string | null;
  genericErrorModal: boolean;
  showGenericErrorModal: (
    text?: string | null,
    onClose?: (() => void) | null,
    title?: string | null
  ) => void;
  hideGenericErrorModal: () => void;
  genericWarningModal: boolean;
  showGenericWarningModal: (
    text?: string | null,
    onClose?: (() => void) | null,
    title?: string | null
  ) => void;
  hideGenericWarningModal: () => void;
  genericSuccessModal: boolean;
  showGenericSuccessModal: (
    text?: string | null,
    onClose?: (() => void) | null,
    title?: string | null
  ) => void;
  hideGenericSuccessModal: () => void;
  handleFormikError: <Values>(
    exception: any,
    setErrors?: FormikErrors<Values>
  ) => string | undefined;
  handleFormikException: <Values>(
    exception: any,
    setErrors?: FormikErrors<Values>,
    msg?: string,
    title?: string
  ) => string | undefined;
  titleErrorModal?: string;
  titleWarningModal?: string;
  titleSuccessModal?: string;
  usernamePawordlessContext: ICreatePasswordlessRequest;
  setUsernamePawordlessContext: React.Dispatch<
    React.SetStateAction<ICreatePasswordlessRequest>
  >;
  coxUserContext: ICoxUser;
  setCoxUserContext: React.Dispatch<React.SetStateAction<ICoxUser>>;
  selectedDealershipContext: IDealershipComboData | undefined;
  setSelectedDealershipContext: React.Dispatch<
    React.SetStateAction<IDealershipComboData | undefined>
  >;
  handleEventGTM: (eventData: object) => void;
}

export const useSessionContext = () => {
  const context = useContext<SessionProviderState>(SessionContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
