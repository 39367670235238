import { Combo } from "c4u-web-components";

const identityIssuingAgencies = [
  { title: "DESIPE" },
  { title: "DESP" },
  { title: "DENATRAN" },
  { title: "DFSP" },
  { title: "DGPC" },
  { title: "DI" },
  { title: "DPC" },
  { title: "DPGE" },
  { title: "DSC" },
  { title: "DPTC" },
  { title: "EAPJU" },
  { title: "FUNAI" },
  { title: "GEJ" },
  { title: "GISI" },
  { title: "IC" },
  { title: "IFP" },
  { title: "IIDAMP" },
  { title: "IIDML" },
  { title: "IIEP" },
  { title: "IITP" },
  { title: "IMLC" },
  { title: "INI" },
  { title: "IPF" },
  { title: "IPT" },
  { title: "ITB" },
  { title: "JUIJ" },
  { title: "MRE" },
  { title: "PCIID" },
  { title: "PDFII" },
  { title: "PIG" },
  { title: "PJEG" },
  { title: "PTC" },
  { title: "RBR" },
  { title: "RFBR" },
  { title: "SEDPF" },
  { title: "SEDS" },
  { title: "SEJSP" },
  { title: "SEPC" },
  { title: "SES" },
  { title: "SESDP" },
  { title: "SESP" },
  { title: "SGPJ" },
  { title: "SIC" },
  { title: "SJS" },
  { title: "SJSP" },
  { title: "SJTC" },
  { title: "SPC" },
  { title: "SPCII" },
  { title: "SPSP" },
  { title: "SPTC" },
  { title: "SSI" },
  { title: "SSP" },
  { title: "SSPDC" },
  { title: "OUTROS" },
];

export class IdentityIssuingAgenciesConstant {
  static allAgenciesCombo = identityIssuingAgencies.map(
    (m) => new Combo({ title: m.title, value: m.title })
  );
}
