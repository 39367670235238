import { Routers } from "../models";
import {
  FinalDataPage,
  MyQuotesPage,
  NewQuotePage,
  PasswordlessRegisterPage,
  PaymentPage,
  QuotesPage,
} from "../ui/pages";
import { MainTemplate, PasswordlessTemplate } from "../ui/templates";

const paths = {
  insuranceRegister: (param?: string) => `/register/${param ?? ""}`,
  home: () => `/`,
  myQuotes: (param?: string) => `/my-quotes/${param ?? ""}`,
  newQuote: (param?: string) => `/new-quote/${param ?? ""}`,
  finalData: (
    publicPersonId: string | null = null,
    encodeLenderCode: string | null = null,
    param?: string
  ) => {
    publicPersonId = publicPersonId || ":publicPersonId";
    encodeLenderCode = encodeLenderCode || ":encodeLenderCode";
    return `/final-data/${publicPersonId}/${encodeLenderCode}/${param ?? ""}`;
  },
  quotes: (id?: number | string, param?: string) =>
    `/quotes/${id ?? ":id"}/${param ?? ""}`,
  payment: (id?: number | string, param?: string) =>
    `/payment/${id ?? ":id"}/${param ?? ""}`,
};

const Definition = {
  Passwordless: "passwordless",
  typeJourneyParam: ":typeJourney",
};

const routes = [
  {
    path: paths.insuranceRegister(Definition.typeJourneyParam),
    component: PasswordlessRegisterPage,
    template: PasswordlessTemplate,
    title: "Insurance Passwordless",
  },
  {
    path: paths.home(),
    component: MyQuotesPage,
    template: MainTemplate,
    title: "Home",
  },
  {
    path: paths.myQuotes(),
    component: MyQuotesPage,
    template: MainTemplate,
    title: "My quotes",
  },
  {
    path: paths.myQuotes(Definition.typeJourneyParam),
    component: MyQuotesPage,
    template: PasswordlessTemplate,
    title: "My quotes",
  },
  {
    path: paths.newQuote(),
    component: NewQuotePage,
    template: MainTemplate,
    title: "New quote",
  },
  {
    path: paths.newQuote(Definition.typeJourneyParam),
    component: NewQuotePage,
    template: PasswordlessTemplate,
    title: "New quote",
  },
  {
    path: paths.finalData(),
    component: FinalDataPage,
    template: MainTemplate,
    title: "FinalData",
  },
  {
    path: paths.finalData(null, null, Definition.typeJourneyParam),
    component: FinalDataPage,
    template: PasswordlessTemplate,
    title: "FinalData",
  },
  {
    path: paths.quotes(),
    component: QuotesPage,
    template: MainTemplate,
    title: "Quotes",
  },
  {
    path: paths.quotes(undefined, Definition.typeJourneyParam),
    component: QuotesPage,
    template: PasswordlessTemplate,
    title: "Quotes",
  },
  {
    path: paths.payment(),
    component: PaymentPage,
    template: MainTemplate,
    title: "Payment",
  },
  {
    path: paths.payment(undefined, Definition.typeJourneyParam),
    component: PaymentPage,
    template: PasswordlessTemplate,
    title: "Payment",
  },
] as Routers[];

export { paths, routes as default, Definition };
