import { CancelToken } from "axios";
import {
  Combo,
  HttpHelper,
  InsuranceCompanyEnum,
  VehicleCategoriesEnum,
  TransferToUserRequest,
} from "c4u-web-components";
import {
  ICustomerViewModelUrlParams,
  IGetCustomerResponse,
  IGetInsurancesResponse,
  IGetQuotesParams,
  IGetQuotesResponse,
  IGetVehicle,
  IPlanChoiceRequest,
  IPlanChoiceResponse,
  IQuoteModel,
  ISaveVehicleModel,
  IStartQuote,
  PaymentRequest,
  PostCustomerRequest,
} from "../../../models";
import { insuranceHttp, useRequest } from "./../config";

export const useInsurance = () => {
  const { get, put, post } = useRequest(insuranceHttp, "Insurance");

  const startQuote = async (
    customerId: number,
    cancelToken?: CancelToken
  ): Promise<IStartQuote[]> => {
    const { data } = await get(
      `plans/${customerId}/start`,
      cancelToken ? { cancelToken } : undefined,
      3
    );
    return data;
  };

  const quoteResult = async (
    customerId: number,
    hash: string,
    cancelToken?: CancelToken
  ): Promise<IQuoteModel> => {
    const { data } = await get(
      `plans/${customerId}/result/${hash}`,
      cancelToken ? { cancelToken } : undefined,
      3
    );
    return data;
  };

  const getQuote = async (customerId: number): Promise<IQuoteModel[]> => {
    const { data } = await get(`plans/${customerId}`);
    return data;
  };

  const getCustomerById = async (
    customerId: number
  ): Promise<IGetCustomerResponse> => {
    const { data } = await get(`customer/${customerId}`);
    return data;
  };

  const savePlan = async (
    request: IPlanChoiceRequest
  ): Promise<IPlanChoiceResponse> => {
    const { data } = await put(`planChoice`, request);
    return data;
  };

  const savePayment = async (request: PaymentRequest): Promise<void> => {
    await post(`proposal`, request);
  };

  const getCustomerData = async (
    params: ICustomerViewModelUrlParams
  ): Promise<IGetCustomerResponse> => {
    const response = await get(
      `customer/${params.publicPersonId}/${params.encodeLenderCode}/`
    );
    return response.data;
  };

  const saveCustomerData = async (
    request: PostCustomerRequest
  ): Promise<void> => {
    await post(`customer`, request);
  };

  const getProfessionalActivity = async (): Promise<Combo[]> => {
    const response = await get(`professionalActivity`);
    return response.data;
  };

  const getQuotesHistory = async (
    params: IGetQuotesParams,
    cancelToken?: CancelToken
  ): Promise<IGetQuotesResponse> => {
    const queryString = HttpHelper.BuildQueryString(params);
    const response = await get(
      `quotes${queryString}`,
      cancelToken ? { cancelToken } : undefined
    );
    return response.data;
  };

  const getUpdateList = async (): Promise<void> => {
    // call trigger - no need to await
    get(`updateList`);
  };

  const getDtLink = async (customerId: number): Promise<string> => {
    const response = await get(`dtLink/${customerId}`);
    return response.data;
  };

  const getVehicle = async (
    year: number,
    molicar: number
  ): Promise<IGetVehicle> => {
    const response = await get(`getVehicleByMolicar/${year}/${molicar}`);
    return response.data;
  };

  const saveVehicle = async (
    molicar: string,
    year: number,
    vehicleCategory: VehicleCategoriesEnum,
    passwordlessUsername: string | null = null
  ): Promise<ISaveVehicleModel> => {
    const response = await post(`saveCustomerByMolicar`, {
      molicarCode: molicar,
      modelYear: year,
      vehicleCategory: vehicleCategory,
      passwordless: passwordlessUsername,
    });
    return response.data;
  };

  const getQuotePdf = async (
    companyId: InsuranceCompanyEnum,
    planId: string
  ): Promise<string> => {
    const response = await get(`quotePdf/${companyId}/${planId}`);
    return response.data;
  };

  const getProposalPdf = async (customerId: number): Promise<string> => {
    const response = await get(`${customerId}/proposalPdf`);
    return response.data;
  };

  const putTransferInsurance = async (
    request: TransferToUserRequest
  ): Promise<void> => {
    await put("TransferInsurance", request);
  };

  const getInsurancesList = async (): Promise<IGetInsurancesResponse[]> => {
    const { data } = await get("insurancesList");
    return data;
  };

  return {
    getCustomerById,
    getCustomerData,
    getDtLink,
    getProfessionalActivity,
    getProposalPdf,
    getQuote,
    getQuotePdf,
    getQuotesHistory,
    getUpdateList,
    getVehicle,
    putTransferInsurance,
    quoteResult,
    saveCustomerData,
    savePayment,
    savePlan,
    saveVehicle,
    startQuote,
    getInsurancesList,
  };
};
