import { Combo, NumberHelper } from "c4u-web-components";
import {
  IProposalDetail,
  IQuoteModelPlan,
  PaymentFormCodeEnum,
} from "../models";

type TranslateFunction = (key: string, options?: any) => string;

export class InsuranceHelper {
  static splitSelectedPlotValue(plots: string) {
    return plots.split("_").map((m) => parseInt(m));
  }

  static getProposalDetail(
    planData: IQuoteModelPlan,
    numberPlots: number,
    paymentFormCode: number,
    translate: TranslateFunction
  ): IProposalDetail {
    if (planData) {
      const paymentFormPlot = planData.paymentForm
        .find((f) => f.paymentFormCode === paymentFormCode)
        ?.plots.find((f) => f.numberPlots === numberPlots);
      if (paymentFormPlot)
        return {
          description: "",
          monthsValidity: planData.monthsValidity,
          deductible: planData.franchise,
          coverages: planData.coverages.concat([
            {
              title: planData.backupCar.title,
              description: planData.backupCar.information,
              value: 0,
              has: planData.backupCar.status,
            },
            {
              title: translate("Winch"),
              description: planData.winch.information,
              value: 0,
              has: planData.winch.status,
            },
          ]),
          firstPlotPrice: paymentFormPlot.firstPlotPrice,
          othersPlotsPrice: paymentFormPlot.othersPlotsPrice,
          interestPrice: paymentFormPlot.interestPrice,
        };
    }
    return {} as IProposalDetail;
  }

  static getDataDropdownPlots(
    typePayment: PaymentFormCodeEnum,
    planData: IQuoteModelPlan,
    translate: TranslateFunction
  ): Combo[] {
    if (planData) {
      const paymentFormFiltered = planData.paymentForm?.find(
        (f) => f.paymentFormCode === typePayment
      );
      if (paymentFormFiltered)
        return paymentFormFiltered.plots.map(
          (m) =>
            new Combo({
              title: `${m.numberPlots}x R$ ${NumberHelper.toFormatString(
                m.firstPlotPrice,
                2
              )} ${
                m.interestPrice
                  ? `${translate(
                      "Add of"
                    ).toLowerCase()} R$ ${NumberHelper.toFormatString(
                      m.interestPrice,
                      2
                    )}`
                  : translate("Interest-free").toLowerCase()
              }`,
              value: `${m.numberPlots}_${paymentFormFiltered.paymentFormCode}`,
            })
        );
    }
    return [new Combo({ title: `${translate("Loading")}...`, value: "" })];
  }
}
