import React, { useCallback, useState } from "react";
import { SpinnerCenteredAtom } from "c4u-web-components";
import { StyledIframe } from "./liberty-cc-iframe.molecule.style";
import { useEvent } from "../../../../../hooks";

interface IProps {
  paymentLink: string;
  onSubmitCC: () => void;
}

export const LibertyCcIframeMolecule: React.FC<IProps> = (props) => {
  const { onSubmitCC } = props;
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const [iframeHeight, setIframeHeight] = useState<string>("0px");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const eventHandler = useCallback(
    (event: any) => {
      if (event.origin !== process.env.REACT_APP_LIBERTY_IFRAME_ORIGIN_URL)
        return;

      const data = JSON.parse(event.data);

      if (data.eventName === "LibertyResultCardPostMessage") {
        if (data.eventValue === true) {
          setIsSubmitting(true);
          onSubmitCC();
        } else console.log("cancelado");
      } else {
        console.log("evento com outro nome");
      }
    },
    [onSubmitCC]
  );

  useEvent("message", eventHandler);

  return (
    <div className="cancel-default-align">
      {(!iframeLoaded || isSubmitting) && (
        <SpinnerCenteredAtom classNames="mt-5" />
      )}
      {!isSubmitting && (
        <StyledIframe
          name="liberty-cc-data-iframe"
          src={props.paymentLink}
          width="100%"
          height={iframeHeight}
          onLoad={() => {
            setIframeHeight("700px");
            setIframeLoaded(true);
          }}
        ></StyledIframe>
      )}
    </div>
  );
};
