import {
  ICreatePasswordlessRequest,
  StorageConstant,
  TopPasswordlessKbbAtom,
} from "c4u-web-components";
import { parseISO } from "date-fns";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { BaseTemplate } from "..";
import { Definition, paths } from "../../../constants";
import { useSessionContext } from "../../../hooks";
import { ParamsModel } from "../../../models";
import { FeedbackModal } from "../../molecules";

export const PasswordlessTemplate: FunctionComponent = (props) => {
  const { t } = useTranslation();
  const { replace, location } = useHistory();
  const params = useParams<ParamsModel>();

  const {
    usernamePawordlessContext,
    setUsernamePawordlessContext,
    genericModalMsg,
    genericErrorModal,
    hideGenericErrorModal,
    genericWarningModal,
    hideGenericWarningModal,
  } = useSessionContext();

  const isPasswordless = useMemo(
    () => params.typeJourney === Definition.Passwordless,
    [params]
  );

  useEffect(() => {
    if (!isPasswordless) replace(paths.newQuote());

    if (
      isPasswordless &&
      location.pathname !== paths.insuranceRegister(params.typeJourney)
    ) {
      const dateSession = sessionStorage.getItem(
        StorageConstant.PasswordlessExpire
      );
      if (!dateSession || parseISO(dateSession) < new Date())
        replace(paths.insuranceRegister(params.typeJourney));
    }
  }, [isPasswordless, params, replace, location]);

  const userPasswordlessData = useMemo<ICreatePasswordlessRequest>(() => {
    const storage = sessionStorage.getItem(
      StorageConstant.PasswordlessEmailCellphone
    );
    if (storage) return JSON.parse(storage);
  }, []);

  useEffect(() => {
    if (!usernamePawordlessContext.email && userPasswordlessData)
      setUsernamePawordlessContext(userPasswordlessData);
  }, [userPasswordlessData, setUsernamePawordlessContext]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseTemplate>
      <TopPasswordlessKbbAtom />
      <Container>{props.children}</Container>
      <FeedbackModal
        type={"error"}
        show={genericErrorModal}
        onHide={hideGenericErrorModal}
        title={t("Error")}
        content={genericModalMsg ?? t("GenericErrorMsg")}
      />
      <FeedbackModal
        type={"warning"}
        show={genericWarningModal}
        onHide={hideGenericWarningModal}
        title={t("Warning")}
        content={genericModalMsg ?? t("GenericWarningMsg")}
      />
    </BaseTemplate>
  );
};
