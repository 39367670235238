import { TypesHelper } from "c4u-web-components";
import {
  AddressTypeEnum,
  BonusClassViewModel,
  DocumentTypeEnum,
  GenderEnum,
  ICustomerViewModel,
  InsuranceCompanyEnum,
  MaritalStatusEnum,
  NationalityEnum,
  PoliticallyExposedPersonEnum,
  RelationshipTypeEnum,
} from "../..";

export class PostCustomerRequest {
  customer: CustomerViewRequest;
  constructor(customerData: ICustomerViewModel) {
    this.customer = new CustomerViewRequest(customerData);
  }
}

export class CustomerViewRequest {
  id: number;

  company?: InsuranceCompanyEnum | null;
  country?: string | null;
  planId?: string | null;
  birthDate?: Date | null;

  overnightAtDifferentZipCode?: boolean | null;
  overnightZipCode?: string | null;
  businessPurposes?: boolean | null;
  driverAgeBetween18And25?: boolean | null;
  driverGenderBetween18And25?: string | null;

  name?: string | null;
  socialName?: string | null;
  hasSocialName?: boolean | null;
  email?: string | null;
  cpf?: string | null;
  professionalActivity?: string | null;
  customerGender?: GenderEnum | null;
  maritalStatus?: MaritalStatusEnum | null;
  nationality?: NationalityEnum | null;
  phoneNumber?: string | null;

  mailingAddressZipCode?: string | null;
  mailingAddressType?: AddressTypeEnum | null;
  mailingAddressStreet?: string | null;
  mailingAddressNumber?: string | null;
  mailingAddressComplement?: string | null;
  mailingAddressNeighborhood?: string | null;
  mailingAddressCity?: string | null;
  mailingAddressState?: string | null;

  customerIncome?: number | null;

  politicallyExposedPerson?: PoliticallyExposedPersonEnum | null;
  politicallyExposedRelativeName?: string | null;
  politicallyExposedRelativeCpf?: string | null;
  politicallyExposedRelativeRelationshipType?: RelationshipTypeEnum | null;
  politicallyExposedRelativeEmail?: string | null;
  politicallyExposedRelativePhoneNumber?: string | null;
  politicallyExposedRelativeAddressZipCode?: string | null;
  politicallyExposedRelativeAddressType?: AddressTypeEnum | null;
  politicallyExposedRelativeAddressStreet?: string | null;
  politicallyExposedRelativeAddressNumber?: string | null;
  politicallyExposedRelativeAddressComplement?: string | null;
  politicallyExposedRelativeAddressNeighborhood?: string | null;
  politicallyExposedRelativeAddressCity?: string | null;
  politicallyExposedRelativeAddressState?: string | null;

  identityType?: DocumentTypeEnum | null;
  identityNumber?: string | null;
  identityIssuingAgency?: string | null;
  identityIssuingDate?: Date | null;

  cnhNumber?: string | null;
  cnhIssuingAgency?: string | null;
  cnhIssuingDate?: Date | null;
  cnhCategory?: string | null;

  molicarCode?: string | null;
  plate?: string | null;
  plateUf?: string | null;
  color?: string | null;
  chassi?: string | null;
  renavam?: string | null;
  isZeroKm?: boolean | null;
  modelYear?: number | null;
  manufactureYear?: number | null;

  invoiceNumber?: string | null;
  invoiceValue?: number | null;
  invoiceEmissionDate?: Date | null;
  invoiceDealershipCode?: string | null;
  invoiceDealershipVehicleLeaveDate?: Date | null;

  vehicleName: string | null;

  bonusClass: BonusClassViewModel | null;

  constructor({
    id,

    company,
    country,
    planId,

    overnightAtDifferentZipCode,
    overnightZipCode,
    businessPurposes,
    driverAgeBetween18And25,
    driverGenderBetween18And25,

    name,
    socialName,
    hasSocialName,
    email,
    cpf,
    birthDate,
    professionalActivity,
    customerGender,
    maritalStatus,
    nationality,
    phoneNumber,

    mailingAddressZipCode,
    mailingAddressType,
    mailingAddressStreet,
    mailingAddressNumber,
    mailingAddressComplement,
    mailingAddressNeighborhood,
    mailingAddressCity,
    mailingAddressState,

    customerIncome,

    politicallyExposedPerson,
    politicallyExposedRelativeName,
    politicallyExposedRelativeCpf,
    politicallyExposedRelativeRelationshipType,
    politicallyExposedRelativeEmail,
    politicallyExposedRelativePhoneNumber,
    politicallyExposedRelativeAddressZipCode,
    politicallyExposedRelativeAddressType,
    politicallyExposedRelativeAddressStreet,
    politicallyExposedRelativeAddressNumber,
    politicallyExposedRelativeAddressComplement,
    politicallyExposedRelativeAddressNeighborhood,
    politicallyExposedRelativeAddressCity,
    politicallyExposedRelativeAddressState,

    identityType,
    identityNumber,
    identityIssuingAgency,
    identityIssuingDate,

    cnhNumber,
    cnhIssuingAgency,
    cnhIssuingDate,
    cnhCategory,

    molicarCode,
    plate,
    plateUf,
    color,
    chassi,
    renavam,
    isZeroKm,
    modelYear,
    manufactureYear,

    invoiceNumber,
    invoiceValue,
    invoiceEmissionDate,
    invoiceDealershipCode,
    invoiceDealershipVehicleLeaveDate,

    vehicleName,
    bonusClass,
  }: ICustomerViewModel) {
    this.id = id;

    this.company = TypesHelper.toNumberNull(company);
    this.country = TypesHelper.toStringNull(country);
    this.planId = TypesHelper.toStringNull(planId);

    this.overnightAtDifferentZipCode = TypesHelper.toBooleanNull(
      overnightAtDifferentZipCode
    );
    this.overnightZipCode =
      TypesHelper.toStringOnlyNumbersNull(overnightZipCode);
    this.businessPurposes = TypesHelper.toBooleanNull(businessPurposes);
    this.driverAgeBetween18And25 = TypesHelper.toBooleanNull(
      driverAgeBetween18And25
    );

    this.driverGenderBetween18And25 = !!driverGenderBetween18And25
      ? (driverGenderBetween18And25 as string[]).join("|")
      : null;

    this.name = TypesHelper.toStringNull(name);
    this.socialName = TypesHelper.toStringNull(socialName);
    this.hasSocialName = TypesHelper.toBooleanNull(hasSocialName);
    this.birthDate = TypesHelper.toDateNull(birthDate);
    this.email = TypesHelper.toStringNull(email);
    this.cpf = TypesHelper.toStringOnlyNumbersNull(cpf);
    this.professionalActivity = TypesHelper.toStringNull(professionalActivity);
    this.customerGender = TypesHelper.toNumberNull(customerGender);
    this.maritalStatus = TypesHelper.toNumberNull(maritalStatus);
    this.nationality = TypesHelper.toNumberNull(nationality);
    this.phoneNumber = TypesHelper.toStringOnlyNumbersNull(phoneNumber);

    this.mailingAddressZipCode = TypesHelper.toStringOnlyNumbersNull(
      mailingAddressZipCode
    );
    this.mailingAddressType = TypesHelper.toNumberNull(mailingAddressType);
    this.mailingAddressStreet = TypesHelper.toStringNull(mailingAddressStreet);
    this.mailingAddressNumber = TypesHelper.toStringNull(mailingAddressNumber);
    this.mailingAddressComplement = TypesHelper.toStringNull(
      mailingAddressComplement
    );
    this.mailingAddressNeighborhood = TypesHelper.toStringNull(
      mailingAddressNeighborhood
    );
    this.mailingAddressCity = TypesHelper.toStringNull(mailingAddressCity);
    this.mailingAddressState = TypesHelper.toStringNull(mailingAddressState);

    this.customerIncome = TypesHelper.toNumberNull(customerIncome);

    this.politicallyExposedPerson = TypesHelper.toNumberNull(
      politicallyExposedPerson
    );
    this.politicallyExposedRelativeName = TypesHelper.toStringNull(
      politicallyExposedRelativeName
    );
    this.politicallyExposedRelativeCpf = TypesHelper.toStringOnlyNumbersNull(
      politicallyExposedRelativeCpf
    );
    this.politicallyExposedRelativeRelationshipType = TypesHelper.toNumberNull(
      politicallyExposedRelativeRelationshipType
    );
    this.politicallyExposedRelativeEmail = TypesHelper.toStringNull(
      politicallyExposedRelativeEmail
    );
    this.politicallyExposedRelativePhoneNumber =
      TypesHelper.toStringOnlyNumbersNull(
        politicallyExposedRelativePhoneNumber
      );
    this.politicallyExposedRelativeAddressZipCode =
      TypesHelper.toStringOnlyNumbersNull(
        politicallyExposedRelativeAddressZipCode
      );
    this.politicallyExposedRelativeAddressType = TypesHelper.toNumberNull(
      politicallyExposedRelativeAddressType
    );
    this.politicallyExposedRelativeAddressStreet = TypesHelper.toStringNull(
      politicallyExposedRelativeAddressStreet
    );
    this.politicallyExposedRelativeAddressNumber = TypesHelper.toStringNull(
      politicallyExposedRelativeAddressNumber
    );
    this.politicallyExposedRelativeAddressComplement = TypesHelper.toStringNull(
      politicallyExposedRelativeAddressComplement
    );
    this.politicallyExposedRelativeAddressNeighborhood =
      TypesHelper.toStringNull(politicallyExposedRelativeAddressNeighborhood);
    this.politicallyExposedRelativeAddressCity = TypesHelper.toStringNull(
      politicallyExposedRelativeAddressCity
    );
    this.politicallyExposedRelativeAddressState = TypesHelper.toStringNull(
      politicallyExposedRelativeAddressState
    );

    this.identityType = TypesHelper.toNumberNull(identityType);
    this.identityNumber = TypesHelper.toStringNull(identityNumber);
    this.identityIssuingAgency = TypesHelper.toStringNull(
      identityIssuingAgency
    );
    this.identityIssuingDate = TypesHelper.toDateNull(identityIssuingDate);

    this.cnhNumber = TypesHelper.toStringOnlyNumbersNull(cnhNumber);
    this.cnhIssuingAgency = TypesHelper.toStringNull(cnhIssuingAgency);
    this.cnhIssuingDate = TypesHelper.toDateNull(cnhIssuingDate);
    this.cnhCategory = TypesHelper.toStringNull(cnhCategory);

    this.molicarCode = TypesHelper.toStringNull(molicarCode);
    this.plate = TypesHelper.toStringNull(plate);
    this.plateUf = TypesHelper.toStringNull(plateUf);
    this.color = TypesHelper.toStringNull(color);
    this.chassi = TypesHelper.toStringNull(chassi);
    this.renavam = TypesHelper.toStringNull(renavam);
    this.isZeroKm = TypesHelper.toBooleanNull(isZeroKm);
    this.modelYear = TypesHelper.toNumberNull(modelYear);
    this.manufactureYear = TypesHelper.toNumberNull(manufactureYear);

    this.invoiceNumber = TypesHelper.toStringNull(invoiceNumber);
    this.invoiceValue = TypesHelper.toNumberNull(invoiceValue);
    this.invoiceEmissionDate = TypesHelper.toDateNull(invoiceEmissionDate);
    this.invoiceDealershipCode = TypesHelper.toStringNull(
      invoiceDealershipCode
    );
    this.invoiceDealershipVehicleLeaveDate = TypesHelper.toDateNull(
      invoiceDealershipVehicleLeaveDate
    );
    this.vehicleName = TypesHelper.toStringNull(vehicleName);

    this.bonusClass = bonusClass ? new BonusClassViewModel(bonusClass) : null;
  }
}
