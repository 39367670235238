import { CoxIcon } from "c4u-web-components";
import React from "react";
import {
  Franchise,
  SubTitle,
  Title,
  WrapperQuoteItem,
} from "./quote-item.atom.style";

interface IProps {
  title: string;
  description?: string;
  has: boolean;
}

export const QuoteItemCoverageAtom: React.FC<IProps> = (props) => {
  return (
    <WrapperQuoteItem>
      <Title>{props.title}</Title>
      <Franchise>
        {props.has ? (
          <CoxIcon name={"checked"} width={18} height={18} />
        ) : (
          <CoxIcon name={"denied"} width={13} height={13} />
        )}
      </Franchise>
      <SubTitle>{props.description ?? ""}</SubTitle>
    </WrapperQuoteItem>
  );
};
