import { ButtonSecondary } from "c4u-web-components";
import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Definition, paths } from "../../../../constants";
import { IPasswordlessParams } from "../../../../models";
import { BreadcrumbsAtom } from "../../../atoms";
import {
  AllCapsButtonTertiary,
  HeaderTitleWrapper,
  TitleDiv,
} from "./header-title.molecule.style";

interface IProps {
  title: string;
  description?: React.ReactNode;
  navigationButtonText?: string;
  navigationButtonAction?: () => void;
  returnButtonActionOverride?: () => void;
  disableReturnButton?: boolean;
  breadcrumbsBackgroundColor?: string;
  alignLeft?: boolean;
}

export const HeaderTitleMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { typeJourney } = useParams<IPasswordlessParams>();

  const breadcrumbs = [
    { text: t("Home"), path: paths.myQuotes(typeJourney) },
    { text: props.title },
  ];

  const isPasswordless = useMemo(
    () => typeJourney === Definition.Passwordless,
    [typeJourney]
  );

  const hideReturnButton = !!props.disableReturnButton;
  const addDefaultAlign = hideReturnButton && !props.alignLeft;

  return (
    <HeaderTitleWrapper>
      {!isPasswordless && (
        <BreadcrumbsAtom
          breadcrumbs={breadcrumbs}
          backgroundColor={props.breadcrumbsBackgroundColor}
        />
      )}
      <div className={addDefaultAlign ? "default-align" : ""}>
        <Row>
          {!hideReturnButton && (
            <Col md={"auto"} className="center-align-vertical">
              <ButtonSecondary
                sizex={"sm"}
                sizey={"thin"}
                onClick={
                  props.returnButtonActionOverride ?? (() => history.go(-1))
                }
                disabled={!!props.disableReturnButton}
              >
                {t("Back")}
              </ButtonSecondary>
            </Col>
          )}
          <Col md className={addDefaultAlign ? "" : "my-4 my-md-0"}>
            <TitleDiv>{props.title}</TitleDiv>
            {props.description !== undefined && props.description}
          </Col>
          <Col md={"auto"} className="text-right center-align-vertical">
            {props.navigationButtonText && props.navigationButtonAction && (
              <AllCapsButtonTertiary
                sizex={"md"}
                sizey={"thin"}
                onClick={props.navigationButtonAction}
              >
                {props.navigationButtonText}
              </AllCapsButtonTertiary>
            )}
          </Col>
        </Row>
      </div>
    </HeaderTitleWrapper>
  );
};
