import {
  ButtonPrimary,
  Combo,
  CreditcardCompany,
  CreditcardCompanyEnum,
} from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormikControlTranslatedAtom } from "../../../../atoms";

interface IProps {
  dataDropdownPlots: Combo[];
  formik: any;
}

export const FormPaymentPortoBaseMolecule: React.FC<IProps> = (parameters) => {
  const { t } = useTranslation();

  const [flag, setFlag] = useState(CreditcardCompanyEnum.Mastercard);
  const [props, setProps] = useState(parameters);

  useEffect(() => {
    props.formik.setFieldValue("creditcardFlag", flag);
    // eslint-disable-next-line
  }, [flag]);

  useEffect(() => {
    if (props.dataDropdownPlots?.length)
      props.formik.setFieldValue("plots", props.dataDropdownPlots[0].value);
    // eslint-disable-next-line
  }, [props.dataDropdownPlots]);

  useEffect(() => {
    setProps(parameters);
  }, [parameters]);

  return (
    <>
      <Form.Row>
        <FormikControlTranslatedAtom
          md={3}
          as={"select"}
          formik={props.formik}
          property={"plots"}
          label={t("Plots amount")}
        >
          {props.dataDropdownPlots.map((m, i) => (
            <option value={m.value} key={i}>
              {m.title}
            </option>
          ))}
        </FormikControlTranslatedAtom>
      </Form.Row>
      <Form.Row className={"mb-2"}>
        <Col lg={3}>
          <Row>
            <Col className={"mb-2"}>
              <label className="form-label">{t("Creditcard flag")}</label>
              <CreditcardCompany
                onChange={(f: CreditcardCompanyEnum) => setFlag(f)}
                filter={[
                  CreditcardCompanyEnum.Visa,
                  CreditcardCompanyEnum.Mastercard,
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Form.Row>

      {props.children}

      <Form.Row>
        <Form.Group as={Col} md className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={props.formik.isSubmitting}
          >
            {t("Continue")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </>
  );
};
