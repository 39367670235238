import { IPagedResponseBaseModel } from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../constants";
import { useInsuranceContext, useSessionContext } from "../../../hooks";
import {
  IGetQuotesResponse,
  IInsuranceQuoteData,
  IPasswordlessParams,
} from "../../../models";
import { HeaderWithSearchOrganism, MyQuotesOrganism } from "../../organisms";

export const MyQuotesPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { typeJourney } = useParams<IPasswordlessParams>();
  const { selectedDealershipContext } = useSessionContext();
  const { clearInsuranceContexts } = useInsuranceContext();

  const [quotesHistory, setQuotesHistory] = useState<IInsuranceQuoteData[]>();
  const [paginationData, setPaginationData] =
    useState<IPagedResponseBaseModel>();
  const [pageNumber, setPageNumber] = useState<number>();

  const isPasswordless = useMemo(
    () => typeJourney === Definition.Passwordless,
    [typeJourney]
  );

  const redirectToNewQuotePage = useCallback(() => {
    clearInsuranceContexts();
    history.push(paths.newQuote(typeJourney));
  }, [history, clearInsuranceContexts, typeJourney]);

  const setQuotesHistoryData = useCallback(
    (responseData: IGetQuotesResponse | undefined) => {
      if (responseData === undefined) {
        setQuotesHistory(undefined);
        setPaginationData(undefined);
        return;
      }

      const { data, ...paginationData } = responseData;
      setQuotesHistory(data);
      setPaginationData(paginationData);
    },
    []
  );

  useEffect(() => {
    setPageNumber(undefined);
  }, [selectedDealershipContext]);

  return (
    <>
      <HeaderWithSearchOrganism
        title={t("MyQuotesPageTitle")}
        setQuotesHistoryData={setQuotesHistoryData}
        disableFormSubmit={quotesHistory === undefined}
        navigationButtonText={t("NewQuote")}
        navigationButtonAction={redirectToNewQuotePage}
        disableReturnButton
        pageNumber={pageNumber}
        isPasswordless={isPasswordless}
        selectedDealership={selectedDealershipContext}
      />
      <MyQuotesOrganism
        quotesHistory={quotesHistory}
        paginationData={paginationData}
        setPageNumber={setPageNumber}
      />
    </>
  );
};
