import styled from "styled-components";

interface IProps {
  active: boolean;
}

export const WrapperQuote = styled.div<IProps>`
  border: 1px solid ${(p) => p.theme.colors.DarkGrayOpacity};
  box-sizing: border-box;
  border-radius: 10px;
  width: 255px;
  overflow: ${(p) => (p.active ? "hidden" : "inicial")};
  .RowQuotes:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.colors.DarkGrayOpacity};
  }
  .Differentials .ColQuotes {
    align-items: flex-start !important;
  }
  margin: auto;
  height: 100%;

  position: relative;
  .overlay-full-diff {
    max-height: 407px;
    height: 100%;
    position: absolute;
    top: 52px;
    left: 0;
    right: 0;
  }
  .white-background {
    background: #fff;
    z-index: 10;
  }
  .click-through {
    pointer-events: none;
  }
  .clickable {
    pointer-events: auto;
  }
`;

export const CompanyLogo = styled.div`
  min-height: 52px;
  border-bottom: 1px solid ${(p) => p.theme.colors.DarkGrayOpacity};
  display: grid;
  align-items: center;
  justify-content: center;
`;

export const RowQuotes = styled.div`
  min-height: 66px;
  &.half-height {
    min-height: 33px;
  }
  display: flex;
  .ColQuotes:first-child {
    border-right: 1px solid ${(p) => p.theme.colors.DarkGrayOpacity};
  }
`;

export const ColQuotes = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: grid;
  padding: 10px;
`;

export const RowButtons = styled.div`
  height: 33px;
  display: flex;
  .ColButtons:first-child {
    border-right: 1px solid ${(p) => p.theme.colors.DarkGrayOpacity};
  }
`;

export const ColButtons = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  background: ${(props) =>
    props.active ? props.theme.colors.Confirm : props.theme.colors.BlueNavy};

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 10px;
  line-height: 44px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.white};
`;

export const NoCotation = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;

  display: grid;
  align-items: center;
  text-align: center;

  color: ${(p) => p.theme.colors.BlueNavy};

  min-height: 300px;
  height: calc(100% - 52px);

  padding: 70px;
`;

export const Soon = styled.div`
  min-height: 300px;
  height: calc(100% - 52px);
  align-items: center;
  display: flex;
  margin: 0 0 0 -8px;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  max-height: 100%;

  padding-right: 6px;
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #cdcdcd #f0f0f0;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #cdcdcd;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 5px;
  }

  @media (max-width: 767px) {
    padding-right: 0;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    ::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }
`;
