import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { InsuranceStepsEnum, IPasswordlessParams } from "../../../models";
import { HeaderStepsMolecule, HeaderTitleMolecule } from "../../molecules";

interface IProps {
  currentStep: InsuranceStepsEnum;
  disableReturnButton?: boolean;
  returnButtonActionOverride?: () => void;
  hideNavigationButton?: boolean;
  description?: React.ReactNode;
}

export const HeaderWithStepsOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { typeJourney } = useParams<IPasswordlessParams>();

  return (
    <>
      <HeaderTitleMolecule
        title={t("InsurancePagesTitle")}
        navigationButtonAction={() => history.push(paths.myQuotes(typeJourney))}
        navigationButtonText={
          props.hideNavigationButton ? undefined : t("My quotes")
        }
        returnButtonActionOverride={props.returnButtonActionOverride}
        disableReturnButton={props.disableReturnButton}
        description={props.description}
      />
      <HeaderStepsMolecule currentStep={props.currentStep} />
    </>
  );
};
