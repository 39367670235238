import { DateHelper, SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { HeaderWithStepsOrganism } from "..";
import { Definition, paths } from "../../../constants";
import { useInsurance, useInsuranceContext, useKbb } from "../../../hooks";
import {
  ICustomerViewModel,
  ICustomerViewModelUrlParams,
  OriginEnum,
  PostCustomerRequest,
} from "../../../models";
import { ContactDetailsAtom } from "../../atoms";
import { FinalDataMolecule } from "../../molecules";
import { FinalDataWrapper } from "./final-data.organism.style";

interface IProps {
  title?: string;
  nextPage?: string;
}

export const FinalDataOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const history = useHistory();
  const params = useParams<ICustomerViewModelUrlParams>();
  const { vehicleContext, setVehicleContext } = useInsuranceContext();
  const { getCustomerData, getDtLink, saveCustomerData } = useInsurance();
  const { GetVehicle } = useKbb();
  const { origin, setOrigin, setQuotesContext } = useInsuranceContext();

  const [initialValues, setInitialValues] = useState<ICustomerViewModel>();
  const [dtLink, setDtLink] = useState<string>();

  useEffect(() => {
    if (vehicleContext?.id || vehicleContext?.molicarID) {
      GetVehicle(
        vehicleContext.id,
        vehicleContext.molicarID,
        vehicleContext.year
      );
      setVehicleContext(vehicleContext);
    }
    // eslint-disable-next-line
  }, [vehicleContext, initialValues]);

  const getDriverGenderBetween18And25 = (
    driverGenderBetween18And25: string | string[] | undefined
  ) => {
    if (!driverGenderBetween18And25) return "";

    return typeof driverGenderBetween18And25 === "string"
      ? driverGenderBetween18And25.split("|")
      : driverGenderBetween18And25;
  };

  const getYesNoStringValues = (value: string | boolean | undefined) => {
    if (value === undefined) return "";
    return value ? "1" : "0";
  };

  const getNotNullProps = useCallback((obj: any) => {
    const newObj: { [key: string]: any } = {};

    for (const [k, v] of Object.entries(obj)) {
      if (v !== null) newObj[k] = v;
    }

    return newObj;
  }, []);

  useEffect(() => {
    getCustomerData(params).then(({ customer }) => {
      getDtLink(customer.id).then((url) => setDtLink(url));
      setOrigin(customer.origin);
      const notNullCustomerProps = getNotNullProps(customer);
      setInitialValues({
        ...notNullCustomerProps,
        hasSocialName: getYesNoStringValues(
          notNullCustomerProps.hasSocialName
        ),
        overnightAtDifferentZipCode: getYesNoStringValues(
          notNullCustomerProps.overnightAtDifferentZipCode
        ),
        businessPurposes: getYesNoStringValues(
          notNullCustomerProps.businessPurposes
        ),
        driverAgeBetween18And25: getYesNoStringValues(
          notNullCustomerProps.driverAgeBetween18And25
        ),
        birthDate: DateHelper.dateFormat(notNullCustomerProps.birthDate),
        identityIssuingDate: DateHelper.dateFormat(
          notNullCustomerProps.identityIssuingDate
        ),
        cnhIssuingDate: DateHelper.dateFormat(
          notNullCustomerProps.cnhIssuingDate
        ),
        invoiceEmissionDate: DateHelper.dateFormat(
          notNullCustomerProps.invoiceEmissionDate
        ),
        invoiceDealershipVehicleLeaveDate: DateHelper.dateFormat(
          notNullCustomerProps.invoiceDealershipVehicleLeaveDate
        ),
        driverGenderBetween18And25: getDriverGenderBetween18And25(
          notNullCustomerProps.driverGenderBetween18And25
        ),
        bonusClass: !notNullCustomerProps.bonusClass
          ? undefined
          : {
              ...notNullCustomerProps.bonusClass,
              startDate: DateHelper.dateFormat(
                notNullCustomerProps.bonusClass.startDate
              ),
              endDate: DateHelper.dateFormat(
                notNullCustomerProps.bonusClass.endDate
              ),
            },
      } as ICustomerViewModel);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const vehicleNameFormat = `${vehicleContext?.brandName} ${vehicleContext?.modelName} ${vehicleContext?.versionName} ${vehicleContext?.hp} Cv - ${vehicleContext?.year}`;

  const submit = async (values: ICustomerViewModel) => {
    if (initialValues !== undefined) {
      await saveCustomerData(
        new PostCustomerRequest({
          ...values,
          vehicleName:
            vehicleNameFormat ??
            `${initialValues.molicarCode} - ${t("Not found")}`,
        })
      );
      setQuotesContext(undefined);
      history.push(paths.quotes(initialValues.id, params.typeJourney));
    }
  };

  const isSingleJourney = useMemo(() => origin !== OriginEnum.Dt, [origin]);
  const isPasswordless = useMemo(
    () => params.typeJourney === Definition.Passwordless,
    [params]
  );

  return (
    <>
      <HeaderWithStepsOrganism
        currentStep={isPasswordless ? 3 : 1}
        disableReturnButton={
          dtLink === undefined && !isSingleJourney && !isPasswordless
        }
        returnButtonActionOverride={() => {
          if (isPasswordless || isSingleJourney) history.goBack();
          else if (dtLink !== undefined) window.location.href = dtLink;
        }}
        description={<ContactDetailsAtom />}
      />
      <FinalDataWrapper className={"default-align"}>
        {initialValues === undefined ? (
          <SpinnerCenteredAtom classNames="mt-5" />
        ) : (
          <FinalDataMolecule onSubmit={submit} initialValues={initialValues} />
        )}
      </FinalDataWrapper>
    </>
  );
};
