import {
  ButtonTertiary,
  Combo,
  CreditcardCompanyEnum,
} from "c4u-web-components";
import { FormikHelpers } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import { InsuranceHelper } from "../../../../helper";
import {
  useInsurance,
  useInsuranceContext,
  useSessionContext,
  useZipcodeAddress,
} from "../../../../hooks";
import {
  CreditCardFlagEnum,
  IFormPaymentPortoCCMolecule,
  IFormPaymentPortoOthersMolecule,
  IFormPaymentPortoOwnMolecule,
  IPaymentRequest,
  IQuoteModelPlan,
  OriginEnum,
  ParamsModel,
  PaymentFormCodeEnum,
  PaymentRequest,
} from "../../../../models";
import {
  FeedbackModal,
  FormPaymentPortoCCMolecule,
  FormPaymentPortoOthersMolecule,
  FormPaymentPortoOwnMolecule,
} from "../../../molecules";
import { CreditCardButton, WrapperPorto } from "./payment-porto.organism.style";

interface IProps {
  title?: string;
  selectedPlanData: IQuoteModelPlan;
  origin: OriginEnum;
}

export const PaymentPortoOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { handleFormikException, handleEventGTM } = useSessionContext();
  const { getSelectedPlanContext, quotesContext } = useInsuranceContext();

  const { savePayment } = useInsurance();
  const { getAddressByZipcode } = useZipcodeAddress();

  const { id: idParam, typeJourney } = useParams<ParamsModel>();

  const [formPortoCC, setPortoCC] = useState(false);
  const [formOwnCC, setOnwCC] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleCCChoose = () => {
    if (dataDropdownPlotsOthersCC.filter((f) => !!f.value).length === 0) return;
    setPortoCC(false);
  };
  const handleCCPortoChoose = () => {
    if (dataDropdownPlotsPortoCC.filter((f) => !!f.value).length === 0) return;
    setPortoCC(true);
  };

  const dataRadioCardholder = useMemo(() => {
    return [
      new Combo({ title: t("Yes"), value: "1" }),
      new Combo({ title: t("No"), value: "0" }),
    ];
  }, [t]);

  const dataDropdownPlotsPortoCC = useMemo(() => {
    return InsuranceHelper.getDataDropdownPlots(
      PaymentFormCodeEnum.CreditCardPortoSeguro,
      props.selectedPlanData,
      t
    );
  }, [props, t]);

  const dataDropdownPlotsOthersCC = useMemo(() => {
    return InsuranceHelper.getDataDropdownPlots(
      PaymentFormCodeEnum.CreditCard,
      props.selectedPlanData,
      t
    );
  }, [props, t]);

  const fromToCC = (flag: string) => {
    switch (flag) {
      case CreditcardCompanyEnum.Visa:
        return CreditCardFlagEnum.Visa;

      case CreditcardCompanyEnum.Mastercard:
        return CreditCardFlagEnum.Mastercard;

      default:
        return CreditCardFlagEnum.Visa;
    }
  };

  const handleGtmPurchase = useCallback(
    (planId: string) => {
      const selectedPlanId = quotesContext?.quotes?.find(
        (f) => !!f.plans.find((f) => f.planId === planId)
      );
      if (!selectedPlanId) return;

      const selectedQuotesModule = { ...selectedPlanId };

      const selectedIndex = selectedQuotesModule?.plans.findIndex(
        (plan) => plan.planId === planId
      );
      selectedQuotesModule.plans = selectedQuotesModule?.plans.filter(
        (f) => f.planId === planId
      );

      handleEventGTM({
        event: "purchase",
        ecommerce: {
          transaction_id: `${idParam}`,
          value: `${selectedQuotesModule.plans.map(
            (quote) => quote.annualPrice
          )}`,
          currency: "BRL",
          items: {
            item_name:
              selectedQuotesModule.companyName === t("ErrorApi")
                ? t("notAvailable")
                : selectedQuotesModule.companyName,
            item_id: selectedQuotesModule.plans.map((quote) => quote.planId),
            price: selectedQuotesModule.plans.map((quote) => quote.annualPrice),
            currency: "BRL",
            affiliation:
              selectedQuotesModule.companyName === t("ErrorApi")
                ? t("notAvailable")
                : selectedQuotesModule.companyName,
            item_category: "Seguros Auto",
            item_list_name: "Pagamento",
            index: selectedIndex,
            quantity: 1,
          },
        },
      });
    },
    [handleEventGTM, idParam, quotesContext?.quotes, t]
  );

  const handleSubmit = async (
    values: PaymentRequest,
    formikHelpers: FormikHelpers<any>
  ) => {
    try {
      await savePayment(values);
      setConfirmModal(true);
    } catch (e) {
      handleFormikException(
        e,
        formikHelpers.setErrors,
        t("PaymentFailed"),
        t("InvalidCreditCard")
      );
    }
  };

  const eventPurchase = useCallback(() => {
    const planData = getSelectedPlanContext();
    const plan = {
      id: planData?.planId,
    };

    if (plan.id) handleGtmPurchase(plan.id);
  }, [getSelectedPlanContext, handleGtmPurchase]);

  const handleSubmitFormCC = async (
    values: IFormPaymentPortoCCMolecule,
    formikHelpers: FormikHelpers<IFormPaymentPortoCCMolecule>
  ) => {
    const [numberPlots, paymentFormCode] =
      InsuranceHelper.splitSelectedPlotValue(values.plots);
    const request = new PaymentRequest({
      customerId: idParam,
      numberInstallments: numberPlots,
      paymentFormCode: paymentFormCode,
      creditCard: {
        flagCard: fromToCC(values.creditcardFlag),
      },
      proposalDetail: InsuranceHelper.getProposalDetail(
        props.selectedPlanData,
        numberPlots,
        paymentFormCode,
        t
      ),
    });

    await handleSubmit(request, formikHelpers);
    eventPurchase();
  };

  const handleSubmitFormOwn = async (
    values: IFormPaymentPortoOwnMolecule,
    formikHelpers: FormikHelpers<IFormPaymentPortoOwnMolecule>
  ) => {
    const [numberPlots, paymentFormCode] =
      InsuranceHelper.splitSelectedPlotValue(values.plots);
    const request: IPaymentRequest = {
      customerId: idParam,
      numberInstallments: numberPlots,
      paymentFormCode: paymentFormCode,
      creditCard: {
        flagCard: fromToCC(values.creditcardFlag),
        cvv: values.creditcardCvv,
        month: values.creditcardMonth,
        numberCard: values.creditcardNumber,
        name: values.creditcardName,
        year: values.creditcardYear,
      },
      proposalDetail: InsuranceHelper.getProposalDetail(
        props.selectedPlanData,
        numberPlots,
        paymentFormCode,
        t
      ),
    };

    await handleSubmit(new PaymentRequest(request), formikHelpers);
    eventPurchase();
  };

  const handleSubmitFormOthers = async (
    values: IFormPaymentPortoOthersMolecule,
    formikHelpers: FormikHelpers<IFormPaymentPortoOthersMolecule>
  ) => {
    const [numberPlots, paymentFormCode] =
      InsuranceHelper.splitSelectedPlotValue(values.plots);
    const request: IPaymentRequest = {
      customerId: idParam,
      numberInstallments: numberPlots,
      paymentFormCode: paymentFormCode,
      email: values.email,
      phone: values.phone,
      creditCard: {
        flagCard: fromToCC(values.creditcardFlag),
        cvv: Number(values.creditcardCvv),
        month: values.creditcardMonth,
        numberCard: values.creditcardNumber,
        name: values.creditcardName,
        year: values.creditcardYear,
        birthDate: values.birthdate,
        cpfCnpj: values.document,
        maritalStatus: values.maritalStatus,
        gender: values.gender,
        ramal: values.ramal,
        observation: values.observation,
        invoiceAddress: {
          addressType: values.addressType,
          zipCode: values.zipcode,
          street: values.street,
          number: values.number,
          complement: values.complement,
          neighborhood: values.district,
          city: values.city,
          state: values.state,
        },
      },
      proposalDetail: InsuranceHelper.getProposalDetail(
        props.selectedPlanData,
        numberPlots,
        paymentFormCode,
        t
      ),
    };

    await handleSubmit(new PaymentRequest(request), formikHelpers);
    eventPurchase();
  };

  useEffect(() => setOnwCC(true), [formPortoCC]);

  const onCloseSuccessModal = useCallback(
    () => history.push(paths.myQuotes(typeJourney)),
    [history, typeJourney]
  );

  return (
    <WrapperPorto>
      <Row className={"mt-4 header-payment-porto"}>
        <Col md={"auto"} className={"mb-2"}>
          {dataDropdownPlotsOthersCC.filter((f) => !!f.value).length > 0 && (
            <CreditCardButton onClick={handleCCChoose} active={!formPortoCC}>
              {t("Credit card")}
            </CreditCardButton>
          )}
        </Col>
        <Col md={"auto"}>
          {dataDropdownPlotsPortoCC.filter((f) => !!f.value).length > 0 && (
            <CreditCardButton
              onClick={handleCCPortoChoose}
              active={formPortoCC}
            >
              {t("Credit card porto seguro")}
            </CreditCardButton>
          )}
        </Col>
      </Row>
      <FeedbackModal
        type={"success"}
        show={confirmModal}
        onHide={onCloseSuccessModal}
        title={t("Successfully submitted form")}
        titleHeader={t("Insurance send confirmation")}
        personalButton={
          <ButtonTertiary sizex={"md"} onClick={onCloseSuccessModal}>
            {t("My quotes")}
          </ButtonTertiary>
        }
        content={
          <>
            {props.origin === OriginEnum.Dt && (
              <>
                {t("Please wait for the financing payment")}
                <br />
              </>
            )}
            {t("Insurance term is one year")}
          </>
        }
        size="md"
      />
      {formPortoCC ? (
        <FormPaymentPortoCCMolecule
          dataDropdownPlots={dataDropdownPlotsPortoCC}
          onSubmit={handleSubmitFormCC}
        />
      ) : (
        <>
          {formOwnCC ? (
            <FormPaymentPortoOwnMolecule
              onChangeOwnCC={setOnwCC}
              dataDropdownPlots={dataDropdownPlotsOthersCC}
              dataRadioCardholder={dataRadioCardholder}
              onSubmit={handleSubmitFormOwn}
            />
          ) : (
            <FormPaymentPortoOthersMolecule
              onChangeOwnCC={setOnwCC}
              dataDropdownPlots={dataDropdownPlotsOthersCC}
              dataRadioCardholder={dataRadioCardholder}
              onSubmit={handleSubmitFormOthers}
              getAddress={getAddressByZipcode}
            />
          )}
        </>
      )}
    </WrapperPorto>
  );
};
