import { useAuth0 } from "@auth0/auth0-react";
import { AxiosInstance } from "axios";
import { StorageConstant } from "c4u-web-components";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Definition, paths } from "../../../../constants";
import {
  ConflictRequestError,
  GeneralRequestError,
  IPasswordlessParams,
  RequestError,
} from "../../../../models";

export const useRequest = (
  http: AxiosInstance,
  path: string,
  headersParam: any = null
) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { replace } = useHistory();
  const { typeJourney } = useParams<IPasswordlessParams>();

  const buildUrl = useCallback(
    (url: string) => {
      return url ? `${path}/${url}` : path;
    },
    [path]
  );

  const buildHeaders = useCallback(
    async (customHeaders = {}) => {
      const token =
        typeJourney === Definition.Passwordless
          ? sessionStorage.getItem(StorageConstant.PasswordlessToken)
          : isAuthenticated
          ? await getAccessTokenSilently({
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: process.env.REACT_APP_AUTH0_SCOPE,
            })
          : localStorage.getItem("access_token");

      const headers = headersParam
        ? headersParam
        : {
            Authorization: `Bearer ${token}`,
            "Accept-Language": "pt-BR,pt;q=0.9",
          };

      return { ...headers, ...customHeaders };
    },
    [headersParam, isAuthenticated, getAccessTokenSilently, typeJourney]
  );

  const handleErrors = useCallback(
    (e: any): void => {
      if (e?.response && e.response?.status === 412) {
        throw new RequestError(e.response.data);
      } else if (e?.response && e.response?.status === 409) {
        throw new ConflictRequestError(e.response.data);
      } else if (
        e?.response &&
        e?.response?.status === 401 &&
        typeJourney === Definition.Passwordless
      ) {
        replace(paths.insuranceRegister(typeJourney));
      } else {
        console.log("General error", e);
        throw new GeneralRequestError(e);
      }
    },
    [typeJourney, replace]
  );

  const callApi = useCallback(
    async (
      url: string,
      method: string,
      config: any,
      data: any = null,
      retry: number = 1
    ) => {
      let i = 1;
      while (true) {
        const requestConfig = config;
        requestConfig.url = buildUrl(url);
        requestConfig.headers = await buildHeaders(config.headers);
        requestConfig.data = data;
        requestConfig.method = method;
        try {
          return await http.request(requestConfig);
        } catch (e) {
          if (
            (e as any)?.message === "Network Error" &&
            !(e as any)?.tryEntries?.length &&
            i++ < retry
          )
            continue;
          handleErrors(e);
          break;
        }
      }
    },
    [buildHeaders, buildUrl, handleErrors, http]
  );

  return {
    get: useCallback(
      async (url: string, config = {}, retry?: number): Promise<any> =>
        callApi(url, "GET", config, null, retry),
      [callApi]
    ),
    delete: useCallback(
      async (url: string, config = {}, retry?: number): Promise<any> =>
        callApi(url, "DELETE", config, null, retry),
      [callApi]
    ),
    put: useCallback(
      async (
        url: string,
        data: any,
        config = {},
        retry?: number
      ): Promise<any> => callApi(url, "PUT", config, data, retry),
      [callApi]
    ),
    post: useCallback(
      async (
        url: string,
        data: any,
        config = {},
        retry?: number
      ): Promise<any> => callApi(url, "POST", config, data, retry),
      [callApi]
    ),
  };
};
