export const MsgValidation = {
  RequiredField: "Required Field",
  Required: "Required",
  Invalid: "Invalid",
  Cpf: "Invalid CPF",
  Phone: "Invalid Phone",
  Email: "Invalid Email",
  Date: "Invalid Date",
  Rg: "Invalid RG"
};
