import { Combo } from "c4u-web-components";
import { FormikHelpers, useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormPaymentPortoBaseMolecule } from "../form-payment-porto-base/form-payment-porto-base.molecule";
import { FormPaymentPortoOwnMoleculeValidation } from "./form-payment-porto-own.molecule.validation";
import { FormikControlTranslatedAtom } from "../../../../atoms";
import { IFormPaymentPortoOwnMolecule } from "../../../../../models";

interface IProps {
  onChangeOwnCC: (own: boolean) => void;
  dataDropdownPlots: Combo[];
  dataRadioCardholder: Combo[];
  onSubmit: (
    values: IFormPaymentPortoOwnMolecule,
    formikHelpers: FormikHelpers<IFormPaymentPortoOwnMolecule>
  ) => Promise<void>;
}

export const FormPaymentPortoOwnMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const formik = useFormik<IFormPaymentPortoOwnMolecule>({
    initialValues: { cardholder: "1" } as IFormPaymentPortoOwnMolecule,
    onSubmit: props.onSubmit,
    validationSchema: FormPaymentPortoOwnMoleculeValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (formik.values.cardholder === "0") props.onChangeOwnCC(false);
    // eslint-disable-next-line
  }, [formik.values.cardholder]);

  return (
    <>
      <Form
        onSubmit={(e: any) => formik.handleSubmit(e)}
        className={"form-default"}
      >
        <Form.Row>
          <FormikControlTranslatedAtom
            type={"radio"}
            formik={formik}
            property={"cardholder"}
            label={t("Are you the cardholder?")}
            data={props.dataRadioCardholder}
          />
        </Form.Row>

        <FormPaymentPortoBaseMolecule
          formik={formik}
          dataDropdownPlots={props.dataDropdownPlots}
        >
          <Form.Row>
            <FormikControlTranslatedAtom
              lg={4}
              formik={formik}
              property={"creditcardNumber"}
              label={t("Creditcard number")}
              mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
            />
            <FormikControlTranslatedAtom
              lg={4}
              formik={formik}
              property={"creditcardName"}
              label={t("Creditcard name")}
            />
          </Form.Row>
          <Form.Row>
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"creditcardMonth"}
              label={t("Month")}
              mask={[/\d/, /\d/]}
            />
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"creditcardYear"}
              label={t("Year")}
              mask={[/\d/, /\d/, /\d/, /\d/]}
            />
            <FormikControlTranslatedAtom
              lg={2}
              formik={formik}
              property={"creditcardCvv"}
              label={t("Cvv")}
              mask={[/\d/, /\d/, /\d/, /\d?/]}
              placeholderChar={"\u2000"}
            />
          </Form.Row>
        </FormPaymentPortoBaseMolecule>
      </Form>
    </>
  );
};
