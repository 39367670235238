import { Modal } from "react-bootstrap";
import styled from "styled-components";

interface ICustomMarginLeft {
  marginLeft: string;
}

interface IPrintQuoteDetailsProp {
  isLoading?: boolean;
}

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 708px;
  }
`;

// -------- //
//  Header  //
// -------- //
const ModalHeader = styled(Modal.Header)`
  background: ${({ theme }) => theme.colors.DTBlue};
  border-radius: 0px 0px 8px 8px;
  border: none;
  justify-content: flex-end;
  padding: 10px 16px 0;
  span {
    font-size: 30px;
    font-weight: 100;
  }
  button {
    padding: 0;
    margin: 0;
    color: white;
    opacity: 1;
  }
`;
const ModalHeaderContent = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;

  padding: 10px 0 15px 26px;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 12px;
  }
  .vehicle-* {
    text-transform: uppercase;
  }
  .vehicle-name {
    margin-bottom: 2px;
  }
`;

// --------------------- //
//  Receipt Description  //
// --------------------- //
const ModalBodyDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 42px;
`;

const HR = styled.hr`
  border-top: 2px solid ${({ theme }) => theme.colors.DTBlue};
  width: 100%;
`;

const PlanHeader = styled.div`
  display: flex;
  align-items: center;
`;
const PlanHeaderItem = styled.div<ICustomMarginLeft>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.marginLeft};
`;
const PlanTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.BlueNavy};
`;
const PlanDescription = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.BlueNavy};
`;
const DeductibleValue = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.BlueNavy};
`;

const CoverageTitle = styled(PlanTitle)`
  margin-bottom: 11px;
`;

const CoverageItem = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CoverageItemText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.MediumGray};
`;
const CoverageItemNotAvailable = styled.span`
  margin-right: 5px;
`;

// ----------------- //
//  Payment Summary  //
// ----------------- //
const ModalBodySummary = styled.div`
  background-color: ${({ theme }) => theme.colors.Background};
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 25px 42px 40px;
`;

const TotalSummary = styled.div`
  display: flex;
  justify-content: right;
  align-items: end;
  gap: 10px;
`;
const TotalLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.MediumGray};
`;
const TotalContent = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.DTBlue};
`;

const PaymentSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;
const CreditCardInfo = styled.div`
  display: flex;
  justify-content: left;
  align-items: end;
  gap: 10px;
`;
const InstallmentsInfo = styled.div`
  display: flex;
  justify-content: right;
  align-items: end;
  gap: 10px;
`;
const SummaryLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.MediumGray};
`;
const SummaryContent = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.DTBlue};
`;

// -------- //
//  Footer  //
// -------- //
const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 42px;
`;

const PrintButtonDiv = styled.div<IPrintQuoteDetailsProp>`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.LapisLazul};
  text-transform: uppercase;
  cursor: ${(p) => (p.isLoading ? "wait" : "pointer")};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PrintableContentDiv = styled.div``;

export {
  CoverageItem,
  CoverageItemNotAvailable,
  CoverageItemText,
  CoverageTitle,
  CreditCardInfo,
  DeductibleValue,
  HR,
  InstallmentsInfo,
  ModalBodyDescription,
  ModalBodySummary,
  ModalFooter,
  ModalHeader,
  ModalHeaderContent,
  PaymentSummary,
  PlanDescription,
  PlanHeader,
  PlanHeaderItem,
  PlanTitle,
  PrintButtonDiv,
  PrintableContentDiv,
  StyledModal,
  SummaryContent,
  SummaryLabel,
  TotalContent,
  TotalLabel,
  TotalSummary,
};
