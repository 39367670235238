import { useAuth0 } from "@auth0/auth0-react";
import {
  ActiveItemTypeConstant,
  AuthHelper,
  C4UMenu,
  CorporateUserTypeEnum,
  IAuth0User,
  IUser,
  StorageConstant,
} from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BaseTemplate } from "..";
import { paths } from "../../../constants";
import { useC4u, useInsuranceContext, useSessionContext } from "../../../hooks";
import { FeedbackModal } from "../../molecules";
import { MainTemplateAuth } from "./main.template.auth";
import { AppContainerWithMenu } from "./main.template.style";

export const MainTemplate: React.FC = (props) => {
  const { t } = useTranslation();

  const { isAuthenticated, isLoading } = useAuth0();

  const {
    genericModalMsg,
    genericErrorModal,
    hideGenericErrorModal,
    genericWarningModal,
    hideGenericWarningModal,
    genericSuccessModal,
    hideGenericSuccessModal,
    titleErrorModal,
    titleWarningModal,
    titleSuccessModal,
    coxUserContext,
    setCoxUserContext,
    selectedDealershipContext,
    setSelectedDealershipContext,
  } = useSessionContext();
  const { clearInsuranceContexts } = useInsuranceContext();

  //#region auth
  const { GetAuth0User, GetUser, postSelectDealership, getListMyDealerships } =
    useC4u();

  const [auth0User, setAuth0User] = useState<IAuth0User>();
  const [redirectedFromLogin, setRedirectedFromLogin] = useState(false);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      GetAuth0User().then((t) => setAuth0User(t));
      GetUser().then((t) => setCoxUserContext(t));
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  const [userCox, setUserCox] = useState<IUser>();
  useEffect(() => {
    if (coxUserContext && auth0User) {
      setUserCox(AuthHelper.GetCoxUser(coxUserContext, auth0User));
      const { dealershipId, dealershipName, dealershipCnpj } = coxUserContext;
      if (dealershipId && dealershipName && dealershipCnpj)
        setSelectedDealershipContext({
          id: dealershipId,
          name: dealershipName,
          cnpj: dealershipCnpj,
        });
      setRedirectedFromLogin(
        sessionStorage.getItem(StorageConstant.redirectedFromLogin) === "true"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0User, coxUserContext]);
  //#endregion

  const handleLogout = () => {
    window.location.href = process.env.REACT_APP_LOGOUT_URL ?? "";
  };

  const clearKeysFromStorage = useCallback(() => {
    sessionStorage.removeItem(StorageConstant.redirectedFromLogin);
  }, []);

  const redirectUri = `${window.location.origin}/#${paths.newQuote()}`;

  return (
    <BaseTemplate>
      <MainTemplateAuth>
        <C4UMenu
          handleLogout={handleLogout}
          activeMenu={ActiveItemTypeConstant.INSURANCE}
          environment={process.env.REACT_APP_BUILD}
          user={userCox}
          selectDealershipProps={
            !userCox
              ? undefined
              : {
                  userId: coxUserContext?.id,
                  userIsManager:
                    coxUserContext?.corporateUserType ===
                    CorporateUserTypeEnum.Manager,
                  userIsCorporate: coxUserContext?.isCorporate,
                  dealership: selectedDealershipContext,
                  onSubmitCallback: postSelectDealership,
                  onSuccessCallback: clearInsuranceContexts,
                  getDealershipsPagedListCallback: getListMyDealerships,
                  setSelectedDealership: setSelectedDealershipContext,
                  onHideCallback: clearKeysFromStorage,
                  forceShowModal: redirectedFromLogin,
                  redirectTo: redirectUri,
                }
          }
          translate={t}
        />
        <AppContainerWithMenu>
          <Container>{props.children}</Container>
        </AppContainerWithMenu>
      </MainTemplateAuth>
      <FeedbackModal
        type={"error"}
        show={genericErrorModal}
        onHide={hideGenericErrorModal}
        title={titleErrorModal ?? t("A problem found")}
        content={genericModalMsg ?? t("GenericErrorMsg")}
      />
      <FeedbackModal
        type={"warning"}
        show={genericWarningModal}
        onHide={hideGenericWarningModal}
        title={titleWarningModal ?? t("Attention")}
        content={genericModalMsg ?? t("GenericWarningMsg")}
      />
      <FeedbackModal
        type={"success"}
        show={genericSuccessModal}
        onHide={hideGenericSuccessModal}
        title={titleSuccessModal ?? t("Everything all right")}
        content={genericModalMsg ?? t("GenericSuccessMsg")}
      />
    </BaseTemplate>
  );
};
