import React from "react";
import {
  SectionTitleDiv,
  WrapperFormSectionMolecule,
} from "./form-section.molecule.style";

interface IProps {
  title?: string;
  verticalMargin?: string;
}

export const FormSectionMolecule: React.FC<IProps> = (props) => {
  return (
    <WrapperFormSectionMolecule verticalMargin={props.verticalMargin}>
      {!!props.title && (
        <SectionTitleDiv>{props.title}</SectionTitleDiv>
      )}
      {props.children}
    </WrapperFormSectionMolecule>
  );
};
