import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { MsgValidation } from "../../../../../constants";
import { IFormPaymentPortoCCMolecule } from "../../../../../models";

export const FormPaymentPortoCCMoleculeValidation = () => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);

  const validations = Yup.object<IFormPaymentPortoCCMolecule>({
    plots: Yup.string().required(msgRequired),
    cardholder: Yup.string().required(msgRequired),
    creditcardFlag: Yup.string().required(msgRequired),
  });

  return validations;
};
