import React, { createContext, useCallback, useState } from "react";
import { InsuranceProviderState } from "../hooks/contexts/use-insurance-context.hook";
import {
  IGetCustomerResponse,
  InsuranceCompanyEnum,
  IQuoteContext,
  IVehicle,
  IVehicleData,
  OriginEnum,
} from "../models";
import { VehicleCategoriesEnum, SourcePartnerEnum } from "c4u-web-components";

export const InsuranceContext = createContext({} as any);

export const InsuranceProvider = (props: any) => {
  const [idCustomerContext, setIdCustomerContext] = useState<string>();
  const [customerContext, setCustomerContext] =
    useState<IGetCustomerResponse>();
  const [quotesContext, setQuotesContext] = useState<IQuoteContext>();
  const [selectedCompanyContext, setSelectedCompanyContext] =
    useState<InsuranceCompanyEnum>();
  const [selectedPlanContext, setSelectedPlanContext] = useState<string>();

  const getSelectedPlanContext = useCallback(() => {
    if (quotesContext && selectedCompanyContext && selectedPlanContext) {
      const companyPlan = quotesContext.quotes.find(
        (f) => f.company === selectedCompanyContext
      );
      const plan = companyPlan?.plans.find(
        (f) => f.planId === selectedPlanContext
      );

      return plan;
    }
  }, [quotesContext, selectedCompanyContext, selectedPlanContext]);

  const [vehicleDataContext, setVehicleDataContext] = useState<IVehicleData>();
  const [vehicleContext, setVehicleContext] = useState<IVehicle>();

  const [paymentLinkContext, setPaymentLinkContext] = useState<string>();

  const clearInsuranceContexts = useCallback(() => {
    setVehicleContext(undefined);
  }, []);

  const [origin, setOrigin] = useState<OriginEnum>();
  const [vehicleCategoryContext, setVehicleCategoryContext] =
    useState<VehicleCategoriesEnum>(VehicleCategoriesEnum.Car);
  const [sourcePartnerContext, setSourcePartnerContext] =
    useState<SourcePartnerEnum>(SourcePartnerEnum.Kbb);

  return (
    <InsuranceContext.Provider
      value={
        {
          quotesContext,
          setQuotesContext,
          selectedCompanyContext,
          setSelectedCompanyContext,
          selectedPlanContext,
          setSelectedPlanContext,
          getSelectedPlanContext,
          customerContext,
          setCustomerContext,
          idCustomerContext,
          setIdCustomerContext,
          vehicleDataContext,
          setVehicleDataContext,
          vehicleContext,
          setVehicleContext,
          paymentLinkContext,
          setPaymentLinkContext,
          origin,
          setOrigin,
          clearInsuranceContexts,
          vehicleCategoryContext,
          setVehicleCategoryContext,
          sourcePartnerContext,
          setSourcePartnerContext,
        } as InsuranceProviderState
      }
    >
      {props.children}
    </InsuranceContext.Provider>
  );
};
