import { Form } from "react-bootstrap";
import styled from "styled-components";

const HeaderSearchFilterWrapper = styled.div`
  margin: 10px 0;

  .form-row {
    margin-left: unset;
    margin-right: unset;
  }
  @media (min-width: 990px) {
    margin-left: 100px;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
  height: 100%;

  .form-group {
    padding: 0;
    margin: 0;
  }
`;

export { HeaderSearchFilterWrapper, StyledForm };
